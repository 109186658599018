<template>
  <div
    class="deck-exodia-header-image-fallback"
    :style="`aspect-ratio: 1 / ${1 / aspectRatio}`"
  >
    <v-avatar
      v-if="initials"
      color="primary"
      size="64"
    >
      <span class="text-uppercase font-weight-medium text-h5">{{ initials }}</span>
    </v-avatar>
  </div>
</template>

<script>
export default {
  name: 'DeckExodiaProfileImageFallback',
  props: {
    text: { type: String, default: null },
    aspectRatio: { type: Number, default: 1 },
  },
  computed: {
    initials() {
      if (!this.text) return null;

      return this.text
        .split(' ')
        .slice(0, 2)
        .map(string => string[0])
        .join('');
    },
  },
};
</script>

<style lang="scss" scoped>
.deck-exodia-header-image-fallback {
  align-items: center;
  background-color: rgba(var(--v-theme-primary), var(--v-medium-emphasis-opacity));
  background-image: url("~/assets/images/card/avatar-background.svg");
  background-position: 50% center;
  background-size: cover;
  display: flex;
  justify-content: center;
  width: 100%;
}

.deck-exodia-header-image-fallback__avatar {
  border-radius: 50% !important;
}
</style>
