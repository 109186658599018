export default class AsyncDataManagerLoadState {
  #loadMethod;

  #onLoad;

  #onLoadError;

  constructor({
    loadMethod,
    onLoad,
    onLoadError,
  } = {}) {
    this.#loadMethod = loadMethod;
    this.#onLoad = onLoad;
    this.#onLoadError = onLoadError;
  }

  load() {
    this.#loadMethod()
      .then((data) => {
        this.#onLoad(data);
      })
      .catch((error) => {
        this.#onLoadError(error);
      });
  }
}
