import type { NuxtConfig } from '@nuxt/schema';

export const locales = [
  {
    code: 'en',
    language: 'en-US',
    name: 'English',
  },
  {
    code: 'pt',
    language: 'pt-BR',
    name: 'Português',
  },
];

const baseUrl = process.env.NODE_ENV === 'production'
  ? 'https://app.zazos.com'
  : 'http://localhost:8080';

export default {
  vueI18n: 'config/i18n/i18n-vue.config.ts', // https://i18n.nuxtjs.org/docs/options#vuei18n
  baseUrl, // https://i18n.nuxtjs.org/docs/options/routing#baseurl
  langDir: './config/i18n/locales', // https://i18n.nuxtjs.org/docs/options/lazy#langdir
  // https://i18n.nuxtjs.org/docs/options/routing#locales
  locales: locales.map(locale => ({
    ...locale,
    files: [
      `./${locale.code}.json`,
      `./${locale.code}/errors.json`,
    ],
  })),
  defaultLocale: 'pt', // https://i18n.nuxtjs.org/docs/options/routing#defaultlocale
  detectBrowserLanguage: { // https://i18n.nuxtjs.org/docs/options/browser#detectbrowserlanguage
    useCookie: true,
    alwaysRedirect: true,
  },
  compilation: {
    strictMessage: false, // https://i18n.nuxtjs.org/docs/options/compilation#strictmessages
  },
} as NuxtConfig['i18n'];
