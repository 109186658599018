import { pick } from 'lodash';
import { HELPER_TEXT_ATTRIBUTES, LABEL_ATTRIBUTES } from '~/assets/javascript/constants';

export default class MarkdownInfoComponentFromOptionBuilder {
  static build(infoComponentOption) {
    return new MarkdownInfoComponentFromOptionBuilder(infoComponentOption).build();
  }

  constructor(infoComponentOption) {
    this.markdownInfoComponentOption = infoComponentOption;
  }

  build() {
    return {
      ...pick(
        this.markdownInfoComponentOption,
        [
          'columns',
          'content',
          'id',
          'type',
          ...HELPER_TEXT_ATTRIBUTES,
          ...LABEL_ATTRIBUTES,
        ],
      ),
    };
  }
}
