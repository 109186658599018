/* eslint-disable no-underscore-dangle */
import {
  findInfoComponentById,
  findAssociatedView,
  getFieldOutputType,
  updateInfoComponentById,
  removeInfoComponentById,
  isFieldEditable,
} from '~/assets/javascript/utils';

import { VIEW_TYPES } from '~/assets/javascript/constants';

import { InfoComponentFromOptionBuilder, InfoComponentOptionBuilder } from '~/assets/javascript/store-helpers/page-state-components';

export const state = () => ({
  currentInfoComponentId: undefined,
  currentSectionKey: undefined,
});

export const getters = {
  __rawMainSectionMainContent: (_state, _getters, _rootState, rootGetters) => (rootGetters['builderView/editingViewState']?.main_section?.main_content || []),
  __rawHeaderSectionMainContent: (_state, _getters, _rootState, rootGetters) => (rootGetters['builderView/editingViewState']?.header_section?.main_content || []),
  __mainSectionMainContent: (_state, getters) => getters.__rawMainSectionMainContent.map(item => InfoComponentOptionBuilder.build(item, getters.__rawMainSectionMainContent)),
  __headerSectionMainContent: (_state, getters) => getters.__rawHeaderSectionMainContent.map(item => InfoComponentOptionBuilder.build(item, getters.__rawHeaderSectionMainContent)),
  __viewSelectedComponentKey: (_state, _getters, rootState) => (rootState.builderView.selectedComponentKey),

  popupOpen: (_state, getters) => getters.currentInfoComponent !== undefined,
  currentRawMainContent: (state, getters) => {
    if (state.currentSectionKey === undefined) return [];

    if (state.currentSectionKey === 'main_section') return getters.__rawMainSectionMainContent;
    if (state.currentSectionKey === 'header_section') return getters.__rawHeaderSectionMainContent;
    throw new Error('Invalid section key');
  },
  currentMainContent: (state, getters) => {
    if (state.currentSectionKey === undefined) return [];

    if (state.currentSectionKey === 'main_section') return getters.__mainSectionMainContent;
    if (state.currentSectionKey === 'header_section') return getters.__headerSectionMainContent;
    throw new Error('Invalid section key');
  },

  currentInfoComponent: (state, getters) => {
    if (state.currentInfoComponentId === undefined) return undefined;
    return findInfoComponentById(getters.currentMainContent, state.currentInfoComponentId);
  },
  editingView: (_state, _getters, rootState) => rootState.builderView.editingView,
  currentView: (state, getters) => {
    if (state.currentInfoComponentId === undefined) return undefined;
    return findAssociatedView(state.currentInfoComponentId, getters.currentRawMainContent, getters.editingView);
  },
  isEditableInfoComponent: (_state, getters) => {
    const pageStateCanEdit = getters.__viewSelectedComponentKey === VIEW_TYPES[getters.editingView.page_type].builderOptions.editableInfoComponentsKey;

    return pageStateCanEdit && isFieldEditable(getters.currentField);
  },
  currentField: (_state, getters) => {
    if (getters.currentInfoComponent.type !== 'field') return undefined;

    return (getters.currentView?.sheet?.fields || []).find(
      field => field.id === getters.currentInfoComponent.field_id,
    );
  },
  fieldOutputType: (_state, getters) => (getters.currentField ? getFieldOutputType(getters.currentField) : getters.currentInfoComponent.field_type),
};

export const mutations = {
  setCurrentInfoComponent(state, id) {
    state.currentInfoComponentId = id;
  },
  setCurrentSectionKey(state, key) {
    state.currentSectionKey = key;
  },
};

export const actions = {
  setCurrentInfoComponent({ commit }, { id, pageSectionKey }) {
    if (pageSectionKey !== undefined) commit('setCurrentSectionKey', pageSectionKey);
    commit('setCurrentInfoComponent', id);
  },
  closePopup({ commit }) {
    commit('setCurrentInfoComponent', undefined);
    commit('setCurrentSectionKey', undefined);
  },
  async updateCurrentInfoComponent({ state, dispatch, getters }, updatedInfoComponent) {
    const updatedMainContent = updateInfoComponentById(
      getters.currentMainContent,
      state.currentInfoComponentId,
      { ...getters.currentInfoComponent, ...updatedInfoComponent },
    );

    await dispatch('save', updatedMainContent);
  },
  async removeCurrentInfoComponent({ state, dispatch, getters }) {
    const updatedMainContent = removeInfoComponentById(getters.currentMainContent, state.currentInfoComponentId);

    await dispatch('save', updatedMainContent);
    await dispatch('closePopup');
  },

  async save({ state, dispatch, rootGetters, commit }, updatedMainContent) {
    const newEditingView = {
      ...rootGetters['builderView/editingViewState'],
      [state.currentSectionKey]: {
        ...rootGetters['builderView/editingViewState'][state.currentSectionKey],
        main_content: updatedMainContent.map(infoComponentOption => InfoComponentFromOptionBuilder.build(infoComponentOption)),
      },
    };

    commit('builderView/setEditingViewState', newEditingView, { root: true });
    await dispatch('builderView/saveEditingView', {}, { root: true });
  },
};

export const namespaced = true;
