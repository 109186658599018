<i18n lang="yaml">
pt:
  title: "Esqueci minha senha"
  subtitle: "Informe seu email para receber o link de redefinição de senha"
en:
  title: "Forgot your password?"
  subtitle: "You will receive an email with the link to access your workspaces"
</i18n>
<template>
  <AuthLogin
    :layout-attrs="{
      title: t('title'),
      subtitle: t('subtitle'),
      hideCaptchaDisclaimer: true,
      hideSignUpDisclaimer: true
    }"
    :modules="[
      'resetPassword',
    ]"
  />
</template>

<script lang="ts">
import AuthLogin from '~/components/auth/login';

export default defineComponent({
  name: 'ResetPassword',
  components: { AuthLogin },
  setup() {
    definePageMeta({
      middleware: ['auth-redirect'],
    });

    return {
      t: useI18n().t,
    };
  },
});
</script>
