import { v4 as uuidv4 } from 'uuid';

export const state = () => ({
  workflowExecutionsMap: {},
});

export const getters = {
  findWorkflowExecutionByRequestId: state => requestId => state.workflowExecutionsMap[requestId],
};

export const mutations = {
  add(state, item) {
    state.workflowExecutionsMap[item.workflowExecutionRequestId] = item;
  },
  remove(state, id) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete state.workflowExecutionsMap[id];
  },
  updateWorkflowExecutionPath(state, { requestId, path }) {
    state.workflowExecutionsMap[requestId].path = path;
  },
};

export const actions = {
  async create({ commit }, { params, path, successMessage }) {
    const { $apiClient, $notifier } = useNuxtApp();
    const workflowExecutionRequestId = uuidv4();

    commit('add', {
      ...params,
      workflowExecutionRequestId,
      path,
    });

    params.workflow_execution_request_id = workflowExecutionRequestId;

    try {
      await $apiClient.workflows.executions.create(params);

      $notifier.showMessage({
        content: successMessage,
        color: 'info',
      });
    } catch (error) {
      commit('remove', workflowExecutionRequestId);
      throw error;
    }
  },
};

export const namespaced = true;
