<!--
  We need to use a functional component with render function to ensure that an
  actual <v-list-item> component is rendered, instead of our custom named
  component. If we don't, the v-autocomplete will automatically wrap anything
  that is not a v-list-item in the #item slot with a v-list-item component. This
  can lead to unnecessary components being rendered and may cause layout and
  styling issues, as well as interference with our custom populating behavior
  that is implemented through an “items schema.”

  @see https://github.com/vuetifyjs/vuetify/issues/13305#issuecomment-1834642535

  PS: The suggestion of just setting `name: 'v-list-item'` to a SFC file with a
  root v-list-item did not work for us. Using a render function was the only
  way I could make it work.
-->

<script>
import { VListItem } from 'vuetify/components/VList';
import { h } from 'vue';

const component = {
  props: {
    item: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectAllDisabled: {
      type: Boolean,
      default: false,
    },
    isSearching: {
      type: Boolean,
      default: false,
    },
    itemsSelection: {
      type: Object,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    indentItems: {
      type: Boolean,
      default: false,
    },
    defaultProps: {
      type: Object,
      default: () => ({}),
    },
  },
  render: (props, context) => {
    const { item } = props;
    const { disabled } = item;

    // Prevent default tag title to be rendered
    delete props.defaultProps.title;
    /*
     * Quick overview of the item kinds:
     *
     * Static items:
     * Are not user-selectable. In order to prevent vuetify's `v-list-item`
     * selection on click, we need to set some intricate props and prevent click
     * manually.
     *
     * Selectable items:
     * Are user-selectable. We need to programmatically set its `disabled` and
     * `active` states by manipulating the same props
     */

    const isStaticItem = 'selectAll' in item || 'label' in item;

    const staticItemProps = {
      'input-value': false, // Controls the visual active state of the item (background color, checkbox state, etc.)
      ripple: false, // Controls the ripple effect on click
      tabindex: -1, // Overrides the auto populated `tab-index` to prevent the item from being focused
      role: '', // Removes the accessible role attribute of `option` from the item
      onClick: e => e.preventDefault(), // Prevents the item from being selected/checked
    };

    const selectableItemProps = {
      disabled, // Prevents the item from being selected/checked while also visually indicating that it's disabled
      'input-value': !disabled && item.selected, // Controls the visual active state of the item (background color, checkbox state, etc.)
      ripple: !disabled, // Controls the ripple effect on click
    };

    if (disabled) {
      selectableItemProps.onClick = e => e.preventDefault(); // Prevents the item from being selected/checked
    }

    const staticItemClasses = {
      'deck-select-list-item-wrapper--select-all': 'selectAll' in item,
      'deck-select-list-item-wrapper--group-label': 'label' in item,
      'deck-select-list-item-wrapper--group-shift-sticky-label': 'label' in item && props.multiple && !props.isSearching,
      'deck-select-list-item-wrapper--group-unlabelled': item?.label === '-',
    };

    const selectableItemClasses = {
      'deck-select-list-item-wrapper--selectable': true,
      'deck-select-list-item-wrapper--indent': props.indentItems,
      'deck-select-list-item-wrapper--in-unlabelled-group': item.groupLabel === '-',
    };

    const commonItemClasses = {
      'deck-select-list-item-wrapper': true,
      'deck-select-list-item-wrapper--exclusive': item.exclusive,
      'deck-select-list-item-wrapper--disabled': props.disabled,
    };

    const attrs = {
      class: {
        ...context.class,
        ...commonItemClasses,
        ...(isStaticItem ? staticItemClasses : selectableItemClasses),
      },
      ...props.defaultProps,
      ...(isStaticItem ? staticItemProps : selectableItemProps),
    };

    return h(VListItem, attrs, {
      default: () => h('div', { class: 'deck-select-list-item-wrapper__content-wrapper' }, context.slots),
    });
  },
};

// I wrote the component in this format so as not to mess up the diff
// too much, the default is to follow this here
// https://v3-migration.vuejs.org/breaking-changes/functional-components#_3-x-syntax
const DeckSelectVListItem = component.render;
DeckSelectVListItem.props = component.props;

export default DeckSelectVListItem;
</script>

<style lang="scss">
.deck-select-list-item-wrapper {
  --deck-select-item-height: 40px;
  --deck-select-select-all-height: var(--deck-select-item-height);
  --deck-select-group-label-height: 32px;
  --deck-select-group-label-top: 0;

  position: relative;
  min-height: var(--deck-select-item-height) !important;
  padding-inline: var(--z-s3) !important;
}

.deck-select-list-item-wrapper__content-wrapper {
  flex: 1;
  display: flex;
}

.deck-select-list-item-wrapper--select-all {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  gap: var(--z-s2);
  height: var(--deck-select-select-all-height);
  padding-inline: var(--z-s3);
  background-color: #FFFFFF;
  box-shadow: 0 -1px 0 0 var(--z-color-shade-light-5);
  z-index: 2;

  .deck-select__select-all-checkbox .v-input--is-disabled {
    opacity: 0.5;
  }

  + .deck-select-list-item-wrapper--group-label {
    /* The first group label does not need to be distanced from the "select all" when they are right next to each other. */
    margin-top: 0;
  }
}

.deck-select-list-item-wrapper--group-label {
  position: sticky;
  top: var(--deck-select-group-label-top);
  min-height: var(--deck-select-group-label-height) !important;
  height: var(--deck-select-group-label-height);
  margin-block: var(--z-s2);
  padding-inline: var(--z-s3);
  color: var(--z-color-label) !important;
  background-color: var(--z-main-background-accent-color);
  box-shadow: inset 0 0 0 1px #EFEFEF, 0 -4px 0 0 #FFFFFF;
  z-index: 1;

  /*
   * The first .deck-select__group-label.
   * Must use this more complex selector due to the way Vuetify renders the list.
   */
  &:not(& ~ &) {
    margin-top: 0;
  }

  &.v-list-item--disabled {
    pointer-events: auto;

    .deck-select__group-label-content {
      pointer-events: none;
    }
  }

  &.deck-select-list-item-wrapper--exclusive {
    /* Exclusive groups are always before the header with select all, so they need to stick at the top */
    top: 0;
  }
}

.deck-select-list-item-wrapper--group-unlabelled {
  display: none;
}

.deck-select-list-item-wrapper--group-shift-sticky-label {
  /* Shift the sticky label down to avoid overlapping with the sticky select all */
  --deck-select-group-label-top: var(--deck-select-select-all-height);
}

.deck-select-list-item-wrapper--disabled {
  .deck-select-list-item-wrapper__content-wrapper {
    opacity: 0.5;
  }
}

.deck-select-list-item-wrapper--indent:not(.deck-select-list-item-wrapper--in-unlabelled-group ) {
  padding-left: var(--z-s9) !important;
}
</style>
