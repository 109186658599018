<i18n lang="yaml">
pt:
  alreadyConfirmed: 'Já confirmei o e-mail'
  title: 'Verifique seu e-mail'
  subtitle: 'Acesse o link enviado para o seu e-mail para entrar na sua Área de Trabalho. Verifique também sua caixa de spam.'
en:
  alreadyConfirmed: 'I already confirmed my email'
  title: 'Verify your email'
  subtitle: 'Access the link sent to your e-mail to enter in your Workspace. Also check your spam box.'
</i18n>
<template>
  <AuthLogin
    :layout-attrs="layoutAttrs"
    :modules="[
      'emailValidation'
    ]"
    :module-attrs="{
      emailValidation: {
        validatedEmailBtnAttrs: {
          to: loginPage,
          text: t('alreadyConfirmed'),
        }
      }
    }"
  />
</template>

<script lang="ts">
import ROUTES from '~/assets/javascript/constants/routes';
import AuthLogin from '~/components/auth/login';

export default defineComponent({
  name: 'EmailValidation',
  components: { AuthLogin },
  setup() {
    const { t } = useI18n();

    definePageMeta({
      middleware: ['auth-redirect'],
    });

    return { t };
  },
  computed: {
    layoutAttrs() {
      return {
        title: this.t('title'),
        subtitle: this.t('subtitle'),
        dark: this.darkAuthCardLayout,
        hideSignUpDisclaimer: true,
      };
    },
    darkAuthCardLayout() {
      return this.$route.query.from === 'signUp';
    },
    loginPage() {
      return this.localePath({
        name: ROUTES.public.login.routeName,
        params: {
          tenantSlug: this.$route.params.tenantSlug,
        },
      });
    },
  },
});
</script>
