export const getImgMustacheContent = (node) => {
  const height = node.getAttribute('height') || '';
  const width = node.getAttribute('width') || '';

  const styleMustacheContent = [];

  if (height && height !== 'auto') {
    styleMustacheContent.push(`height="${height}"`);
  }

  if (width && width !== 'auto') {
    styleMustacheContent.push(`width="${width}"`);
  }

  return styleMustacheContent.join(' ');
};

export const parseImageResizeMustache = html => (html ? html.replace(
  /<img [^<>]+>{{3}([^<>{}]+)}{3}/g,
  (match) => {
    const imgHtml = match.match(/<img .*>/g)[0];

    const imgStyleText = match.match(/{{{(.*)}}}/)[1]; // height and width are saved like {{{height="100" width="100"}}} in the markdown
    const imgStyleObject = imgStyleText.split(' ').map(
      (style) => {
        const [key, value] = style.replaceAll('"', '').split('=');
        return {
          key, value,
        };
      },
    );
    const doc = new DOMParser().parseFromString(imgHtml, 'text/html');
    const imgElement = doc.body.getElementsByTagName('img')[0];
    const height = parseFloat(imgStyleObject.find(style => style.key === 'height')?.value || '');
    const width = parseFloat(imgStyleObject.find(style => style.key === 'width')?.value || '');

    if (!Number.isNaN(height)) {
      imgElement.height = height;
    }

    if (!Number.isNaN(width)) {
      imgElement.width = width;
    }

    return imgElement.outerHTML;
  },
) : html);
