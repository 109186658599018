import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';

export const state = () => ({
  apps: [],
  app: {},
  appInstallations: [],
  appInstallation: {},
  selectedAreasOfInterest: [],
  selectedAppsPerSelectedAreaOfInterest: {},
});

export const getters = {
  areasOfInterest(state) {
    return uniqBy(state.apps.map(app => ({
      name: app.category,
      icon: app.icon,
    })), 'name') || [];
  },
  appsPerSelectedAreaOfInterest(state) {
    const appsPerSelectedAreaOfInterest = {};

    state.selectedAreasOfInterest.forEach((areaOfInterest) => {
      appsPerSelectedAreaOfInterest[areaOfInterest] = state.apps.filter(app => app.category === areaOfInterest);
    });

    return appsPerSelectedAreaOfInterest;
  },
  getAppByName(state) {
    return name => state.apps.find(app => app.name === name);
  },
  getAppInstallationByName(state) {
    return name => state.appInstallations.find(appInstallation => appInstallation.name === name);
  },
  appInstalled(state) {
    return !isEmpty(state.appInstallation);
  },
};

export const mutations = {
  setApps(state, apps) {
    state.apps = apps;
  },
  setApp(state, app) {
    state.app = app;
    state.appInstallation = state.appInstallations.find(appInstallation => appInstallation.name === app.name);
  },
  setAppInstallations(state, appInstallations) {
    state.appInstallations = appInstallations;
  },
  setSelectedAreasOfInterest(state, selectedAreasOfInterest) {
    state.selectedAreasOfInterest = selectedAreasOfInterest;
  },
  setSelectedAppsPerSelectedAreaOfInterest(state, selectedAppsPerSelectedAreaOfInterest) {
    state.selectedAppsPerSelectedAreaOfInterest = selectedAppsPerSelectedAreaOfInterest;
  },
  updateApp(state) {
    state.app = state.apps.find(app => app.name === state.app?.name);
    state.appInstallation = state.appInstallations.find(appInstallation => appInstallation.name === state.app?.name);
  },
};

export const actions = {
  async loadPublicApps({ commit }) {
    const { $api } = useNuxtApp();

    const data = await $api.$get('/public/apps');
    commit('setApps', data);
  },
  async loadApps({ commit }) {
    const { $apiClient } = useNuxtApp();

    const data = await $apiClient.builder.apps.list();
    commit('setApps', data);
    return commit('updateApp');
  },
  async loadApp({ commit }, name) {
    const { $apiClient } = useNuxtApp();

    const data = await $apiClient.builder.apps.get(name);
    commit('setApp', data);
  },
  async loadAppInstallations({ commit }) {
    const { $apiClient } = useNuxtApp();

    const data = await $apiClient.builder.appInstallations.list();
    commit('setAppInstallations', data);
    return commit('updateApp');
  },
  async installApp({ dispatch }, { appName, runtimeValues }) {
    const { $apiClient } = useNuxtApp();

    await $apiClient.builder.apps.install(appName, runtimeValues);
    return dispatch('loadAppInstallations');
  },
  async installDummyData() {
    const { $api } = useNuxtApp();

    await $api.post('dummy_data');
  },
  async uninstallApp({ dispatch }, appName) {
    const { $apiClient } = useNuxtApp();

    await $apiClient.builder.apps.uninstall(appName);
    return dispatch('loadAppInstallations');
  },
  setSelectedAreasOfInterest({ commit, dispatch }, selectedAreasOfInterest) {
    const newSelectedAppsPerSelectedAreaOfInterest = {};

    selectedAreasOfInterest.forEach((areaOfInterest) => {
      newSelectedAppsPerSelectedAreaOfInterest[areaOfInterest] = [];
    });

    dispatch('setSelectedAppsPerSelectedAreaOfInterest', newSelectedAppsPerSelectedAreaOfInterest);
    return commit('setSelectedAreasOfInterest', selectedAreasOfInterest);
  },
  setSelectedAppsPerSelectedAreaOfInterest({ commit }, selectedAppsPerSelectedAreaOfInterest) {
    return commit('setSelectedAppsPerSelectedAreaOfInterest', selectedAppsPerSelectedAreaOfInterest);
  },
};

export const namespaced = true;
