<i18n lang="yaml">
pt:
  closeBtnLabel: 'Fechar'
  expandBtnLabel: 'Expandir'
  nextBtnLabel: 'Próximo'
  previousBtnLabel: 'Anterior'
en:
  closeBtnLabel: 'Close'
  expandBtnLabel: 'Expand'
  nextBtnLabel: 'Next'
  previousBtnLabel: 'Previous'
</i18n>

<template>
  <div
    :class="`deck-exodia-toolbar deck-exodia-toolbar--${size} deck-exodia-toolbar--${style}`"
  >
    <v-btn
      v-for="({ icon, event, labelKey, disabled }, index) in actions.left"
      :key="`toolbar-left-${index}`"
      v-bind="btnAttrs"
      :aria-label="t(labelKey)"
      :disabled="disabled"
      @click="$emit(event)"
    >
      <v-icon
        v-bind="iconAttrs"
      >
        {{ icon }}
      </v-icon>
    </v-btn>

    <v-spacer />

    <template v-if="$slots['toolbar-items']">
      <slot
        name="toolbar-items"
        :btn-attrs="btnAttrs"
        :icon-attrs="iconAttrs"
      />
    </template>

    <v-btn
      v-for="({ icon, event, labelKey }, index) in actions.right"
      :key="`toolbar-right-${index}`"
      icon
      :aria-label="t(labelKey)"
      v-bind="btnAttrs"
      @click="$emit(event)"
    >
      <v-icon
        v-bind="iconAttrs"
      >
        {{ icon }}
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
const ICON_SIZE = {
  small: 14,
  large: 16,
};

const BTN_SIZE = {
  small: 24,
  large: 28,
};

const DEFAULT_ACTIONS = {
  left: [
    {
      propCheck: 'showNavigationButtons',
      disabledCheck: 'showPreviousBtn',
      icon: {
        vertical: 'fa-regular fa-chevron-up',
        horizontal: 'fa-regular fa-chevron-left',
      },
      event: 'previousBtnClick',
      labelKey: 'previousBtnLabel',
    },
    {
      propCheck: 'showNavigationButtons',
      disabledCheck: 'showNextBtn',
      icon: {
        vertical: 'fa-regular fa-chevron-down',
        horizontal: 'fa-regular fa-chevron-right',
      },
      event: 'nextBtnClick',
      labelKey: 'nextBtnLabel',
    },
  ],
  right: [
    {
      propCheck: 'showExpandBtn',
      icon: 'fa fa-expand',
      event: 'expandBtnClick',
      labelKey: 'expandBtnLabel',
    },
    {
      propCheck: 'showCloseBtn',
      icon: 'fa-regular fa-close',
      event: 'closeBtnClick',
      labelKey: 'closeBtnLabel',
    },
  ],
};

export default {
  name: 'DeckExodiaToolbar',
  props: {
    title: { type: String, default: null },
    subtitle: { type: String, default: null },
    avatarIcon: { type: String, default: null },
    avatarImage: { type: String, default: null },
    showProfileImage: { type: Boolean, default: false },
    showPreviousBtn: { type: Boolean, default: false },
    showNextBtn: { type: Boolean, default: false },
    navigationDirection: { type: String, default: 'vertical' },
    showExpandBtn: { type: Boolean, default: false },
    showCloseBtn: { type: Boolean, default: false },
    size: { type: String, required: true },
  },
  emits: ['previousBtnClick', 'nextBtnClick', 'expandBtnClick', 'closeBtnClick'],
  setup() {
    return {
      t: useI18n().t,
    };
  },
  computed: {
    actions() {
      const actions = {
        left: [],
        right: [],
      };

      Object.keys(DEFAULT_ACTIONS).forEach((key) => {
        DEFAULT_ACTIONS[key].forEach((defaultAction) => {
          if (this[defaultAction.propCheck]) {
            actions[key].push({
              ...defaultAction,
              icon: typeof defaultAction.icon === 'string'
                ? defaultAction.icon
                : defaultAction.icon[this.navigationDirection],
              disabled: defaultAction.disabledCheck
                ? !this[defaultAction.disabledCheck]
                : false,
            });
          }
        });
      });

      return actions;
    },
    style() {
      return this.showProfileImage ? 'profile' : 'cover';
    },
    btnAttrs() {
      return {
        variant: 'text',
        icon: true,
        class: `deck-exodia-toolbar__btn deck-exodia-toolbar__btn--${this.size}`,
        width: BTN_SIZE[this.size],
        height: BTN_SIZE[this.size],
      };
    },
    iconAttrs() {
      return {
        size: ICON_SIZE[this.size],
        class: 'text--primary',
      };
    },
    showNavigationButtons() {
      return this.showPreviousBtn || this.showNextBtn;
    },
  },
};
</script>

<style lang="scss" scoped>
.deck-exodia-toolbar {
  display: flex;
  padding-top: 12px;
  margin: 0 -10px;

  &--small {
    padding-top: 0px;
    margin: 0 -8px !important;
  }
}
</style>
