<i18n lang="yaml">
pt:
  continue: 'Continuar'
  createWorkspaceCall: 'Sua empresa ainda não usa a Zazos?'
  createWorkspace: 'Crie uma Área de Trabalho'
  standardLoginCall: 'Não sabe a URL da sua empresa?'
  standardLogin: 'Encontre sua Área de Trabalho'
  subtitle: 'Insira a URL da sua empresa'
  title: 'Entre na Área de Trabalho da sua empresa'
  workspaceURL: 'URL da sua empresa'
  workspaceURLPlaceholder: 'sua-empresa'
en:
  continue: 'Continue'
  createWorkspaceCall: 'Does your company not use Zazos yet?'
  createWorkspace: 'Create a new workspace'
  standardLoginCall: "Don't know your company's URL?"
  standardLogin: 'Find your workspace'
  subtitle: "Enter your company's URL"
  title: "Log in to your company's workspace"
  workspaceURL: 'URL of your company'
  workspaceURLPlaceholder: 'your-company'
</i18n>
<template>
  <AuthCardLayout
    :title="t('title')"
    :subtitle="t('subtitle')"
    hide-captcha-disclaimer
    hide-sign-up-disclaimer
  >
    <v-form
      ref="setWorkspaceForm"
      class="mt-4 pt-4"
      @submit.prevent="setWorkspace"
    >
      <v-text-field
        :rules="[rules.required]"
        variant="outlined"
        class="set-workspace__input"
        :placeholder="t('workspaceURLPlaceholder')"
        @update:model-value="onTenantSlugInput"
      >
        <span class="set-workspace__input-prepend">app.zazos.com/t/</span>
      </v-text-field>

      <v-btn
        :loading="loading"
        color="primary"
        class="set-workspace__button"
        block
        elevation="0"
        type="submit"
      >
        {{ t('continue') }}
      </v-btn>
    </v-form>

    <template #actions>
      <v-col>
        <v-row>
          <span>{{ t('standardLoginCall') }} <NuxtLink :to="localePath('login')">{{ t('standardLogin') }}</NuxtLink></span>
        </v-row>

        <v-row class="mt-4">
          <span>{{ t('createWorkspaceCall') }} <NuxtLink :to="localePath('sign-up')">{{ t('createWorkspace') }}</NuxtLink></span>
        </v-row>
      </v-col>
    </template>
  </AuthCardLayout>
</template>

<script lang="ts">
import { parameterize } from '~/assets/javascript/utils/string';
import AuthCardLayout from '~/components/auth/card-layout';

export default defineComponent({
  name: 'SetWorkspace',
  components: { AuthCardLayout },
  setup() {
    definePageMeta({
      middleware: ['auth-redirect'],
    });

    return {
      t: useI18n().t,
    };
  },
  data() {
    return {
      tenantSlug: null,
      loading: false,
      rules: {
        required: value => !!value || this.$t('global.emptyGeneric'),
      },
    };
  },
  methods: {
    onTenantSlugInput(value) {
      this.tenantSlug = parameterize(value);
    },
    async setWorkspace() {
      if (!(await this.$refs.setWorkspaceForm.validate()).valid) return;

      this.loading = true;

      try {
        await this.$api.get(`public/workspaces/${this.tenantSlug}`);
        const redirectTo = this.localePath({
          name: 't-tenantSlug-login',
          params: {
            tenantSlug: this.tenantSlug,
          },
        });

        this.$router.push(redirectTo);
      } catch (error) {
        let message = error;

        if (error.response?.status === 404) {
          message = this.$t('global.workspaceNotFound');
        }

        this.$notifier.showMessage({
          content: message,
          color: 'warning',
        });
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
<style lang="scss">
.set-workspace__button {
  margin-top: 4px;
  text-transform: none;
}

.set-workspace__input-prepend {
  color: hsl(0 0% 55% / 1);
}

.set-workspace__input input::placeholder {
  color: inherit !important;
  font-weight: 500;
  opacity: 1 !important;
}
</style>
