export class SubviewHasNoInsertPermissionError extends Error {
  constructor() {
    super('Subview has no insert permission');

    this.response = {
      data: {
        errors: ['forbidden'],
      },
    };
  }
}
