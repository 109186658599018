export default defineNuxtPlugin(({ $auth, $i18n, $rollbar }) => ({
  provide: {
    segmentIdentifyUser: async (additionalInfo: object) => {
      if (!$auth.user) return;

      const workspace = $auth.user.workspace || {};

      let userInfo = {
        tenantId: $auth.user.tenant_id,
        workspaceName: workspace.name,
        workspaceCohort: workspace.cohort,
        workspacePlan: workspace.plan,
        username: $auth.user.username,
        email: $auth.user.username,
        role: $auth.user.role,
        locale: $i18n.locale,
      };

      try {
        if (!userInfo.role) {
          $rollbar.error('Missing role', userInfo);
        }

        userInfo = {
          ...userInfo,
          ...additionalInfo,
        };

        await window.analytics.identify($auth.user.id, userInfo);

        await window.analytics.group($auth.user.tenant_id, {
          name: workspace.name,
          cohort: workspace.cohort,
          plan: workspace.plan,
        });
      } catch (error) {
        $rollbar.error('Segment', error);
      }
    },
  },
}));
