<template>
  <v-expand-transition
    v-bind="$attrs"
  >
    <div
      v-show="isOpen"
      class="deck-expander"
    >
      <div
        class="deck-expander__content"
        :class="contentClass"
      >
        <!-- @slot For rendering any desired content -->
        <slot />
      </div>
    </div>
  </v-expand-transition>
</template>

<script>
/**
 * A Pattern component for expanding any desired content programmatically from 0
 * height to the content height with a transition.
 */
export default {
  name: 'DeckExpander',
  props: {
    /**
     * Model to control the expander open state.
     */
    modelValue: {
      type: Boolean,
      default: false,
    },

    /**
     * Class or classes to be applied to the content wrapper.
     * Useful for applying paddings or margins without without affecting the
     * expander height calculation, which can't account for them.
     */
    contentClass: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isOpen: this.modelValue,
    };
  },
  watch: {
    modelValue: {
      handler(newValue, oldValue) {
        if (newValue === oldValue) return;

        this.handleOpenState(newValue);
      },
    },
  },
  methods: {
    handleOpenState(value) {
      this.isOpen = value;

      /**
       * Reflects the expander open state. Bound to v-model.
       * @event update:modelValue
       */
      this.$emit('update:modelValue', this.isOpen);
    },
  },
};
</script>
