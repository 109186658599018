import AsyncDataManager from './data-manager';

export default defineNuxtPlugin({
  name: 'asyncDataManager',
  setup() {
    return {
      provide: {
        asyncDataManager: new AsyncDataManager(),
      },
    };
  },
});
