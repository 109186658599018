import { aliases } from 'vuetify/iconsets/fa';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('vuetify:before-create', ({ vuetifyOptions }) => {
    vuetifyOptions.icons.aliases = {
      ...aliases,
      dropdown: 'far fa-chevron-down',
    };
  });
});
