<i18n lang="yaml">
pt:
  title: 'Encontre sua área de trabalho'
  subtitle: 'Você receberá um e-mail com o link para acessar suas áreas de trabalho'
en:
  title: 'Find your workspace'
  subtitle: 'You will receive an email with the link to access your workspaces'
</i18n>
<template>
  <AuthLogin
    :layout-attrs="{
      title: t('title'),
      subtitle: t('subtitle'),
      hideCaptchaDisclaimer: true,
      hideSignUpDisclaimer: true
    }"
    :modules="[
      'signIn',
      'magicLink'
    ]"
  />
</template>

<script lang="ts">
import AuthLogin from '~/components/auth/login';

export default defineComponent({
  name: 'Login',
  components: { AuthLogin },
  setup() {
    const { t } = useI18n();

    definePageMeta({
      middleware: ['auth-redirect'],
    });

    return { t };
  },
});
</script>
