<i18n lang="yaml">
pt:
  importYamlFile: 'Importar arquivo YAML'
en:
  importYamlFile: 'Import YAML file'
</i18n>

<template>
  <v-file-input
    :model-value="value"
    :label="t('importYamlFile')"
    :variant="!outlined ? 'underlined' : undefined"
    :flat="!outlined"
    :outlined="outlined"
    :density="dense ? 'compact' : undefined"
    :disabled="!editable"
    v-bind="$attrs"
    accept=".yml, .yaml"
    @update:model-value="processFile"
  />
</template>

<script>
const readFileContent = file => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.onload = (event) => {
    resolve(event.target.result);
  };

  reader.onerror = (error) => {
    reject(error);
  };

  reader.readAsText(file);
});

export default {
  name: 'DeckYamlFileInput',
  props: {
    // eslint-disable-next-line vue/no-restricted-props
    value: { type: [Object, Array], default: undefined },
    outlined: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    editable: { type: Boolean, default: true },
  },
  emits: ['fileLoaded', 'fileError'],
  setup() {
    return {
      t: useI18n().t,
    };
  },
  methods: {
    async processFile(file) {
      if (!file) return;

      try {
        const fileContent = await readFileContent(file);
        this.$emit('fileLoaded', fileContent);
      } catch (error) {
        this.$emit('fileError', error);
      }
    },
  },
};
</script>
