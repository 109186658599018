import isFunction from 'lodash/isFunction';
import { isFieldEditable } from '~/assets/javascript/utils';

export default class BaseCell {
  constructor(recordId, field, value, point) {
    this.recordId = recordId;
    this.fieldId = field.id;
    this.field = field;
    this.value = value;
    this.point = point;
    this.allowPaste = false;
    this.allowClear = false;
    this.valueToClear = null;
    this.emptyValue = null;
  }

  get type() { return this.field.type; }

  get canClear() { return this.allowClear && !this.isEmpty && isFieldEditable(this.field); }

  get canPaste() { return this.allowPaste && isFieldEditable(this.field); }

  get isEmpty() {
    if (isFunction(this.emptyValue)) return this.emptyValue(this.value);

    return this.value === this.emptyValue;
  }

  valueFromPaste(rawValue, ...args) {
    if (this.canPaste) {
      if (this.buildValueFromPaste) return this.buildValueFromPaste(rawValue, ...args);

      return rawValue || undefined;
    }

    return undefined;
  }
}
