import moment from 'moment';
import {
  DATE_FORMATS_SUBMIT_MAPPING,
  DEFAULT_DATE_FORMAT,
  DYNAMIC_DATES,
  ISO_DATE_FORMAT,
  ISO_DATE_TIME_FORMAT,
} from '~/assets/javascript/constants';
import { findKey, isEmpty } from 'lodash';

const DATE_MAPPING = {
  now: () => moment(),
  today: () => moment(),
  tomorrow: () => moment().add(1, 'd'),
  yesterday: () => moment().subtract(1, 'd'),
  next_week: () => moment().add(1, 'w'),
  last_week: () => moment().subtract(1, 'w'),
  next_month: () => moment().add(1, 'M'),
  last_month: () => moment().subtract(1, 'M'),
  next_year: () => moment().add(1, 'y'),
  last_year: () => moment().subtract(1, 'y'),
};

export function transformDynamicDate(dateString) {
  const dynamicDateSetting = DYNAMIC_DATES.find(({ key }) => key === dateString);

  return DATE_MAPPING[dateString]?.()?.format(dynamicDateSetting.type === 'dynamic_date' ? ISO_DATE_FORMAT : ISO_DATE_TIME_FORMAT);
}

export const formatDateDisplayName = (date, { fieldId, fieldsFormatOptions, locale }) => {
  if (!date) return DEFAULT_DATE_FORMAT.toLowerCase();

  if (isEmpty(fieldsFormatOptions)) return date;

  const rawFormat = fieldsFormatOptions[fieldId] || '%d/%m/%Y';
  const format = findKey(DATE_FORMATS_SUBMIT_MAPPING, value => value === rawFormat);

  return moment(date).locale(locale).format(format);
};
