<template>
  <div class="d-flex align-center g-2 w-stretch">
    <!-- Due to Vuetify shananigans when wrapping a tooltip in this
    particular scenario, it is easier to conditionally render and trigger
    it with an invisible full size inner absolute element -->
    <deck-tooltip
      v-if="item.disabled && item.disabledHint"
      :text="item.disabledHint"
      kind="warning"
      position="left"
      trigger="click"
      close-delay-after-click="long"
      shake-on-open
    >
      <template #activator="{ props }">
        <span
          class="deck-select__hint-area"
          v-bind="props"
        />
      </template>
    </deck-tooltip>

    <template v-if="withCheckbox">
      <deck-radio
        v-if="item.exclusive"
        :model-value="item.selected"
        :disabled="disabled"
      />
      <deck-checkbox
        v-else
        :model-value="item.selected"
        :disabled="disabled"
        hide-details
      />
    </template>
    <deck-select-list-item v-bind="item">
      <slot
        name="item-content"
        v-bind="{ item }"
      />
    </deck-select-list-item>
  </div>
</template>

<script>
import DeckTooltip from '~/deck/tooltip';
import DeckRadio from '~/deck/radio';
import DeckCheckbox from '~/deck/checkbox';
import DeckSelectListItem from '~/deck/select/_list-item';

export default {
  name: 'DeckSelectListItemSelectable',
  components: {
    DeckTooltip,
    DeckRadio,
    DeckCheckbox,
    DeckSelectListItem,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    withCheckbox: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.deck-select__hint-area {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  pointer-events: auto;
}
</style>
