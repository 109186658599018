export const state = () => ({
  users: [],
  userGroups: [],
});

export const getters = {
  findUserById: state => id => state.users.find(user => user.id === id),
  findUserGroupById: state => id => state.userGroups.find(userGroup => userGroup.id === id),
  usersWithUserGroups: (state) => {
    const users = state.users.map((user) => {
      const userGroups = state.userGroups.filter(
        userGroup => userGroup.members.map(member => member.id).includes(user.id),
      );

      return {
        ...user,
        user_groups: userGroups,
      };
    });

    return users;
  },
};

export const mutations = {
  setUsers(state, users) {
    state.users = users;
  },
  setUserGroups(state, userGroups) {
    state.userGroups = userGroups;
  },
  updateUserGroup(state, userGroupData) {
    const userGroups = state.userGroups.map((userGroup) => {
      if (userGroup.id === userGroupData.id) {
        return userGroupData;
      }

      return userGroup;
    });
    state.userGroups = userGroups;
  },
  updateUser(state, userData) {
    const users = state.users.map((user) => {
      if (user.id === userData.id) {
        return userData;
      }

      return user;
    });
    state.users = users;
  },
};

export const actions = {
  async fetchUsers({ commit }, { includeGuests = true } = {}) {
    const { $api } = useNuxtApp();
    const fetchOptions = includeGuests ? { params: { include_guests: true } } : {};
    const data = await $api.$get('/users', fetchOptions);
    commit('setUsers', data);
  },
  async updateUser({ commit }, { id: userId, ...userData }) {
    const { $api } = useNuxtApp();
    const data = await $api.$patch(`/users/${userId}`, { body: userData });
    commit('updateUser', data);
  },
  async fetchUserGroups({ commit }) {
    const { $api } = useNuxtApp();
    const data = await $api.$get('/user_groups');
    commit('setUserGroups', data);
  },
  async fetchUserGroup({ commit, state }, userGroupId) {
    const { $api } = useNuxtApp();
    const data = await $api.$get(`/user_groups/${userGroupId}`);
    const userGroups = [...state.userGroups.filter(userGroup => userGroup.id), data];
    commit('setUserGroups', userGroups);
  },
  async deleteUserGroup({ commit, state }, userGroupId) {
    const { $api } = useNuxtApp();
    await $api.$delete(`/user_groups/${userGroupId}`);
    commit('setUserGroups', state.userGroups.filter(userGroup => userGroup.id !== userGroupId));
  },
  async createUserGroup({ commit, state }, userGroup) {
    const { $api } = useNuxtApp();
    const data = await $api.$post('/user_groups', { body: userGroup });
    commit('setUserGroups', [...state.userGroups, data]);
  },
  async updateUserGroup({ commit }, { id: userGroupId, ...userGroupData }) {
    const { $api } = useNuxtApp();
    const data = await $api.$patch(`/user_groups/${userGroupId}`, { body: userGroupData });
    commit('updateUserGroup', data);
  },
};

export const namespaced = true;
