import {
  FIELD_TYPE_KEY_DISPLAY_MAPPING,
  FIELD_TYPE_KEY_SORT_MAPPING,
  FIELD_TYPE_FUNCTION_DISPLAY_MAPPING,
} from '~/assets/javascript/constants';
import { arrayToString } from '../string';

const getSelectFieldSortValue = (fieldData, field) => {
  const indexes = fieldData
    .map(({ select_option_id: optionId }) => field.options.select_options.findIndex(({ id }) => id === optionId));

  if (indexes.length === 0) return field.options.select_options.length;

  return indexes.sort()[0];
};

const getFieldKeyValue = (fieldData, field, mapping) => {
  if (!fieldData) return fieldData;

  if (Array.isArray(fieldData)) {
    return fieldData.map(currentField => getFieldKeyValue(currentField, field, mapping));
  }

  const displayKey = mapping[field.type];

  // if display key exists and fieldData is an object
  if (displayKey && typeof fieldData === 'object') {
    const displayName = fieldData[displayKey];

    if (field.type === 'Checklist') {
      return arrayToString(displayName.map(item => FIELD_TYPE_FUNCTION_DISPLAY_MAPPING[field.type](field, item, mapping)));
    }

    if (Array.isArray(displayName) && ['Link', 'Attachment', 'CoverImage'].includes(field.options.lookup_field_type)) {
      return displayName.map(currentField => getFieldKeyValue(currentField, { type: field.options.lookup_field_type }, mapping));
    }

    const displayFunction = FIELD_TYPE_FUNCTION_DISPLAY_MAPPING[field.type];

    if (displayFunction) {
      return displayFunction(field, displayName, mapping);
    }

    return displayName;
  }

  return fieldData;
};

export const getFieldDisplayName = (fieldData, field) => getFieldKeyValue(fieldData, field, FIELD_TYPE_KEY_DISPLAY_MAPPING);

export const getFieldDisplayNameAsString = (fieldData, field) => {
  const displayName = getFieldDisplayName(fieldData, field);

  if (Array.isArray(displayName)) {
    return displayName.flat().join(', ');
  }

  return displayName;
};

export const getFieldSortValue = (fieldData, field) => {
  if (['Select', 'MultipleSelect'].includes(field.type)) return getSelectFieldSortValue(fieldData, field);
  if (field.type === 'Number') return fieldData.value;

  return getFieldKeyValue(fieldData, field, FIELD_TYPE_KEY_SORT_MAPPING);
};

// TODO: refactor this function, assign i18nOptions is not a good idea
export const calculateFieldIdMapping = (fields, locale) => fields.reduce((accumulator, { id, ...fieldSpec }) => {
  accumulator[id] = { id, ...fieldSpec, i18nOptions: { locale } };

  return accumulator;
}, {});

export const calculateFieldFilterOptions = (fieldData, fieldSpec, emptyLabel) => {
  let fieldDisplayName = getFieldDisplayName(fieldData, fieldSpec);

  if (!fieldDisplayName || fieldDisplayName.length === 0) {
    fieldDisplayName = emptyLabel;
  }

  return fieldDisplayName;
};

export const getFieldOutputType = field => (field.type === 'Lookup' ? field.options.lookup_field_type : field.type);
