<i18n lang="yaml">
pt:
  noAppsFound: 'Não encontramos Apps com o termo "{searchedTerm}" filtrando por {category}.'
  trySearchingAgain: 'Tente aplicar outros filtros ou busque por outro termo.'
  suggestToBookADemoHeader: "Não se limite aos Apps lançados!"
  suggestToBookADemoParagraph: 'Te mostramos como criar um para chamar de seu na Zazos!'
  suggestToBookADemoAction: '{bookADemo}'
  bookADemo: 'Agendar uma demonstração'
  suggestMoreCategories: 'Procura por outro app? Explore mais {categories} ou {seeAllAppsAvailable}'
  seeAllAppsAvailable: 'veja todos os apps disponíveis'
  pageTitle: 'Loja de Apps para RH - Customize seu sistema!'
  pageDescription: 'AvD, ATS, Gestão de Contratos e o que mais você imaginar. Explore nossa Loja de Apps e dê superpoderes ao seu time de RH!'
  searchApps: 'Buscar Apps'
en:
  noAppsFound: 'No Apps were found searching for "{searchedTerm}" and filtering by {category}.'
  trySearchingAgain: 'Try applying other filters or searching for another term.'
  suggestToBookADemoHeader: "Don't limit yourself to the Apps we've launched!"
  suggestToBookADemoParagraph: 'We show you how to create one to call your own on Zazos!'
  suggestToBookADemoAction: '{bookADemo}'
  bookADemo: 'Book a demo'
  suggestMoreCategories: 'Looking for another app? Explore more {categories} or {seeAllAppsAvailable}'
  seeAllAppsAvailable: 'see all available apps'
  pageTitle: 'HR App Store - Customize your system!'
  pageDescription: 'Performance Evaluation, ATS, Contract Management and whatever else you can imagine. Explore our App Store and give superpowers to your HR team!'
  searchApps: 'Search Apps'
</i18n>
<template>
  <LogoLoading v-if="loading" />

  <AppStore
    v-else
    ref="appStore"
    :title="$t(`apps.selection.${selectionInfo.name}`)"
    :category-icon="selectionInfo.icon"
    :facets="facets"
    :results-length="resultsCount"
  >
    <div
      v-if="filteredApps.length > 0"
      class="app-store__apps"
    >
      <DeckExodia
        v-for="app in filteredApps"
        :key="app.name"
        show-cover-image
        small
        :title="getAppByName(app.name) && getAppByName(app.name).translated_name"
        :cover-image-url="`apps/card/${app.name}.png`"
        :avatar-icon="getAppByName(app.name) && getAppByName(app.name).icon"
        :to="appRoute(app.name)"
        :title-line-clamp="0"
        class="view-permissions-card"
      />
    </div>

    <div
      v-else
      class="mt-4 mt-md-16 d-flex flex-column align-center justify-center text-body-2 text-md-body-1"
    >
      <v-icon
        :size="$vuetify.display.smAndDown ? '72' : '144'"
        color="grey-lighten-2"
        class="mb-4"
      >
        fa-regular fa-magnifying-glass
      </v-icon>

      <i18n-t
        tag="p"
        class="mb-1 text-center text-grey-darken-1"
        keypath="noAppsFound"
      >
        <template #searchedTerm>
          <strong>{{ searchTerm }}</strong>
        </template>

        <template #selection>
          <strong>{{ $t(`apps.selection.${selectionInfo.name}`) }}</strong>
        </template>

        <template #category>
          <strong>{{ stringifiedSelectedFacets }}</strong>
        </template>
      </i18n-t>

      <p class="text-center text-grey-darken-1">
        {{ t('trySearchingAgain') }}
      </p>

      <p class="mt-4 mt-md-14 mb-0 text-center text-h6">
        {{ t('suggestToBookADemoHeader') }}
      </p>
      <p class="mb-6 text-center">
        {{ t('suggestToBookADemoParagraph') }}
      </p>

      <v-btn
        color="#fd8c41"
        elevation="0"
        rounded
        :href="scheduleDemoLink"
        target="_blank"
        class="mb-6 text-white text-capitalize"
      >
        {{ t('bookADemo') }}
      </v-btn>
    </div>
  </AppStore>
</template>

<script lang="ts">
import { mapActions, mapState, mapGetters } from '~/assets/javascript/modules/vuex';
import { LocalSearchStrategy, SearchEngine } from '~/assets/javascript/utils';
import LogoLoading from '~/components/LogoLoading';
import DeckExodia from '~/deck/exodia';
import ADDITIONAL_CATEGORIES from '~/assets/javascript/constants/additional-categories';
import RECOMMENDED_APPS from '~/assets/javascript/constants/recommended-apps';
import FACET_TYPES from '~/assets/javascript/constants/facet-types';
import { SCHEDULE_DEMO_LINK } from '~/assets/javascript/constants';
import AppStore from '~/components/store/app-store';

const scheduleDemoLink = () => {
  const url = new URL(SCHEDULE_DEMO_LINK);

  url.searchParams.set('utm_source', 'app_zazos');
  url.searchParams.set('utm_medium', 'empty_search_result_app_store');

  return url.toString();
};

export default defineComponent({
  name: 'StorePage',
  components: {
    LogoLoading,
    DeckExodia,
    AppStore,
  },
  layout: 'store',
  setup() {
    const { t } = useI18n();

    definePageMeta({
      layout: 'store',
    });

    buildHead({
      titleTemplate: '%s ⚡️Zazos',
      title: t('pageTitle'),
      description: t('pageDescription'),
    });

    return {
      t,
      ...mapState('apps', ['apps', 'appInstallations']),
      ...mapGetters('apps', ['getAppByName']),
      ...mapActions('apps', ['loadApps', 'loadAppInstallations', 'loadPublicApps']),
    };
  },
  data() {
    return {
      loading: false,
      scheduleDemoLink: scheduleDemoLink(),
    };
  },
  computed: {
    hasStudioAccess() {
      return this.$auth.loggedIn && this.$auth.user.role === 'admin';
    },
    installedApps() {
      return this.apps.filter(app => this.appInstallations.find(installedApp => installedApp.name === app.name));
    },
    recommendedApps() {
      return this.apps.filter(app => RECOMMENDED_APPS.find(recommendedApp => recommendedApp === app.name));
    },
    selectionInfo() {
      const currentSelectionIcon = ADDITIONAL_CATEGORIES.find(category => category.key === this.selection)?.icon;

      return {
        name: this.selection,
        icon: currentSelectionIcon,
      };
    },
    filteredApps() {
      return this.searchResult.result;
    },
    facets() {
      return this.searchResult.facets;
    },
    availableFacets() {
      return FACET_TYPES.map(facet => facet.key);
    },
    resultsCount() {
      return this.searchResult.count;
    },
    selectedFacets() {
      return SearchEngine.extractSelectedFacetsFromQueryParams(this.$route.query || {}, this.availableFacets);
    },
    stringifiedSelectedFacets() {
      const localizedSelectedFacets = [];

      Object.entries(this.selectedFacets).forEach(([facetKey, facet]) => {
        facet.forEach((selection: string) => {
          localizedSelectedFacets.push(this.t(`apps.${facetKey}.${selection}`));
        });
      });

      return localizedSelectedFacets.join(', ');
    },
    searchResult() {
      const searchEngine = new SearchEngine(this.searchStrategy);
      return searchEngine.search(this.searchTerm, this.selectedFacets);
    },
    searchData() {
      return this.appsBySelection.filter(app => !app.archived);
    },
    searchStrategy() {
      return new LocalSearchStrategy({ items: this.searchData, facetKeys: this.availableFacets, searchableKeys: ['translated_name', 'description'] });
    },
    searchTerm() {
      return this.$route.query.searchTerm;
    },
    selection() {
      return this.$route.query.selection || 'all';
    },
    appsBySelection() {
      if (this.selection === 'installed') {
        return this.installedApps;
      }

      if (this.selection === 'recommended') {
        return this.recommendedApps;
      }

      return this.apps;
    },
  },
  watch: {
    filteredApps(filteredApps) {
      if (this.apps.length === 0 || filteredApps.length > 0) return;

      window.analytics.track('emptyAppSearch', {
        search: this.searchTerm,
        selection: this.selection,
        facets: this.selectedFacets,
      });
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;

      if (this.hasStudioAccess) {
        await Promise.all([this.loadApps(), this.loadAppInstallations()]);
      } else {
        await this.loadPublicApps();
      }

      this.loading = false;
    },
    appRoute(appName) {
      return this.localePath(({
        name: 'store-apps-appName',
        params: { appName },
      }));
    },
    // eslint-disable-next-line vue/no-unused-properties
    clearSearch() {
      this.$router.replace({
        query: _omit(this.$route.query, ['searchTerm']),
      });
    },
  },
});
</script>
<style lang="scss">
@use '~/assets/styles/tools/fluid-grid' as fluid-grid;

.app-store__apps {
  @include fluid-grid.fluid-grid(5, 24px, 200px);
  margin-bottom: 40px; // for overflow breathing room
}
</style>
