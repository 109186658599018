import { Auth, ExpiredAuthSessionError } from '#auth/runtime'
import { defineNuxtPlugin, useRuntimeConfig } from '#imports'
import { defu } from 'defu';

// Active schemes
import { LocalScheme } from '#auth/runtime'

// Options
let options = {
    "globalMiddleware": false,
    "enableMiddleware": true,
    "resetOnError": false,
    "resetOnResponseError": false,
    "ignoreExceptions": false,
    "scopeKey": "scope",
    "rewriteRedirects": true,
    "fullPathRedirect": true,
    "redirectStrategy": "storage",
    "watchLoggedIn": true,
    "tokenValidationInterval": false,
    "redirect": {
        "login": "\u002Flogin",
        "logout": "\u002Flogin",
        "home": "\u002F",
        "callback": "\u002F"
    },
    "stores": {
        "state": {
            "namespace": "auth"
        },
        "pinia": {
            "enabled": false,
            "namespace": "auth"
        },
        "cookie": {
            "enabled": true,
            "prefix": "auth.",
            "options": {
                "path": "\u002F",
                "sameSite": "lax",
                "maxAge": 31536000
            },
            "secure": true
        },
        "local": {
            "enabled": false,
            "prefix": "auth."
        },
        "session": {
            "enabled": false,
            "prefix": "auth."
        }
    },
    "defaultStrategy": "local"
}

export default defineNuxtPlugin({
    name: 'nuxt-alt:auth',
    async setup(nuxtApp) {
        // Create a new Auth instance
        const auth = new Auth(nuxtApp, options)

        // Register strategies
        auth.registerStrategy('local', new LocalScheme(auth, defu(useRuntimeConfig()?.public?.auth?.strategies?.['local'], {"token":{"property":"token","type":"Bearer"},"user":{"property":"user"},"endpoints":{"login":{"url":"authentication","method":"post"},"user":{"url":"authentication/user","method":"get"},"logout":false},"name":"local","ssr":false})));
auth.registerStrategy('magicLink', new LocalScheme(auth, defu(useRuntimeConfig()?.public?.auth?.strategies?.['magicLink'], {"token":{"property":"token","type":"Bearer"},"user":{"property":"user"},"endpoints":{"login":{"url":"authentication/magic_link","method":"post"},"user":{"url":"authentication/user","method":"get"},"logout":false},"name":"magicLink","ssr":false})))

        nuxtApp.provide('auth', auth)

        return auth.init()
        .catch(error => {
            if (process.client) {
                // Don't console log expired auth session errors. This error is common, and expected to happen.
                // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
                // token. We don't want to log this as an error.
                if (error instanceof ExpiredAuthSessionError) {
                    return
                }

                console.error('[ERROR] [AUTH]', error)
            }
        })
    }
})