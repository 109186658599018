import { cloneDeep, omit, pick, isEmpty } from 'lodash';
import {
  getSelfLinkFields,
  formatRecords,
  formatRecordToImport,
  validateAndFilterRecordForImport,
  OMITTED_COLUMNS,
  UPDATE_FORMAT,
  STEPS,
} from '~/assets/javascript/store-helpers/import';

export const state = () => ({
  step: STEPS.initial,
  importedRecords: [],
  users: [],
  sheets: [],
  matchedColumns: {},
  rawImportedRecords: [],
  removedColumns: [],
  separator: undefined,
});

export const getters = {
  sheet: (_, { view }) => view.sheet,
  view: (_state, _getters, { builderView }) => builderView?.view,
  records: ({ importedRecords, matchedColumns, users, sheets, rawImportedRecords, separator }) => (sheets.length > 0 ? formatRecords(
    cloneDeep(importedRecords),
    matchedColumns,
    users,
    sheets,
    rawImportedRecords,
    separator,
  ) : []),
  recordsCounts: (_, { records }) => records.reduce((acc, record) => {
    acc[record.state || 'pending'] += 1;
    return acc;
  }, { total: records.length, error: 0, disabled: 0, 'success-first-step': 0, success: 0, pending: 0 }),
  importedRecordsCount: (_, { records }) => records.filter(r => r.state === 'success').length,
  fields: ({ matchedColumns, removedColumns }, { records }) => (overrideAttributes) => {
    if (!records) return [];

    const columns = Object.keys(omit(records[0], OMITTED_COLUMNS));

    return columns.map((key) => {
      const field = matchedColumns[key] || {};
      const removed = removedColumns.includes(key);
      const customAttributes = {};
      let label = key;

      if (field.name) customAttributes.labelClass = overrideAttributes.matchedLabelClass;
      else if (removed) customAttributes.labelClass = overrideAttributes.removedLabelClass;
      else customAttributes.labelClass = overrideAttributes.unmatchedLabelClass;

      if (removed) {
        label = overrideAttributes.removedLabel;
      } else if (!field.name) {
        label = overrideAttributes.unmatchedLabel;
      }

      return {
        label,
        customAttributes,
        removed,
        id: key,
        type: field.type || 'String',
        name: field.name || key,
        system_name: field.system_name,
        options: { ...field.options },
        rules: field.rules,
      };
    });
  },
  finished: (_, { records, importedRecordsCount }) => records.length > 0 && records.length === importedRecordsCount,
  started: (_, { importedRecordsCount }) => importedRecordsCount > 0,
  importingRecordsLinkOptions: ({ rawImportedRecords }, { sheet, view }) => {
    if (!view || !rawImportedRecords) return {};
    const primaryFieldId = sheet.primary_field_id || view.fields[0].id;
    const primaryField = view.fields.find(({ id }) => id === primaryFieldId);

    return {
      [sheet.id]: rawImportedRecords.map(record => ({ text: record[primaryField.name], value: record.id })),
    };
  },
};

export const mutations = {
  setStep(state, step) {
    state.step = step;
  },
  setImportedRecords(state, records) {
    state.importedRecords = records.map((record, index) => {
      record.id = index.toString();
      return record;
    });

    state.rawImportedRecords = cloneDeep(state.importedRecords);
  },
  setMatchedColumn(state, { fieldId, field }) {
    state.matchedColumns[fieldId] = field;
  },
  setRecordState(state, { recordIndex, recordState }) {
    const row = state.importedRecords[recordIndex];
    row.state = recordState;
    state.importedRecords.splice(recordIndex, 1, row);
  },
  removeMatchedColumn(state, fieldId) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete state.matchedColumns[fieldId];
    state.removedColumns.push(fieldId);
  },
  enableColumn(state, fieldId) {
    state.removedColumns.splice(state.removedColumns.indexOf(fieldId), 1);
  },
  updateImportedRecordData(state, { recordId, fieldId, value }) {
    const field = state.matchedColumns[fieldId];

    const data = state.importedRecords[recordId];
    data[fieldId] = value;

    if (field && UPDATE_FORMAT[field.type]) {
      UPDATE_FORMAT[field.type]({ record: data, field, fieldId });
    }
  },
  setUsers(state, users) {
    state.users = users;
  },
  setSheets(state, sheets) {
    state.sheets = sheets;
  },
  reset(state) {
    state.step = STEPS.initial;
    state.importedRecords = [];
    state.matchedColumns = {};
    state.removedColumns = [];
    state.users = [];
  },
  setSeparator(state, separator) {
    state.separator = separator;
  },
};

export const actions = {
  restartImport({ commit }) {
    commit('reset');
  },
  async setStep({ commit, dispatch }, step) {
    await commit('setStep', step);

    if (step === STEPS.manipulation) {
      await dispatch('loadUsers');
    }
  },
  async loadSheets({ commit }) {
    const sheets = await useNuxtApp().$api.$get('/sheets');

    await Promise.all(sheets.map(async (sheet) => {
      const defaultView = sheet.views.find(view => view.page_data_default);
      sheet.view = await useNuxtApp().$api.$get(`/builder/views/${defaultView.id}`);
    }));

    return commit('setSheets', sheets);
  },
  async loadUsers({ commit }) {
    const users = await useNuxtApp().$api.$get('/users');
    return commit('setUsers', users);
  },
  async importFormattedRecords({ state, commit, dispatch, getters: { records, sheet } }, selected) {
    const recordsToImport = records.filter(validateAndFilterRecordForImport(state, selected, commit));
    const selfLinkFields = getSelfLinkFields(Object.values(state.matchedColumns), state.sheets);
    const selfLinkFieldIds = selfLinkFields.map(field => field.id);
    const recordsToImportIdsMapping = {};
    const selfLinkFieldsData = {};
    const recordsIdsNotImported = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const record of recordsToImport) {
      let newRecord;
      let recordIndex;

      try {
        const { fields, user } = formatRecordToImport(record, state.matchedColumns, state.removedColumns);
        recordIndex = parseInt(record.id, 10);
        selfLinkFieldsData[recordIndex] = pick(fields, selfLinkFieldIds);

        const body = {
          values: omit(fields, selfLinkFieldIds),
          sheet_id: sheet.id,
        };

        if (user?.id) {
          body.user_id = user.id;
        }

        // eslint-disable-next-line no-await-in-loop
        newRecord = await useNuxtApp().$api.$post('/records', { body });
        recordsToImportIdsMapping[recordIndex] = newRecord.id;

        if (isEmpty(selfLinkFieldsData[recordIndex])) {
          commit('setRecordState', { recordIndex, recordState: 'success' });
        } else {
          commit('setRecordState', { recordIndex, recordState: 'success-first-step' });
        }
      } catch (e) {
        if (newRecord?.id) {
          // eslint-disable-next-line no-await-in-loop
          await useNuxtApp().$api.delete(`/records/${newRecord.id}`);
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete recordsToImportIdsMapping[recordIndex];
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete selfLinkFieldsData[recordIndex];
        }

        recordsIdsNotImported.push(recordIndex);

        useNuxtApp().$rollbar.error('importFormattedRecords', e, e.response);
        commit('setRecordState', { recordIndex, recordState: 'error' });
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const [recordIndexStr, selfLinkFieldsDataForRecord] of Object.entries(selfLinkFieldsData)) {
      const recordIndex = parseInt(recordIndexStr, 10);
      const recordId = recordsToImportIdsMapping[recordIndex];

      // eslint-disable-next-line no-continue
      if (isEmpty(selfLinkFieldsDataForRecord)) continue;
      // eslint-disable-next-line no-continue
      if (recordsIdsNotImported.includes(recordIndex)) continue;

      try {
        // eslint-disable-next-line no-restricted-syntax
        for (const [fieldId, values] of Object.entries(selfLinkFieldsDataForRecord)) {
          // eslint-disable-next-line no-restricted-syntax
          for (const value of values.filter(v => !recordsIdsNotImported.includes(parseInt(v, 10)))) {
            const params = {
              foreign_record_id: recordsToImportIdsMapping[value] || value,
              field_id: fieldId,
            };

            const path = `records/${recordsToImportIdsMapping[recordIndex]}/link`;
            // eslint-disable-next-line no-await-in-loop
            await useNuxtApp().$api.$post(path, { body: params });
          }
        }

        commit('setRecordState', { recordIndex, recordState: 'success' });
      } catch (e) {
        if (recordId) {
          // eslint-disable-next-line no-await-in-loop
          await useNuxtApp().$api.delete(`/records/${recordId}`);
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete recordsToImportIdsMapping[recordIndex];
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete selfLinkFieldsData[recordIndex];
        }

        recordsIdsNotImported.push(recordIndex);

        useNuxtApp().$rollbar.error('importFormattedRecords', e, e.response);
        commit('setRecordState', { recordIndex, recordState: 'error' });
      }
    }

    await dispatch('loadUsers');
  },
};

export const namespaced = true;
