<template>
  <LogoLoading />
</template>

<script lang="ts">
import LogoLoading from '~/components/LogoLoading';

export default defineComponent({
  name: 'PagesTenantStudioDataIndex',
  components: { LogoLoading },
  setup() {
    definePageMeta({
      layout: false,
      middleware: [
        async () => {
          const { $api } = useNuxtApp();
          const allData = await $api.$get('/sheets');
          const peopleSheetId = allData.find(sheet => sheet.system_name === 'people').id;
          const localePath = useLocalePath();
          const { user } = useAuth();

          return navigateTo(localePath(
            {
              name: 't-tenantSlug-studio-data-id',
              params: {
                tenantSlug: user.workspace.slug,
                id: peopleSheetId,
              },
            },
          ));
        },
      ],
    });
  },
});
</script>
