<template>
  <div
    class="deck-select-list-item"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <deck-icon
      v-if="icon"
      :name="icon"
      size="small"
      :kind="iconKind"
      color="var(--z-color-label)"
      class="flex-none"
      fixed-width
    />

    <deck-avatar
      v-if="avatar"
      :name="avatar.name"
      :image-src="avatar.src"
      class="flex-none"
      size="small"
    />

    <div class="deck-select-list-item__text-wrapper">
      <deck-trimmer
        class="deck-select-list-item__text"
        tag="span"
        v-bind="textTrimmerProps"
      >
        <slot>
          {{ text }}
        </slot>
      </deck-trimmer>

      <div
        v-if="$slots['actions']"
        class="d-flex g-1 align-center justify-start overflow-hidden"
      >
        <slot
          name="actions"
          v-bind="{ hover }"
        />
      </div>

      <div
        v-if="hasTags"
        class="deck-select-list-item__tags"
      >
        <deck-chip
          v-for="tag, index in tags"
          v-bind="tag"
          :key="index"
          :size="tag.size || 'x-small'"
        />
      </div>
    </div>
  </div>
</template>
<script>
import DeckTrimmer from '~/deck/trimmer';

export default {
  name: 'DeckSelectListItem',
  components: {
    DeckIcon: defineAsyncComponent(() => import('~/deck/icon')),
    DeckAvatar: defineAsyncComponent(() => import('~/deck/avatar')),
    DeckChip: defineAsyncComponent(() => import('~/deck/chip')),
    DeckTrimmer,
  },
  props: {
    /**
     * The text to display.
     * @type {string}
     * @required
     */
    text: {
      type: String,
      required: true,
    },

    /**
     * The icon to display.
     * @type {string}
     * @default undefined
     */
    icon: {
      type: String,
      default: undefined,
    },

    /**
     * The avatar to display.
     * @type {string}
     * @default undefined
     */
    avatar: {
      type: Object,
      default: undefined,
    },

    /**
     * The icon kind to display.
     * @type {string}
     * @default regular
     */
    iconKind: {
      type: String,
      default: 'regular',
    },

    /**
     * Informational tags to display at the end of the list item. Defined as a
     * list of objects of DeckChip props. This uses precious real-estate, so
     * use sparingly and preferrably only one.
     * @type {Array}
     * @default undefined
     */
    tags: {
      type: [Array],
      default: undefined,
    },

    /**
     * Props to pass to the DeckTrimmer component of the default slot.
     * @type {Object}
     * @default undefined
     */
    textTrimmerProps: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    hasTags() {
      return this.tags?.length > 0;
    },
  },
};
</script>
<style lang="scss">
.deck-select-list-item {
  flex: auto;
  display: flex;
  align-items: center;
  gap: var(--z-s2);
  font-size: 14px;
}

.deck-select-list-item__text-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  height: stretch;
  min-width: 0;
}

.deck-select-list-item__text {
  flex-grow: 1;
  min-width: 5ch;
  overflow: hidden;
  color: var(--z-color-text);
}

.deck-select-list-item__tags {
  display: flex;
  align-items: center;
  gap: var(--z-s1);
  padding-left: var(--z-s2);
}
</style>
