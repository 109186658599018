<template>
  <div
    v-if="kind === 'action'"
    class="deck-button-group deck-button-group--action"
  >
    <deck-button
      v-for="(button, index) in computedButtons"
      v-bind="button"
      :key="index"
      :model-value="button.value"
      is-ready
      class="deck-button-group__button"
      @click="buttonClickHandler(button)"
    />
  </div>

  <v-btn-toggle
    v-else
    v-model="internalValue"
    :mandatory="mandatory"
    :multiple="kind === 'checkbox'"
    :class="`deck-button-group--${kind} deck-button-group--${buttonsKind}`"
    class="deck-button-group"
    density="compact"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <deck-button
      v-for="(button, index) in computedButtons"
      v-bind="button"
      :key="index"
      :model-value="button.value"
      class="deck-button-group__button"
      is-ready
    />
  </v-btn-toggle>
</template>

<script>
import DeckButton from '~/deck/button';

/**
 * Represents a group of buttons with a related set of actions.
 * Useful for defining toolbars, and groups of actions.
 * Each button can fire specific actions, may be toggled on/off individually or as
 * a group (only one active at a time).
 */
export default {
  components: {
    DeckButton,
  },
  props: {
    /**
     * The current model of the selected button(s).
     * Only used when the `kind` prop is set to `radio` or `checkbox`.
     * @type {any}
     * @default undefined
     */

    modelValue: {
      type: [String, Number, Boolean, Object, Array],
      default: undefined,
    },

    /**
     * The array of button definitions to render as DeckButton components.
     * Use valid DeckButton props.
     * For actions, pass a `value` property to define as the payload for the
     * `action` event when that specific button is clicked. Handle the event in
     * the parent component appropriately to your case.
     * @required
     */
    buttons: {
      type: Array,
      required: true,
    },

    /**
     * Whether the buttons behave like actions (no toggling), like checkboxes
     * (multi-select) or like radio buttons (single-select).
     * @type {'action' | 'checkbox' | 'radio'}
     * @default 'action'
     */
    kind: {
      type: String,
      default: 'action',
    },

    /**
     * The size of the buttons.
     * @type {'small' | 'default' | 'large'}
     * @default 'default'
     */
    size: {
      type: String,
      default: 'default',
    },

    /**
     * Whether at least one button must be selected when the kind is `checkbox`
     * or `radio`.
     * @type {boolean}
     * @default false
     */
    mandatory: {
      type: Boolean,
      default: false,
    },

    /**
     * The default color to be used for the buttons.
     * Uses the color definitions from DeckButton.
     * @type {string}
     * @default 'primary'
     */
    color: {
      type: String,
      default: 'primary',
    },

    /**
     * The default kind to be used for all buttons.
     * As a default state, it only makes sense to use secondary or ghost. If you
     * want to use a primary button, define a single button as so in the buttons
     * array.
     * Usage of `inline` is not recommended, since a button group should not be
     * rendered within some text.
     * @type {'secondary' | 'ghost' | string}
     * @default 'secondary'
     */
    buttonsKind: {
      type: String,
      default: 'secondary',
    },
  },
  emits: ['action', 'update:modelValue'],
  data() {
    return {
      internalValue: this.modelValue || [],
    };
  },
  computed: {
    computedButtons() {
      return this.buttons.map(button => ({
        ...button,
        kind: button.kind || this.buttonsKind,
        color: button.color || this.color,
        size: button.size || this.size,
        toggled: this.kind === 'checkbox' ? this.internalValue.includes(button.value) : this.internalValue === button.value,
      }));
    },
  },
  watch: {
    internalValue(newValue) {
      /**
       * Triggered when the button group model is modified. Sends its value as the
       * payload.
       * @event input
       */
      this.$emit('update:modelValue', newValue);
    },
    modelValue(newValue) {
      this.internalValue = newValue;
    },
  },
  methods: {
    buttonClickHandler(button) {
      if (this.kind === 'action') {
        /**
         * Triggered when an action button is clicked. Sends the button defined
         * value as the payload.
         * @event action
         */
        this.$emit('action', button.value);
      }
    },
  },
};
</script>
<style lang="scss">
.deck-button-group {
  display: inline-flex;
  flex-wrap: wrap;
  gap: var(--deck-button-group-gap);
  border-radius: 8px !important;
  background-color: unset !important;
  height: auto !important;
  min-height: 36px !important;
}

.deck-button-group--action {
  --deck-button-group-gap: 0px;
  --deck-button-group-button-radius: 0px;
}

.deck-button-group--checkbox {
  --deck-button-group-gap: 1px;
  --deck-button-group-button-radius: 2px;

  border-radius: 8px;
}

.deck-button-group--radio {
  --deck-button-group-gap: 0;
  --deck-button-group-button-radius: 0;

  border-radius: 8px;
}

.deck-button-group--ghost {
  --deck-button-group-gap: 4px;
  --deck-button-group-button-radius: 8px;
}

.deck-button-group__button {
  border: 0 !important;
  opacity: 1 !important; // v-btn-toggle v-btn overrride
  padding-inline: var(--deck-button-padding-inline) !important; // v-btn-toggle v-btn overrride

  &:not(:first-child) {
    border-top-left-radius: var(--deck-button-group-button-radius) !important;
    border-bottom-left-radius: var(--deck-button-group-button-radius) !important;
  }

  &:not(:last-child) {
    border-top-right-radius: var(--deck-button-group-button-radius) !important;
    border-bottom-right-radius: var(--deck-button-group-button-radius) !important;
  }

  &.deck-buton--is-ghost {
    border-radius: 8px !important;
  }
}
</style>
