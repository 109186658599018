<template>
  <div
    class="deck-select-selection"
    :class="classes"
  >
    <deck-select-list-item
      v-if="!multiple || item.exclusive"
      :text="item.selectionText || item.text"
      v-bind="item"
      class="deck-select-selection__single"
    >
      <slot
        name="selection"
        v-bind="{ item }"
      />
      <template #actions="{ hover }">
        <slot
          name="selected-item-actions"
          v-bind="{ item, hover }"
        />
      </template>
    </deck-select-list-item>

    <slot
      v-else
      name="selection"
      v-bind="{ item }"
    >
      <deck-chip
        size="small"
        :icon-kind="item.iconKind || 'regular'"
        :icon-prepend="item.icon"
        :text="item.selectionText || item.text"
        class="deck-select-selection__multiple"
      >
        <template #append>
          <deck-button
            v-if="!disabled"
            :disabled="item.disabled"
            :text="$t('global.clear')"
            size="small"
            kind="ghost"
            icon="xmark"
            color="controls"
            class="deck-select-selection__multiple-clear"
            is-ready
            @mousedown.stop="$emit('clearItem')"
          />
        </template>
      </deck-chip>
    </slot>
  </div>
</template>

<script>
import DeckSelectListItem from '~/deck/select/_list-item';
import DeckChip from '~/deck/chip';
import DeckButton from '~/deck/button';

export default {
  name: 'DeckSelectSelection',
  components: {
    DeckSelectListItem,
    DeckChip,
    DeckButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'dense',
    },
    isSearching: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['clearItem'],
  computed: {
    classes() {
      return {
        'deck-select-selection--multiple': this.multiple,
        'deck-select-selection--disabled': this.disabled,
        [`deck-select-selection--${this.size}`]: true,
        'deck-select-selection--searching': this.isSearching,
      };
    },
  },
};
</script>

<style lang="scss">
.deck-select-selection {
  display: flex;
  align-items: center;
}

.deck-select-selection__single {
  position: absolute;
  width: stretch !important;
  height: stretch;
  font-size: var(--deck-select-selection-font-size);
}

.deck-select-selection__multiple-clear {
  margin-right: var(--z-s3-n); // To touch the right edge of the chip

  // Super edge-case to override deck-button consistent styles. This feels ok, No need to extend it just for this.
  border-radius: 50% !important;
  --deck-button-height: 24px;
}

.deck-select-selection--multiple {
  margin: 2px;
}

.deck-select-selection--dense {
  --deck-select-selection-font-size: var(--z-font-size-small);
}

.deck-select-selection--medium {
  --deck-select-selection-font-size: var(--z-font-size-medium);
}

.deck-select-selection--searching {
  .deck-select-selection__single {
    opacity: 0;
  }
}
</style>
