export const DYNAMIC_DATES = Object.freeze([
  { key: 'now', type: 'dynamic_date_time' },
  { key: 'today', type: 'dynamic_date' },
  { key: 'tomorrow', type: 'dynamic_date' },
  { key: 'yesterday', type: 'dynamic_date' },
  { key: 'next_week', type: 'dynamic_date' },
  { key: 'last_week', type: 'dynamic_date' },
  { key: 'next_month', type: 'dynamic_date' },
  { key: 'last_month', type: 'dynamic_date' },
  { key: 'next_year', type: 'dynamic_date' },
  { key: 'last_year', type: 'dynamic_date' },
]);
