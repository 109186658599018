import { truncate } from 'lodash';

/**
 * @typedef {Object} HeadOptions
 * @property {(string|{value: string, truncate?: boolean})} [title] - The title of the page. If an object is provided, the `value` property is used as the title and the `truncate` property is used to set an SEO-friendly text limit.
 * @property {(string|{value: string, truncate?: boolean})} [description] - The description of the page. If an object is provided, the `value` property is used as the description and the `truncate` property is used to set an SEO-friendly text limit.
 * @property {Object} [imageInfo] - An object containing information about the image to use for social sharing.
 * @property {string} [imageInfo.url] - The URL of the image. This must be an absolute URL.
 * @property {string} [imageInfo.type] - The MIME type of the image.
 * @property {string} [imageInfo.alt] - The alternative text for the image.
 * @property {number} [imageInfo.width] - The width of the image in pixels.
 * @property {number} [imageInfo.height] - The height of the image in pixels.
 * @property {string} [titleTemplate] - The template for the page title in case you want to override the default one.
 * @see {@link https://vue-meta.nuxtjs.org/api/#metainfo-properties|vue-meta documentation}
 */

const MAX_TITLE_LENGTH = 60;
const MAX_DESCRIPTION_LENGTH = 155;

/**
 * Truncates the text to a specified length and adds an ellipsis to the end.
 * @param {string} text - The text to truncate.
 * @param {number} maxLength - The maximum length of the truncated text.
 * @returns {string} The truncated text with ellipsis.
 */
function truncateText(text: string, maxLength: number) {
  return truncate(text, { length: maxLength, omission: '...' });
}

type MaybeComputed<T> = T | ComputedRef<T>;

type BuildHeadOptions = {
  title?: MaybeComputed<string | { value: string, truncate?: boolean }>,
  description?: MaybeComputed<string | { value: string, truncate?: boolean }>,
  imageInfo?: {
    url: string,
    type: string,
    alt: string,
    width: number | null,
    height: number | null,
  },
  url?: MaybeComputed<string>,
  titleTemplate?: MaybeComputed<string>,
}

export const buildHead = (options: BuildHeadOptions = {}) => {
  const { $store, $localeHead } = useNuxtApp();
  const workspaceLogo = $store.state.workspace.workspace.logo_image?.download_url?.logo;
  const workspaceName = $store.state.workspace.workspace.name;
  const titlePrepend = workspaceName ? ` - ${workspaceName} ` : ' ';
  const i18nHead = $localeHead({ addSeoAttributes: true });

  const imageInfo = {
    url: options.imageInfo?.url || workspaceLogo,
    type: options.imageInfo?.type || 'avif', // Default type of our hosted Workspace logos
    alt: options.imageInfo?.alt || workspaceName,
    width: options.imageInfo?.width,
    height: options.imageInfo?.height,
  };

  i18nHead.titleTemplate = computed(() => unref(options.titleTemplate) || `%s${titlePrepend}⚡️Zazos`);

  if (options.title) {
    const title = computed(() => {
      const titleOption = unref(options.title);

      const [text, truncate] = typeof titleOption === 'object'
        ? [titleOption.value, titleOption.truncate]
        : [titleOption || '', false];

      return (truncate ? truncateText(text, MAX_TITLE_LENGTH) : text);
    });

    i18nHead.title = title;
    i18nHead.meta.push({ hid: 'og:title', property: 'og:title', content: title.value });
  }

  if (options.description) {
    const description = computed(() => {
      const descriptionOption = unref(options.description);

      const [text, truncate] = typeof descriptionOption === 'object'
        ? [descriptionOption.value, descriptionOption.truncate]
        : [descriptionOption || '', false];

      return (truncate ? truncateText(text, MAX_DESCRIPTION_LENGTH) : text);
    });

    i18nHead.description = description;
    i18nHead.meta.push({ hid: 'og:description', property: 'og:description', name: 'og:description', content: description.value });
    i18nHead.meta.push({ hid: 'description', property: 'description', name: 'description', content: description.value });
  }

  if (imageInfo.url) {
    i18nHead.meta.push({ hid: 'og:image', property: 'og:image', content: imageInfo.url });
  }

  if (imageInfo.type) {
    i18nHead.meta.push({ hid: 'og:image:type', property: 'og:image:type', content: `image/${imageInfo.type}` });
  }

  if (imageInfo.width) {
    i18nHead.meta.push({ hid: 'og:image:width', property: 'og:image:width', content: imageInfo.width.toString() });
  }

  if (imageInfo.height) {
    i18nHead.meta.push({ hid: 'og:image:height', property: 'og:image:height', content: imageInfo.height.toString() });
  }

  useHead(i18nHead);
};
