
import ChartInfoComponentFromOptionBuilder from './chart';
import FieldInfoComponentFromOptionBuilder from './field';
import GroupInfoComponentFromOptionBuilder from './group';
import MarkdownInfoComponentFromOptionBuilder from './markdown';

const infoComponentFromOptionBuilders = {
  chart: ChartInfoComponentFromOptionBuilder,
  field: FieldInfoComponentFromOptionBuilder,
  group: GroupInfoComponentFromOptionBuilder,
  markdown: MarkdownInfoComponentFromOptionBuilder,
};

export default class InfoComponentFromOptionBuilder {
  static build(infoComponent) {
    return new InfoComponentFromOptionBuilder(infoComponent).build();
  }

  constructor(infoComponentOption) {
    this.builder = new infoComponentFromOptionBuilders[infoComponentOption.type](infoComponentOption);
  }

  build() {
    return this.builder.build();
  }
}

