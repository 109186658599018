import { default as indexl0MofkPMrhMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/index.vue?macro=true";
import { default as indexNEP1nYLbHMMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/login/index.vue?macro=true";
import { default as indexhDBvHobNdEMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/magic-link/index.vue?macro=true";
import { default as not_45yet6rDJfhZv4eMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/not-yet.vue?macro=true";
import { default as indexOmREa6cD7IMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/reset-password/index.vue?macro=true";
import { default as set_45workspacefYvLggPxaZMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/set-workspace.vue?macro=true";
import { default as indexLbk4XLsJvhMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/sign-up/index.vue?macro=true";
import { default as _91appName_93oQqYrJDNAZMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/store/apps/[appName].vue?macro=true";
import { default as indexlzB54WC9BjMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/store/index.vue?macro=true";
import { default as indexNdEdM4qXZ0Meta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue?macro=true";
import { default as indexgYFCsPLuPGMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/index.vue?macro=true";
import { default as indexnSjjzVMLQHMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/loading/index.vue?macro=true";
import { default as indexJrEyrJYO6QMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/login/index.vue?macro=true";
import { default as indexOuFGqixHwhMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue?macro=true";
import { default as indexcrZ4bzqDsfMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue?macro=true";
import { default as newRqQun4QS1DMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue?macro=true";
import { default as account_45configurationjGRiJLiAKdMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue?macro=true";
import { default as indexopkzX2fBbgMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/m/index.vue?macro=true";
import { default as mOEGutXQEpTMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/m.vue?macro=true";
import { default as indexo6P49wVGjIMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue?macro=true";
import { default as index4lJl6IUbFoMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue?macro=true";
import { default as indexo1RO6m2pNhMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/p/index.vue?macro=true";
import { default as reset_45passwordu1lz8iXIzcMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue?macro=true";
import { default as indexsaMDj0xulNMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue?macro=true";
import { default as indexWYrDbh59IYMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue?macro=true";
import { default as historyXxabcTSgQ9Meta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue?macro=true";
import { default as indexXCDVmVw1PmMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue?macro=true";
import { default as indexr25NgokuBOMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue?macro=true";
import { default as importh47dFdo9KJMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue?macro=true";
import { default as indexzAL8ipbeFPMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue?macro=true";
import { default as schemaQQje88UE7MMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue?macro=true";
import { default as indexSptQwDtoFUMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue?macro=true";
import { default as indexqWoS6AJffOMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue?macro=true";
import { default as _91id_93X1X6irCg03Meta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue?macro=true";
import { default as indexxKLByu1kzVMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue?macro=true";
import { default as indexUNJb9QrZcsMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue?macro=true";
import { default as indexHzv2fapI1sMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue?macro=true";
import { default as index7cQKTliFTAMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue?macro=true";
import { default as indexSfS9kPqwztMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue?macro=true";
import { default as _91id_93S8yhbdkY2aMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[id].vue?macro=true";
import { default as indexuVpTCjXob6Meta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue?macro=true";
import { default as integrationsbgpYujvmIYMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue?macro=true";
import { default as indexZEPme8XipjMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue?macro=true";
import { default as indexrhVADf2pCHMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue?macro=true";
import { default as index5G5MHUepckMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue?macro=true";
import { default as _91id_93oWMKdZeguTMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue?macro=true";
import { default as indexk7JPZnIwXHMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue?macro=true";
import { default as newhj9MUuqcGzMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue?macro=true";
import { default as user_45groups7gvhY0WIHFMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue?macro=true";
import { default as indexvtFVgEJDECMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue?macro=true";
import { default as settingsZNnOKo66LqMeta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue?macro=true";
import { default as studio45rtjsFxS0Meta } from "/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio.vue?macro=true";
export default [
  {
    name: "index___en",
    path: "/en",
    meta: indexl0MofkPMrhMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/",
    meta: indexl0MofkPMrhMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: indexNEP1nYLbHMMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/login/index.vue")
  },
  {
    name: "login___pt",
    path: "/login",
    meta: indexNEP1nYLbHMMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/login/index.vue")
  },
  {
    name: "magic-link___en",
    path: "/en/magic-link",
    meta: indexhDBvHobNdEMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/magic-link/index.vue")
  },
  {
    name: "magic-link___pt",
    path: "/magic-link",
    meta: indexhDBvHobNdEMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/magic-link/index.vue")
  },
  {
    name: "not-yet___en",
    path: "/en/not-yet",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/not-yet.vue")
  },
  {
    name: "not-yet___pt",
    path: "/not-yet",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/not-yet.vue")
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password",
    meta: indexOmREa6cD7IMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___pt",
    path: "/reset-password",
    meta: indexOmREa6cD7IMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/reset-password/index.vue")
  },
  {
    name: "set-workspace___en",
    path: "/en/set-workspace",
    meta: set_45workspacefYvLggPxaZMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/set-workspace.vue")
  },
  {
    name: "set-workspace___pt",
    path: "/set-workspace",
    meta: set_45workspacefYvLggPxaZMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/set-workspace.vue")
  },
  {
    name: "sign-up___en",
    path: "/en/sign-up",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/sign-up/index.vue")
  },
  {
    name: "sign-up___pt",
    path: "/sign-up",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/sign-up/index.vue")
  },
  {
    name: "store-apps-appName___en",
    path: "/en/store/apps/:appName()",
    meta: _91appName_93oQqYrJDNAZMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/store/apps/[appName].vue")
  },
  {
    name: "store-apps-appName___pt",
    path: "/store/apps/:appName()",
    meta: _91appName_93oQqYrJDNAZMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/store/apps/[appName].vue")
  },
  {
    name: "store___en",
    path: "/en/store",
    meta: indexlzB54WC9BjMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/store/index.vue")
  },
  {
    name: "store___pt",
    path: "/store",
    meta: indexlzB54WC9BjMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/store/index.vue")
  },
  {
    name: "t-tenantSlug-email-validation___en",
    path: "/en/t/:tenantSlug()/email-validation",
    meta: indexNdEdM4qXZ0Meta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue")
  },
  {
    name: "t-tenantSlug-email-validation___pt",
    path: "/t/:tenantSlug()/email-validation",
    meta: indexNdEdM4qXZ0Meta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue")
  },
  {
    name: "t-tenantSlug___en",
    path: "/en/t/:tenantSlug()",
    meta: indexgYFCsPLuPGMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/index.vue")
  },
  {
    name: "t-tenantSlug___pt",
    path: "/t/:tenantSlug()",
    meta: indexgYFCsPLuPGMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/index.vue")
  },
  {
    name: "t-tenantSlug-loading___en",
    path: "/en/t/:tenantSlug()/loading",
    meta: indexnSjjzVMLQHMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/loading/index.vue")
  },
  {
    name: "t-tenantSlug-loading___pt",
    path: "/t/:tenantSlug()/loading",
    meta: indexnSjjzVMLQHMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/loading/index.vue")
  },
  {
    name: "t-tenantSlug-login___en",
    path: "/en/t/:tenantSlug()/login",
    meta: indexJrEyrJYO6QMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/login/index.vue")
  },
  {
    name: "t-tenantSlug-login___pt",
    path: "/t/:tenantSlug()/login",
    meta: indexJrEyrJYO6QMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/login/index.vue")
  },
  {
    name: mOEGutXQEpTMeta?.name,
    path: "/en/t/:tenantSlug()/m",
    meta: mOEGutXQEpTMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/m.vue"),
    children: [
  {
    name: "t-tenantSlug-m-viewId___en",
    path: ":viewId()",
    meta: indexOuFGqixHwhMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-recordId___en",
    path: ":viewId()/r/:recordId()",
    meta: indexcrZ4bzqDsfMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-new___en",
    path: ":viewId()/r/new",
    meta: newRqQun4QS1DMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue")
  },
  {
    name: "t-tenantSlug-m-account-configuration___en",
    path: "account-configuration",
    meta: account_45configurationjGRiJLiAKdMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue")
  },
  {
    name: "t-tenantSlug-m___en",
    path: "",
    meta: indexopkzX2fBbgMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/m/index.vue")
  }
]
  },
  {
    name: mOEGutXQEpTMeta?.name,
    path: "/t/:tenantSlug()/m",
    meta: mOEGutXQEpTMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/m.vue"),
    children: [
  {
    name: "t-tenantSlug-m-viewId___pt",
    path: ":viewId()",
    meta: indexOuFGqixHwhMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-recordId___pt",
    path: ":viewId()/r/:recordId()",
    meta: indexcrZ4bzqDsfMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-new___pt",
    path: ":viewId()/r/new",
    meta: newRqQun4QS1DMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue")
  },
  {
    name: "t-tenantSlug-m-account-configuration___pt",
    path: "account-configuration",
    meta: account_45configurationjGRiJLiAKdMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue")
  },
  {
    name: "t-tenantSlug-m___pt",
    path: "",
    meta: indexopkzX2fBbgMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/m/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-p-viewId___en",
    path: "/en/t/:tenantSlug()/p/:viewId()",
    meta: indexo6P49wVGjIMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId___pt",
    path: "/t/:tenantSlug()/p/:viewId()",
    meta: indexo6P49wVGjIMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId-r-recordId___en",
    path: "/en/t/:tenantSlug()/p/:viewId()/r/:recordId()",
    meta: index4lJl6IUbFoMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId-r-recordId___pt",
    path: "/t/:tenantSlug()/p/:viewId()/r/:recordId()",
    meta: index4lJl6IUbFoMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-p___en",
    path: "/en/t/:tenantSlug()/p",
    meta: indexo1RO6m2pNhMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/p/index.vue")
  },
  {
    name: "t-tenantSlug-p___pt",
    path: "/t/:tenantSlug()/p",
    meta: indexo1RO6m2pNhMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/p/index.vue")
  },
  {
    name: "t-tenantSlug-reset-password___en",
    path: "/en/t/:tenantSlug()/reset-password",
    meta: reset_45passwordu1lz8iXIzcMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue")
  },
  {
    name: "t-tenantSlug-reset-password___pt",
    path: "/t/:tenantSlug()/reset-password",
    meta: reset_45passwordu1lz8iXIzcMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue")
  },
  {
    name: "t-tenantSlug-sign-up___en",
    path: "/en/t/:tenantSlug()/sign-up",
    meta: indexsaMDj0xulNMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue")
  },
  {
    name: "t-tenantSlug-sign-up___pt",
    path: "/t/:tenantSlug()/sign-up",
    meta: indexsaMDj0xulNMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue")
  },
  {
    name: "t-tenantSlug-sso-saml___en",
    path: "/en/t/:tenantSlug()/sso/saml",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue")
  },
  {
    name: "t-tenantSlug-sso-saml___pt",
    path: "/t/:tenantSlug()/sso/saml",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue")
  },
  {
    name: studio45rtjsFxS0Meta?.name,
    path: "/en/t/:tenantSlug()/studio",
    meta: studio45rtjsFxS0Meta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-automations-id-history___en",
    path: "automations/:id()/history",
    meta: historyXxabcTSgQ9Meta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue")
  },
  {
    name: "t-tenantSlug-studio-automations-id___en",
    path: "automations/:id()",
    meta: indexXCDVmVw1PmMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-automations___en",
    path: "automations",
    meta: indexr25NgokuBOMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-import___en",
    path: "data/:id()/import",
    meta: importh47dFdo9KJMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id___en",
    path: "data/:id()",
    meta: indexzAL8ipbeFPMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-schema___en",
    path: "data/:id()/schema",
    meta: schemaQQje88UE7MMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue")
  },
  {
    name: "t-tenantSlug-studio-data___en",
    path: "data",
    meta: indexSptQwDtoFUMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue")
  },
  {
    name: "t-tenantSlug-studio___en",
    path: "",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue")
  },
  {
    name: "t-tenantSlug-studio-pages-id___en",
    path: "pages/:id()",
    meta: _91id_93X1X6irCg03Meta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-pages___en",
    path: "pages",
    meta: indexxKLByu1kzVMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue")
  },
  {
    name: settingsZNnOKo66LqMeta?.name,
    path: "settings",
    meta: settingsZNnOKo66LqMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-app-builder___en",
    path: "app-builder",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-authentication___en",
    path: "authentication",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-global-parameters-manager___en",
    path: "global-parameters-manager",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings___en",
    path: "",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue")
  },
  {
    name: integrationsbgpYujvmIYMeta?.name,
    path: "integrations",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-integrations-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-integrations___en",
    path: "",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-internal___en",
    path: "internal",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-system-entities___en",
    path: "system-entities",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-tokens___en",
    path: "tokens",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue")
  },
  {
    name: user_45groups7gvhY0WIHFMeta?.name,
    path: "user-groups",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-user-groups-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups___en",
    path: "",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups-new___en",
    path: "new",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-users___en",
    path: "users",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue")
  }
]
  }
]
  },
  {
    name: studio45rtjsFxS0Meta?.name,
    path: "/t/:tenantSlug()/studio",
    meta: studio45rtjsFxS0Meta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-automations-id-history___pt",
    path: "automations/:id()/history",
    meta: historyXxabcTSgQ9Meta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue")
  },
  {
    name: "t-tenantSlug-studio-automations-id___pt",
    path: "automations/:id()",
    meta: indexXCDVmVw1PmMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-automations___pt",
    path: "automations",
    meta: indexr25NgokuBOMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-import___pt",
    path: "data/:id()/import",
    meta: importh47dFdo9KJMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id___pt",
    path: "data/:id()",
    meta: indexzAL8ipbeFPMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-schema___pt",
    path: "data/:id()/schema",
    meta: schemaQQje88UE7MMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue")
  },
  {
    name: "t-tenantSlug-studio-data___pt",
    path: "data",
    meta: indexSptQwDtoFUMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue")
  },
  {
    name: "t-tenantSlug-studio___pt",
    path: "",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue")
  },
  {
    name: "t-tenantSlug-studio-pages-id___pt",
    path: "pages/:id()",
    meta: _91id_93X1X6irCg03Meta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-pages___pt",
    path: "pages",
    meta: indexxKLByu1kzVMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue")
  },
  {
    name: settingsZNnOKo66LqMeta?.name,
    path: "settings",
    meta: settingsZNnOKo66LqMeta || {},
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-app-builder___pt",
    path: "app-builder",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-authentication___pt",
    path: "authentication",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-global-parameters-manager___pt",
    path: "global-parameters-manager",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings___pt",
    path: "",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue")
  },
  {
    name: integrationsbgpYujvmIYMeta?.name,
    path: "integrations",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-integrations-id___pt",
    path: ":id()",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-integrations___pt",
    path: "",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-internal___pt",
    path: "internal",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-system-entities___pt",
    path: "system-entities",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-tokens___pt",
    path: "tokens",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue")
  },
  {
    name: user_45groups7gvhY0WIHFMeta?.name,
    path: "user-groups",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-user-groups-id___pt",
    path: ":id()",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups___pt",
    path: "",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups-new___pt",
    path: "new",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-users___pt",
    path: "users",
    component: () => import("/codebuild/output/src937738402/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue")
  }
]
  }
]
  }
]