import marked from '~/assets/javascript/modules/marked';
import TurndownService from 'turndown';
import { getImgMustacheContent } from '~/assets/javascript/utils/quill/image-resize';
import sanitizeHtml from 'sanitize-html';

export const VIDEO_ARGS_SEPARATOR = 'ZAZOS__ZAZOS';
// Save the original escape method to keep a reference to the default behavior
const originalEscape = TurndownService.prototype.escape;

// Override the escape method
TurndownService.prototype.escape = (string) => {
  let result = originalEscape.call(this, string);
  const mustacheRegex = /{{.*?}}/g;
  const matches = string.match(mustacheRegex);

  if (matches) {
    matches.forEach((match) => {
      const escapedMatch = match.replace(/_/g, '\\_');
      result = result.replace(escapedMatch, match);
    });
  }

  return result;
};

const cleanAttribute = attribute => (attribute ? attribute.replace(/(\n+\s*)+/g, '\n') : '');
const cleanText = text => text.replaceAll('”', '').replaceAll('“', '');

export const defaultOptions = {
  allowedTags: ['a', 'strong', 'em', 'ul', 'li', 'b', 'i', 'u', 's', 'p', 'br'],
  allowedAttributes: {
    a: ['href', 'rel', 'target', 'alt'],
  },
  transformTags: {
    a(tagName, attribs) {
      return {
        tagName,
        attribs: {
          rel: 'noreferrer noopener',
          href: attribs.href,
          target: '_blank',
        },
      };
    },
  },
};

const $sanitize = (dirty, opts = null) => sanitizeHtml(dirty, opts || defaultOptions);

export const Plugin = {
  install: (app) => {
    app.config.globalProperties.$sanitize = $sanitize;
  },
};

export const $htmlToText = html => $sanitize(html || '', { allowedTags: [] }).trim();

export const $markdownToText = (markdown) => {
  const html = marked(markdown || '').replace('\n', ' ');
  return $htmlToText(html).replaceAll('&gt;', '>').replaceAll('&lt;', '<').trim();
};

function validateVideoURL(url) {
  const youtubeFormat = /^https:\/\/www.(youtube)\.com\/embed\/([a-zA-Z0-9_-]+)/;
  const vimeoFormat = /^https:\/\/player.(vimeo)\.com\/video\/(\d+)/;
  const loomFormat = /^https:\/\/www.(loom)\.com\/embed\/([a-zA-Z0-9_-]+)/;
  const pandaVideo = /^https:\/\/player-vz-([a-zA-Z0-9_-]+).tv.pandavideo.com.br\/embed\/\?v=([a-zA-Z0-9_-]+)/;

  const match = url.match(youtubeFormat)
    || url.match(vimeoFormat)
    || url.match(loomFormat);

  if (url.match(pandaVideo)) {
    return ['pandaVideo', url.match(pandaVideo)[1], url.match(pandaVideo)[2]];
  }

  if (match) {
    return [match[1], match[2]];
  }

  return [];
}

const removeHtmlTags = text => $sanitize(text || '', { allowedTags: [], disallowedTagsMode: 'escape' }).trim();

export const $htmlToMarkdown = (html) => {
  const turndownService = new TurndownService({
    headingStyle: 'atx',
    codeBlockStyle: 'fenced',
    blankReplacement: (content, node) => {
      // Turndown doesn't call the list item replacement rule for blank list items
      if (node.nodeName === 'LI') {
        return turndownService.options.rules.listItem.replacement(content, node, turndownService.options);
      }

      // Default blank replacement
      return node.isBlock ? '\n\n' : '';
    },
  });
  turndownService.remove('script');

  turndownService.addRule('strikethrough', {
    filter: ['del', 's', 'strike'],
    replacement: content => `~~${content}~~`,
  });

  turndownService.addRule('imageResize', {
    filter: ['img'],
    replacement: (_, node) => {
      const alt = cleanAttribute(node.getAttribute('alt'));
      const src = node.getAttribute('src') || '';
      const title = cleanAttribute(node.getAttribute('title'));
      const titlePart = title ? ` "${title}"` : '';

      const imgMarkdown = src ? cleanText(`![${alt}](${src}${titlePart})`) : '';

      const mustache = cleanText(getImgMustacheContent(node));

      if (mustache) {
        return `${imgMarkdown}{{{${mustache}}}}`;
      }

      return imgMarkdown;
    },
  });

  turndownService.addRule('video', {
    filter: ['iframe'],
    replacement: (content, node) => {
      const src = node.getAttribute('src') || '';

      if (src && validateVideoURL(src).length) {
        const [host, ...rest] = validateVideoURL(src);
        content = `!![${host}](${rest.join(VIDEO_ARGS_SEPARATOR)})`;

        if (node.nextSibling) {
          content += '\n\n';
        }

        return content;
      }

      return '';
    },
  });

  turndownService.addRule('nestedList', {
    filter: ['ul', 'ol'],
    replacement(content, node) {
      const items = node.querySelectorAll('li');

      // Extract the indentation levels
      const indentLevels = [...items].map((item) => {
        const className = item.getAttribute('class') || '';
        const match = className.match(/ql-indent-(\d)/);
        return match ? parseInt(match[1], 10) : 0;
      });

      // Split the content string by lines and adjust the indentation
      const lines = content.split('\n');
      const indentedLines = lines.map((line, index) => {
        const indentation = '    '.repeat(indentLevels[index]); // 4 spaces per indentation level
        return indentation + line.trim();
      });

      content = indentedLines.join('\n');

      if (node.nextSibling && !/\n$/.test(content)) {
        content += '\n\n';
      }

      return content;
    },
  });

  // This is a copy of default turndown list item rule with the following changes:
  // - Remove the indentation after newlines
  // - Remove unnecessary spaces after the list marker
  // Source: https://github.com/mixmark-io/turndown/blob/4afc32881bd371a34284d262613c1e1363809963/src/commonmark-rules.js#L61-L80
  turndownService.addRule('listItem', {
    filter: 'li',
    replacement(content, node, options) {
      content = content
        .replace(/^\n+/, '') // remove leading newlines
        .replace(/\n+$/, '\n'); // replace trailing newlines with just a single one

      let prefix = `${options.bulletListMarker} `;
      const parent = node.parentNode;

      if (parent.nodeName === 'OL') {
        const start = parent.getAttribute('start');
        const index = Array.prototype.indexOf.call(parent.children, node);
        prefix = `${start ? Number(start) + index : index + 1}. `;
      }

      return (
        prefix + content + (node.nextSibling && !/\n$/.test(content) ? '\n' : '')
      );
    },
  });

  // Quill adds a <pre> tag without a <code> tag when the user types ```
  // Turndown doesn't convert <pre> tags without <code> tags into code blocks
  // This rule adds the <code> tag to the <pre> tag to fix this
  turndownService.addRule('fixQuillCodeBlockMissingCode', {
    filter(node, options) {
      return (
        options.codeBlockStyle === 'fenced'
        && node.nodeName === 'PRE'
        && node.firstChild && node.firstChild.nodeName !== 'CODE'
      );
    },
    replacement: (content, node, options) => {
      const pre = node.cloneNode(true);
      const code = document.createElement('code');

      while (pre.firstChild) {
        code.appendChild(pre.firstChild);
      }

      pre.appendChild(code);

      return turndownService.options.rules.fencedCodeBlock.replacement(content, pre, options);
    },
  });

  const turndownResult = turndownService.turndown(html);
  return removeHtmlTags(turndownResult);
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Plugin);

  return {
    provide: {
      htmlToText: html => $htmlToText(html),
      markdownToText: html => $markdownToText(html),
      htmlToMarkdown: html => $htmlToMarkdown(html),
    },
  };
});
