export const state = () => ({
  inStudio: false,
});

export const mutations = {
  setInStudio(state) {
    state.inStudio = true;
  },
  setOutsideStudio(state) {
    state.inStudio = false;
  },
};

export const namespaced = true;
