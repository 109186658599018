<i18n lang="yaml">
pt:
  notFound: "{code}! Página não encontrada"
  unauthorized: "{code}! Você não tem permissão para acessar esta pagina"
  error: "Ocorreu um erro"
  logout: "Sair"
en:
  notFound: "{code}! Page not found"
  unauthorized: "{code}! You do not have permission to access this page"
  error: "An error occurred"
  logout: "Log out"
</i18n>
<template>
  <v-row class="pt-10">
    <v-col cols="12">
      <h1 class="text-center">
        {{ errorText }}
      </h1>

      <div class="text-center">
        <img
          src="/public/img-working.svg"
          alt="error"
          class="my-10"
          style="max-height: 300px; width: 100%"
        >
      </div>

      <div class="text-center mt-6">
        <v-btn
          v-if="error.statusCode === 401"
          color="primary"
          variant="text"
          @click="$auth.logoutUser()"
        >
          <v-icon start>
            fa-right-from-bracket fa-regular
          </v-icon>
          {{ t('logout') }}
        </v-btn>
        <v-btn
          :to="tenantHomePage"
          class="zazos-bg reset-elevation"
          rounded
        >
          Home page
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import type { NuxtError } from '#app';

const AVAILABLE_STATUS_CODES = {
  404: 'notFound',
  401: 'unauthorized',
};
export default defineComponent({
  name: 'ErrorLayout',
  props: {
    error: {
      type: Object as () => NuxtError,
      default: () => ({}),
    },
  },
  setup(props) {
    const { error } = props;
    const { t } = useI18n();
    const $rollbar = useRollbar();

    $rollbar.error(`Error Page: ${error.statusCode}`, error);

    const errorText = AVAILABLE_STATUS_CODES[error.statusCode]
      ? t(AVAILABLE_STATUS_CODES[error.statusCode], { code: error.statusCode })
      : t('error');

    buildHead({
      title: errorText,
    });

    return { t, errorText };
  },
  computed: {
    tenantHomePage() {
      return this.localeRoute({
        name: 't-tenantSlug-m',
        params: { tenantSlug: this.$auth.user?.workspace?.slug },
      });
    },
  },
});
</script>
