
import { pick } from 'lodash';
import { HELPER_TEXT_ATTRIBUTES, LABEL_ATTRIBUTES } from '~/assets/javascript/constants';
import InfoComponentFromOptionBuilder from '.';

export default class FieldInfoComponentFromOptionBuilder {
  static build(infoComponentOption) {
    return new FieldInfoComponentFromOptionBuilder(infoComponentOption).build();
  }

  constructor(infoComponentOption) {
    this.fieldInfoComponentOption = infoComponentOption;
  }

  build() {
    return {
      ...pick(
        this.fieldInfoComponentOption,
        [
          'columns',
          'exhibition_mode',
          'field_id',
          'field_type',
          'format_option',
          'id',
          'permit_links_editing',
          'permit_record_delete',
          'permit_record_insert',
          'permit_record_update',
          'records_template',
          'required',
          'sort_by_label',
          'sort_by',
          'type',
          'uneditable',
          ...HELPER_TEXT_ATTRIBUTES,
          ...LABEL_ATTRIBUTES,
        ],
      ),
      items: this.fieldInfoComponentOption.items?.map(
        infoComponentOption => InfoComponentFromOptionBuilder.build(infoComponentOption),
      ) || [],
    };
  }
}

