import { builder } from './builder';
import { dummyData } from './dummyData';
import { permissions } from './permissions';
import { publicRoutes } from './publicRoutes';
import { records } from './records';
import { sheets } from './sheets';
import { views } from './views';
import { workflows } from './workflows';
import { workflowSteps } from './workflowSteps';
import { workflowTriggers } from './workflowTriggers';

export default ($http: HttpApi) => ({
  builder: builder($http),
  dummyData: dummyData($http),
  permissions: permissions($http),
  publicRoutes: publicRoutes($http),
  records: records($http),
  sheets: sheets($http),
  views: views($http),
  workflowSteps: workflowSteps($http),
  workflowTriggers: workflowTriggers($http),
  workflows: workflows($http),
});
