export default defineNuxtPlugin({
  name: 'i18n-switch-locale',
  setup({ hook, $rollbar }) {
    // onLanguageSwitched called right after a new locale has been set
    hook('i18n:localeSwitched', ({ oldLocale, newLocale }) => {
      try {
        window.analytics.track('switchLocale', {
          oldLocale,
          newLocale,
        });
      } catch (error) {
        $rollbar.error('Analytics', error);
      }
    });
  },
});
