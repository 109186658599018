import { isEmpty } from 'lodash';
import { FIELD_TYPES } from '~/assets/javascript/constants';

export const calculateFieldNameWithGroup = field => (field.options?.group_name ? `${field.options.group_name} / ${field.name}` : field.name);

/*
 * Generate an object structure for the fields that are compatible with the `items` schema from `deck-select` component.
 * @param {Array} fields - Array of fields to be grouped.
 * @param {String} ungroupedLabel - Label for the group that will contain the fields that are ungrouped in their table.
 * @returns {Array<DeckSelectItems>} - Array of objects that represent the groups of fields.
 */
export const generateGroupedFieldsStructure = (fields, ungroupedLabel) => {
  const groups = {};

  fields.forEach((field) => {
    const groupName = field.options?.group_name || ungroupedLabel;
    const item = {
      text: field.name,
      value: field.id,
      icon: FIELD_TYPES[field.type].icon,
    };

    if (!groups[groupName]) {
      groups[groupName] = {
        label: groupName,
        items: [],
        ...(groupName === ungroupedLabel && { forceIndex: 0, omitPrefix: true }), // Force ungrouped fields to be the first group
      };
    }

    groups[groupName].items.push(item);
  });

  return Object.values(groups) || [];
};

export const isFieldEditable = (field) => {
  if (isEmpty(field)) return false;
  if (['Lookup', 'Formula'].includes(field.type)) return false;
  if (field.calculated) return false;

  return true;
};
