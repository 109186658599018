<template>
  <v-tooltip
    v-model="tooltipValue"
    :activator="activatorElement"
    :open-on-hover="trigger === 'hover'"
    :open-on-focus="trigger === 'hover'"
    :open-on-click="trigger === 'click'"
    :offset-overflow="!disableOffsetOverflow"
    :open-delay="(trigger === 'hover' && instant) ? 0 : 300"
    :location="position"
    :offset="offset"
    :max-width="maxWidth"
    :min-width="minWidth"
    :disabled="disabled"
    :transition="shakeOnOpen ? 'shake-transition' : 'fade-transition'"
    :content-class="tooltipClasses"
    :attach="attach || undefined"
    :target="target || undefined"
    :eager="false"
    z-index="203"
    class="deck-tooltip"
  >
    <template
      v-if="!activator"
      #activator="{ props }"
    >
      <!-- @slot Default slot where the first child is rendered as the trigger element -->
      <component
        :is="wrapperTag"
        ref="activatorElement"
        :class="wrapperClass"
      >
        <slot
          v-bind="{ props }"
          name="activator"
        />
      </component>
    </template>

    <div
      class="deck-tooltip__content"
      :class="contentClass"
    >
      <!-- @slot Optional slot that can be used to customize the tooltip with markup. Will override `text`-->
      <div
        v-if="kind !== 'plain' && kindIconMapping[kind]"
        class="d-flex align-center g-3"
      >
        <deck-icon
          :name="kindIconMapping[kind].name"
          :color="kindIconMapping[kind].color"
          kind="solid"
        />
        <slot name="content">
          <p class="mb-0">
            {{ text }}
          </p>
        </slot>
      </div>
      <slot
        v-else
        name="content"
      >
        <p class="mb-0">
          {{ text }}
        </p>
      </slot>
    </div>
  </v-tooltip>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';

/**
 * A wrapper around Vuetify's `v-tooltip` component with custom styles, common props and sensible defaults.
 * It will automatically detect the activator element as the first element of the default slot and apply the tooltip to it.
 */
export default defineComponent({
  name: 'DeckTooltip',
  props: {
    /**
     * A text content to display inside the tooltip.
     * @type {string}
     */
    text: {
      type: String,
      default: '',
    },

    /**
     * The activator element. If not provided, will automatically detect the first element of the default slot.
     * @type {string | object | HTMLElement}
     * @default undefined
     */
    activator: {
      type: [String, Object, Element],
      default: undefined,
    },

    /**
     * Whether instantly show the tooltip on mount.
     * @type {boolean}
     * @default false
     */
    openOnMount: {
      type: Boolean,
      default: false,
    },

    /**
     * The kind of tooltip to display. When not `plain` will display an icon next to the text.
     * Attention when using `info` as it is a well established pattern for the `deck-hinter`!
     * It will be overriden if using the `content` slot.
     * @type {'plain' | 'error' | 'warning' | 'success' | 'info' | string}
     * @default 'plain'
     */
    kind: {
      type: String,
      default: 'plain',
    },

    /**
     * Determine how the tooltip is triggered.
     * @type {'hover' | 'click' | string}
     * @default 'hover'
     */
    trigger: {
      type: String,
      default: 'hover',
      validator: value => ['click', 'hover'].includes(value),
    },

    /**
     * Whether to show the tooltip instantly on hover, or with a pre-defined delay.
     * @type {boolean}
     * @default false
     */
    instant: {
      type: Boolean,
      default: false,
    },

    /**
     * Determines the position of the tooltip relative to the activator element.
     * @type {'top' | 'bottom' | 'left' | 'right' | string}
     * @default 'top'
     */
    position: {
      type: String,
      default: 'top',
      validator: value => ['top', 'bottom', 'left', 'right'].includes(value),
    },

    /**
     * Determines the amount of pixels to shift the tooltip from its defined position. Negative values are allowed. Strigs will strip any non-numeric characters and effectively shift in pixels only, eg: "100%" will be evaluated as 100 pixels.
     * @type {number | string}
     * @default 0
     */
    offset: {
      type: [Number, String],
      default: 8,
    },

    /**
     * Determines the maximum width of the tooltip.
     * @type {number | string}
     * @default '320px'
     */
    maxWidth: {
      type: [Number, String],
      default: '320px',
    },

    /**
     * Determines the minimum width of the tooltip.
     * @type {number | string}
     * @default undefined
     */
    minWidth: {
      type: [Number, String],
      default: undefined,
    },

    /**
     * CSS class applied to the tooltip content.
     * @type {string}
     * @default ''
     */
    contentClass: {
      type: String,
      default: '',
    },

    /**
     * Whether the tooltip is disabled to prevent triggering it.
     * @type {boolean}
     * @default false
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Delay in milliseconds for the tooltip to automatically close when trigger is set to `click`. Can be set to 'short' or 'long', or a custom number of milliseconds.
     * @type {'short' | 'long' | string | number}
     * @default undefined
     */
    closeDelayAfterClick: {
      type: [String, Number],
      default: undefined,
      validator: value => ['short', 'long'].includes(value) || Number.isInteger(value),
    },

    /**
     * Whether to shake the tooltip when it opens. Useful to draw attention to warnings or errors, especially when those are triggered by click.
     * @type {boolean}
     * @default false
     */
    shakeOnOpen: {
      type: Boolean,
      default: false,
    },

    /**
     * Timeout in milliseconds to wait for the activator element to be available before giving up. Useful when using an async component as the activator.
     * @type {number}
     * @default 1000
     */
    activatorDetectionTimeout: {
      type: Number,
      default: 1000,
    },

    /**
     * Whether the tooltip is interactive, meaning it will allow user to interact with inner buttons and links.
     * @type {boolean}
     * @default false
     */
    interactive: {
      type: Boolean,
      default: false,
    },

    /**
     * Attach the tooltip inside an element by its given query selector or DOM node. It will replace the default behavior of attaching to the body.
     * @type {string}
     * @default undefined
     */
    attach: {
      type: [String, HTMLElement],
      default: undefined,
    },

    /**
     * Reposition the tooltip to a specific element by its given query selector or DOM node. It will replace the default behavior of visually anchoring the tooltip to the activator element while still being attached to the body.
     * @type {string | HTMLElement}
     * @default undefined
     */
    target: {
      type: [String, HTMLElement],
      default: undefined,
    },

    /**
     * Avoids flipping the tooltip to the opposite side when repositioned due to overflow of the activator parent.
     * @type {boolean}
     * @default false
     */
    disableOffsetOverflow: {
      type: Boolean,
      default: false,
    },

    /**
     * The tag to wrap the activator element. Useful to change the default behavior of the activator element.
     * @type {string}
     * @default 'span'
     */
    wrapperTag: {
      type: String,
      default: 'span',
    },

    // wrapperClass
    /**
     * The class to apply to the wrapper element.
     * @type {string}
     * @default ''
     */
    wrapperClass: {
      type: String,
      default: '',
    },
  },
  emits: ['tooltipClosed', 'tooltipOpened'],
  setup(props, { emit }) {
    const tooltipValue = ref(false);
    const activatorElement = ref(null);

    const closeDelayMapping = {
      short: 2500,
      long: 4000,
    };

    const kindIconMapping = {
      error: {
        name: 'circle-exclamation',
        color: 'error',
      },
      warning: {
        name: 'triangle-exclamation',
        color: 'warning',
      },
      success: {
        name: 'circle-check',
        color: 'success',
      },
      info: {
        name: 'circle-info',
        // TODO: Remove hard-coded color when we can reliably reference vuetify's color namings or when we have our color tokens
        color: '#64b5f6',
      },
    };

    const tooltipClasses = computed(() => {
      const classes = ['deck-tooltip__content-wrapper'];

      if (props.interactive) classes.push('deck-tooltip__content-wrapper--interactive');

      return classes.join(' ');
    });

    const autoCloseDelay = computed(() => closeDelayMapping[props.closeDelayAfterClick] || props.closeDelayAfterClick);

    let autoCloseDelayTimeout = null;

    let handleClickOnActivator = null;

    // onClickOutside will only automatically cleanup side-effects if the target
    // is the ref itself defined on setup(). Since we're using a ref to the
    // first child of the activator slot from onMounted, we need to manually
    // cleanup using a stop handler.
    let stopOnClickOutsideHandler = null;

    const createOnClickOutsideHandler = () => {
      stopOnClickOutsideHandler = onClickOutside(activatorElement, () => {
        tooltipValue.value = false;
      });
    };

    onMounted(() => {
      // eslint-disable-next-line prefer-const
      let timeoutId;

      // Safely waiting for ref to be defined when using async components
      // https://jefrydco.id/en/blog/safe-access-vue-refs-undefined#safe-way
      const interval = setInterval(() => {
        const element = props.activator || activatorElement.value;

        if (element) {
          activatorElement.value = element;
          tooltipValue.value = props.openOnMount;

          handleClickOnActivator = () => {
            tooltipValue.value = false;
          };

          activatorElement.value.addEventListener('click', handleClickOnActivator);

          clearInterval(interval);
          clearTimeout(timeoutId);
        }
      }, 5);

      timeoutId = setTimeout(() => {
        clearInterval(interval);
      }, props.activatorDetectionTimeout);
    });

    onBeforeUnmount(() => {
      if (autoCloseDelayTimeout !== null) {
        clearTimeout(autoCloseDelayTimeout);
      }

      if (stopOnClickOutsideHandler !== null) {
        stopOnClickOutsideHandler();
      }

      if (activatorElement.value) {
        if (handleClickOnActivator) activatorElement.value.removeEventListener('click', handleClickOnActivator);

        activatorElement.value = null;
      }
    });

    watch(tooltipValue, (newValue) => {
      if (props.trigger === 'click' && autoCloseDelayTimeout !== null) {
        clearTimeout(autoCloseDelayTimeout);
        autoCloseDelayTimeout = null;
      }

      if (newValue === false) {
        /**
         * Emited when the tooltip is closed.
         * @event tooltipClosed
         */
        emit('tooltipClosed');

        if (stopOnClickOutsideHandler !== null) {
          stopOnClickOutsideHandler();
        }
      } else if (newValue === true) {
        /**
         * Emited when the tooltip is opened.
         * @event tooltipOpened
         */
        emit('tooltipOpened');

        if (props.trigger === 'click') {
          createOnClickOutsideHandler();

          if (autoCloseDelay.value) {
            autoCloseDelayTimeout = setTimeout(() => {
              tooltipValue.value = false;
            }, autoCloseDelay.value);
          }
        }
      }
    });

    return {
      tooltipValue,
      activatorElement,
      tooltipClasses,
      kindIconMapping,
    };
  },
});
</script>

<style lang="scss">
.deck-tooltip__content-wrapper {
  padding-inline: 12px !important;
  padding-block: 12px !important;
  line-height: 1.5 !important;
  background-color: #212121 !important;
  border-radius: 12px !important;
  z-index: 204 !important;

  &.v-tooltip__content.v-tooltip__content { // Override v-tooltip while allowing for opacity easing from fade-transition
    opacity: 1;
  }

  .deck-tooltip__content {
    color: var(--z-color-text-white) !important;
  }

  &.fade-transition-enter-active,
  &.fade-transition-leave-active {
    transition-duration: 150ms !important;
  }

  &.shake-transition-enter-active {
    transform: translateX(0px) !important;
    opacity: 1 !important;
    transition:
      transform 100ms 75ms cubic-bezier(0.5, 50, 0.5, -50),
      opacity 100ms ease-in-out !important
    ;
  }

  &.shake-transition-leave-active {
    transition:
      transform 0ms 100ms linear,
      opacity 100ms ease-in-out !important
    ;
  }

  &.shake-transition-enter,
  &.shake-transition-leave-to {
    transform: translateX(-1px) !important;
    opacity: 0 !important;
  }
}

.deck-tooltip__content-wrapper--interactive {
  pointer-events: all !important;
}
</style>
