
import { v4 as uuidv4 } from 'uuid';
import { dataMixin } from '~/mixins/dataMixin';
import InfoComponentOptionBuilder from '.';

export default class FieldInfoComponentOptionBuilder {
  static build(infoComponent, mainContent) {
    return new FieldInfoComponentOptionBuilder(infoComponent, mainContent).build();
  }

  constructor(fieldInfoComponent, mainContent) {
    this.fieldInfoComponent = fieldInfoComponent;
    this.mainContent = mainContent;

    this.canEditLinksValue = this.getCanEditLinksValue();
    this.canCreateRecordsValue = this.fieldInfoComponent.permit_record_insert;
    this.isReadOnlySubviewValue = this.getReadOnlySubviewValue();
    this.isReadOnlyFieldValue = this.getReadOnlyValue();

    this.isRequiredValue = this.fieldInfoComponent.required;
  }

  build() {
    const fieldInfoComponentId = this.fieldInfoComponent.id || uuidv4();

    const items = Array.isArray(this.fieldInfoComponent.items)
      ? this.fieldInfoComponent.items.map(item => InfoComponentOptionBuilder.build(item, this.mainContent))
      : null;

    const fieldInfoComponentOption = {
      ...this.fieldInfoComponent,
      id: fieldInfoComponentId,
      title: this.fieldInfoComponent.label,
      titleTypography: 'text-body-2',
      icon: dataMixin.methods.typeIcons(this.fieldInfoComponent.field_type),
      items,
      toggles: this.buildInfoComponentToggles(),
    };

    return fieldInfoComponentOption;
  }

  buildInfoComponentToggles() {
    const isLink = this.fieldInfoComponent.field_type === 'Link';
    const isMinicardLink = isLink && this.fieldInfoComponent.exhibition_mode === 'minicard';

    const validations = {};
    const permissions = {};

    if (typeof this.isRequiredValue !== 'undefined' && this.isRequiredValue !== null) {
      validations.isRequired = {
        value: this.isRequiredValue,
        disabled: this.isRequiredValue ? false : this.fieldInfoComponent.uneditable,
      };
    }

    if (typeof this.isReadOnlyFieldValue !== 'undefined' && this.isReadOnlyFieldValue !== null) {
      permissions.isReadOnlyField = {
        value: this.isReadOnlyFieldValue,
        disabled: this.fieldInfoComponent.uneditable ? false : this.isRequiredValue,
      };
    }

    if (isLink) {
      permissions.canEditLinks = {
        value: this.canEditLinksValue,
        disabled: this.isRequiredValue && !this.canCreateRecordsValue,
      };
    }

    if (isMinicardLink) {
      permissions.canCreateRecords = {
        value: this.canCreateRecordsValue,
        disabled: this.isRequiredValue && !this.canEditLinksValue,
      };

      permissions.isReadOnlySubview = {
        value: this.isReadOnlySubviewValue,
        disabled: this.isRequiredValue,
      };
    }

    return {
      permissions,
      validations,
    };
  }

  getCanEditLinksValue() {
    if (this.fieldInfoComponent.exhibition_mode === 'minicard') {
      return this.fieldInfoComponent.permit_links_editing;
    }

    return this.getReadOnlyValue();
  }

  getCanCreateRecordsValue() {
    return this.fieldInfoComponent.permit_record_insert;
  }

  getReadOnlyValue(fieldInfoComponent = this.fieldInfoComponent) {
    // If the field is a Link, we have two cases to consider:
    // 1. If 'exhibition_mode' is 'label', we treat it as if it's a 'permit_record_update'.
    //    This is because, semantically for the user, a Link that cannot be edited (i.e., 'uneditable' is true) is the same as a field that cannot be updated.
    // 2. If 'permit_record_update' is undefined or false, the function returns true, indicating that the field is read-only.
    if (fieldInfoComponent.field_type === 'Link') {
      if (fieldInfoComponent.exhibition_mode === 'minicard') {
        return !this.canEditLinksValue && !this.canCreateRecordsValue;
      }

      return !fieldInfoComponent.uneditable;
    }

    // For other field types, we simply return the 'uneditable' property which is equivalent to the user-facing 'read-only'.
    return fieldInfoComponent.uneditable;
  }

  getReadOnlySubviewValue(fieldInfoComponent = this.fieldInfoComponent) {
    return !(fieldInfoComponent?.permit_record_update ?? true);
  }
}

