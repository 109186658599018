<i18n lang="yaml">
  pt:
    clearAll: "Desmarcar todas"
    selectAll: "Marcar todas"
    checkAllOptions: "Marcar todas as opções"
  en:
    clearAll: "Uncheck all"
    selectAll: "Check all"
    checkAllOptions: "Check all options"
</i18n>

<!-- TODO: group-label and select-all components are pretty similar, we should abstract them in a smarter way -->
<template>
  <div class="deck-select-list-item-select-all">
    <deck-tooltip
      :text="itemsSelection.isNone ? t('selectAll') : t('clearAll')"
      :disabled="disabled"
      position="left"
    >
      <template #activator="{ props }">
        <div class="d-flex">
          <deck-checkbox
            :model-value="itemsSelection.isAll"
            :indeterminate="itemsSelection.isSome"
            :disabled="disabled"
            class="deck-select-list-item-select-all__checkbox mr-1"
            v-bind="props"
            @click.prevent.stop.capture="$emit('selectAll')"
          />
          <deck-label
            :text="t('checkAllOptions')"
            v-bind="props"
          />
        </div>
      </template>
    </deck-tooltip>
  </div>
</template>
<script>
import DeckTooltip from '~/deck/tooltip';
import DeckCheckbox from '~/deck/checkbox';
import DeckLabel from '~/deck/label';

export default {
  name: 'DeckSelectListItemSelectAll',
  components: {
    DeckTooltip,
    DeckCheckbox,
    DeckLabel,
  },
  props: {
    itemsSelection: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectAll'],
  setup() {
    return {
      t: useI18n().t,
    };
  },
};
</script>
<style lang="scss">
.deck-select-list-item-select-all {
  display: flex;
  align-items: center;
  gap: var(--z-s2);
}

.deck-select-list-item-select-all__checkbox {
  opacity: 0.8;
}
</style>
