import {
  FIELD_TYPES,
  TRIGGER_TYPES,
  STEP_TYPES,
  VIEW_TYPES,
  PAGE_STATE_TYPES,
  AVAILABLE_PAGE_STATE_TYPES,
  AVAILABLE_TRIGGER_TYPES,
} from '~/assets/javascript/constants/types';
import sortBy from 'lodash/sortBy';
import { startCase } from 'lodash';

export const undefinedTranslate = {
  pt: 'Indefinido',
  en: 'Undefined',
};

const STEPS_KEYS = Object.keys(STEP_TYPES);

export const UNDEFINED_ICON = 'fa-question fa-regular';

export const dataMixin = {
  methods: {
    typeIcons(type, { fallback = UNDEFINED_ICON } = {}) {
      return FIELD_TYPES[type]?.icon || fallback;
    },
    typeNames(type, lang) {
      return FIELD_TYPES[type]?.locales[lang] || undefinedTranslate[lang];
    },

    // Workflow Triggers
    triggerTypeIcon(type) {
      const triggerData = TRIGGER_TYPES[type];

      return {
        name: triggerData?.icon || 'play',
        kind: triggerData?.iconKind || 'regular',
      };
    },
    // Workflow schema items type icon
    schemaParamTypeIcon(rawType, { fallback = UNDEFINED_ICON } = {}) {
      const type = startCase(rawType).replaceAll(/ /g, '');

      const icon = this.typeIcons(type);

      if (icon !== UNDEFINED_ICON) return icon;

      const iconTypesMapping = {
        Boolean: 'fa-toggle-on fa-regular',
        Email: 'fa-envelope fa-regular',
        FieldReference: 'fa-table-columns fa-regular',
        File: this.typeIcons('Attachment'),
        ProviderIntegration: 'fa-plug fa-regular',
        RecordReference: 'fa-table-rows fa-regular',
        SheetReference: 'fa-database fa-regular',
        StaticSelect: 'fa-rectangle-list fa-regular',
        Text: this.typeIcons('String'),
        Url: 'fa-link fa-regular',
        UserReference: 'fa-user fa-regular',
        ViewReference: 'fa-browser fa-regular',
      };

      return iconTypesMapping[type] || fallback;
    },
    triggerTypeName(type, lang) {
      return TRIGGER_TYPES[type]?.locales[lang] || undefinedTranslate[lang];
    },
    triggerItems(lang) {
      return AVAILABLE_TRIGGER_TYPES.map(t => ({
        value: t,
        icon: this.triggerTypeIcon(t).name,
        iconKind: this.triggerTypeIcon(t).kind,
        text: this.triggerTypeName(t, lang),
      }));
    },

    // Workflow Steps
    stepTypeIcon(type, iconOverride) {
      return iconOverride || STEP_TYPES[type]?.icon || 'play';
    },
    stepTypeIconKind(type) {
      return STEP_TYPES[type]?.iconKind || 'regular';
    },
    stepTypeName(type, nameOverride, lang) {
      if (nameOverride && nameOverride[lang]) return nameOverride[lang];

      return STEP_TYPES[type]?.locales[lang] || undefinedTranslate[lang];
    },
    stepTypeGroup(type, groupOverride) {
      return groupOverride || STEP_TYPES[type]?.group || null;
    },
    stepItems(availableStepTypes, lang) {
      const steps = availableStepTypes?.map((step) => {
        const config = step.display_config;
        return {
          value: step.key,
          icon: this.stepTypeIcon(step.key, config?.icon),
          iconKind: this.stepTypeIconKind(step.key),
          text: this.stepTypeName(step.key, config?.name, lang),
          group: this.stepTypeGroup(step.key, config?.group),
        };
      });

      return sortBy(steps, [(item) => {
        const index = STEPS_KEYS.indexOf(item.value);

        return index === -1 ? Number.MAX_VALUE : index;
      }]);
    },

    // View Types
    viewTypeIcon(type) {
      return VIEW_TYPES[type]?.icon || 'fa-memo fa-regular';
    },
    viewComponents(type) {
      return VIEW_TYPES[type]?.components.map(component => ({
        ...component,
        name: component.locales[this.$i18n.locale],
      }));
    },
    pageState(lang, type) {
      const stateType = PAGE_STATE_TYPES[type];
      return {
        value: type,
        text: stateType?.locales[lang] || undefinedTranslate[lang],
        icon: stateType?.icon || 'question',
      };
    },
    pageStateIcon(type) {
      const stateType = PAGE_STATE_TYPES[type];
      return stateType?.icon || UNDEFINED_ICON;
    },
    pageStates(lang, viewType = null, kind = 'default') {
      if (!['default', 'redirect'].includes(kind)) throw new Error(`Invalid page state configuration kind "${kind}"`);

      const capitalizedKind = kind.charAt(0).toUpperCase() + kind.slice(1);
      const statesConfigKey = `available${capitalizedKind}States`;

      const stateTypes = viewType
        ? VIEW_TYPES[viewType].builderOptions[statesConfigKey]
        : AVAILABLE_PAGE_STATE_TYPES;
      return stateTypes.map(t => (this.pageState(lang, t)));
    },
  },
};
