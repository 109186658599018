import debounce from './debounce';

export default class AsyncDataManagerSaveState {
  #saveMethod;

  #isDebouncing;

  #isSaving;

  #needsSave;

  #onSave;

  #onSaveError;

  #debounce;

  constructor({ debounceTime }) {
    this.#isDebouncing = false;
    this.#isSaving = false;
    this.#needsSave = false;

    // use debounce to avoid multiple save requests
    // when the save method is called multiple times
    // obs: wrap the #handleSave in a arrow function to keep the context
    this.#debounce = debounce(() => {
      this.#isDebouncing = false;
      this.#handleSave();
    }, debounceTime);
  }

  hasPendingSave() {
    return this.#isSaving || this.#isDebouncing || this.#needsSave;
  }

  #debounceSave() {
    this.#isDebouncing = true;
    this.#debounce();
  }

  #handleSave() {
    // the previous save request is still saving
    if (this.#isSaving) {
      // mark to save it again after the current save request is finished
      this.#needsSave = true;
      return;
    }

    this.#isSaving = true;
    this.#needsSave = false;

    this.#saveMethod()
      .then((data) => {
        this.#isSaving = false;

        // if it is debouncing, it means that the save method will be called again soon
        // so, do not call the onSave method to avoid reactions for an outdated save request
        if (this.#isDebouncing) return;

        if (this.#needsSave) {
          // use directly the save method to avoid debounce
          // because the debounce is already finished for this request
          this.#handleSave();

          return;
        }

        this.#onSave(data);
      })
      .catch((error) => {
        this.#isSaving = false;

        if (this.#isDebouncing) return;

        // if there is another incoming save request, it can solve the error
        if (this.#needsSave) {
          // use directly the save method to avoid debounce
          // because the debounce is already finished for this request
          this.#handleSave();
          return;
        }

        this.#onSaveError(error);
      });
  }

  save({
    saveMethod,
    onSave,
    onSaveError,
  }) {
    this.#saveMethod = saveMethod;
    this.#onSave = onSave;
    this.#onSaveError = onSaveError;

    this.#debounceSave();
  }
}
