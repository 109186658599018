const ROUTES = {
  studio: {
    views: {
      routeName: 't-tenantSlug-studio-pages',
    },
    view: {
      routeName: 't-tenantSlug-studio-pages-id',
      viewId: 'id',
    },
    sheets: {
      routeName: 't-tenantSlug-studio-data-id',
      sheetId: 'id',
    },
    workflows: {
      routeName: 't-tenantSlug-studio-automations',
    },
    workflow: {
      routeName: 't-tenantSlug-studio-automations-id',
      workflowId: 'id',
    },
  },
  member: {
    views: {
      routeName: 't-tenantSlug-m',
    },
    view: {
      routeName: 't-tenantSlug-m-viewId',
      viewId: 'viewId',
      record: {
        routeName: 't-tenantSlug-m-viewId-r-recordId',
        viewId: 'viewId',
        new: {
          routeName: 't-tenantSlug-m-viewId-r-new',
          viewId: 'viewId',
        },
      },
    },
  },
  public: {
    views: {
      routeName: 't-tenantSlug-p',
    },
    view: {
      routeName: 't-tenantSlug-p-viewId',
      viewId: 'viewId',
      record: {
        routeName: 't-tenantSlug-p-viewId-r-recordId',
        viewId: 'viewId',
      },
    },
    emailValidation: {
      routeName: 't-tenantSlug-email-validation',
    },
    login: {
      routeName: 't-tenantSlug-login',
    },
  },
};

export default ROUTES;

export const SHOW_RECORDS_ROUTES = [
  ROUTES.member.view.record,
  ROUTES.public.view.record,
];

export const NEW_RECORDS_ROUTES = [
  ROUTES.member.view.record.new,
];

const removeLocaleFromRoute = route => route.split('___')[0];

export function isChangingBetweenRoutes({ from, to, routes }) {
  const fromAndTo = [removeLocaleFromRoute(from), removeLocaleFromRoute(to)];

  return routes.every(route => fromAndTo.includes(removeLocaleFromRoute(route)));
}
