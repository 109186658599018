export const createDeconstructedPromise = () => {
  let outsideResolve = null;
  let outsideReject = null;

  const promise = new Promise((resolve, reject) => {
    outsideResolve = resolve;
    outsideReject = reject;
  });

  return {
    promise,
    resolve: outsideResolve,
    reject: outsideReject,
  };
};
