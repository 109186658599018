import VuexPersist from 'vuex-persist';
import { get, set } from 'lodash';

export const LOCAL_STORAGE_KEY = 'vuexPersistV3';

const storesToPersist = {
  view: ['locale'],
  viewOptions: ['sortBy', 'sortDesc', 'filter', 'columnWidths'],
  viewsMenu: ['miniSidebar'],
  workspace: ['workspace', 'currentCategoryName', 'newVersion', 'currentScope'],
  apps: ['selectedAreasOfInterest', 'selectedAppsPerSelectedAreaOfInterest'],
};

const SAFELIST = Object.entries(storesToPersist).reduce((acc, [storeName, keys]) => {
  keys.forEach((key) => {
    acc.push(`${storeName}.${key}`);
  });

  return acc;
}, []);

export default defineNuxtPlugin({
  name: 'actioncable-vue',
  dependsOn: ['store'],
  setup({ $store }) {
    new VuexPersist({
      key: LOCAL_STORAGE_KEY, // The key to store the state on in the storage provider.
      modules: Object.keys(storesToPersist),
      storage: {
        ...window.localStorage,
        // Keep this function defined to dont break vue persistence getItem
        getItem(key) {
          return window.localStorage?.getItem(key);
        },
        setItem(key, value) {
          try {
            const parsedValue = JSON.parse(value);
            const finalValue = SAFELIST.reduce((acc, path) => {
              set(acc, path, get(parsedValue, path));
              return acc;
            }, {});

            window.localStorage?.setItem(key, JSON.stringify(finalValue));
          } catch {
            window.localStorage?.removeItem(key);
          }
        },
      }, // or window.sessionStorage or localForage
    }).plugin($store);
  },
});
