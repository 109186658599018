const OUTER_COMMAS_REGEX = /\s*,\s*(?=(?:[^"]|"[^"]*")*$)/;
const BEGINNING_AND_ENDING_QUOTATION_MARKS_REGEX = /^"|"$/g;

function splitStringByOuterCommas(text) {
  return text.split(OUTER_COMMAS_REGEX);
}

function cleanBeginningAndEndingQuotationMarks(text) {
  return text.replace(BEGINNING_AND_ENDING_QUOTATION_MARKS_REGEX, '');
}

export function arrayToString(array) {
  return array.map((item) => {
    if (typeof item !== 'string') return '';

    if (item.includes(',') || item.match(BEGINNING_AND_ENDING_QUOTATION_MARKS_REGEX)) {
      return `"${item}"`;
    }

    return item;
  }).join(', ');
}

export function stringToArray(text) {
  return splitStringByOuterCommas(text).map(cleanBeginningAndEndingQuotationMarks);
}

