import moment from 'moment-timezone';

export default defineNuxtPlugin({
  name: 'vue-moment',
  setup() {
    return {
      provide: {
        moment,
      },
    };
  },
});
