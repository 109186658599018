import { ref, computed } from 'vue';
import { useStorage } from '@vueuse/core';

export const ZAZOS_STORAGE_KEY = 'zazos-storage';

const LOGOS = {
  light: '/zazos-text.svg',
  dark: '/zazos-text-white.svg',
};

const DARK_MODE_FROM_THEME = {
  // system: () => (window.matchMedia
  //   ? window.matchMedia('(prefers-color-scheme: dark)').matches
  //   : false),
  // False until we are ready to support dark mode
  system: () => false,
  light: () => false,
  dark: () => true,
};

// Using useStorage to persist the theme in local storage
const storage = useStorage(ZAZOS_STORAGE_KEY, { theme: 'light' });
const theme = ref(storage.value.theme || 'light');
const darkMode = ref(DARK_MODE_FROM_THEME[theme.value]());
const logoPath = computed(() => (darkMode.value ? LOGOS.dark : LOGOS.light));

export function useZazos() {
  const vuetifyTheme = useTheme();

  const setTheme = (newTheme) => {
    theme.value = newTheme;
    darkMode.value = DARK_MODE_FROM_THEME[newTheme]();
    storage.value.theme = newTheme;

    // Update the Vuetify theme
    vuetifyTheme.global.name.value = darkMode.value ? 'dark' : 'light';
  };

  // Method to change the theme
  watch(theme, setTheme);

  return {
    theme,
    darkMode,
    logoPath,
    darkLogoPath: LOGOS.dark,
    lightLogoPath: LOGOS.light,
    initializeTheme: () => setTheme(theme.value),
  };
}
