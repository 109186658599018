import { v4 as uuidv4 } from 'uuid';

export const notifier = (store: any) => ({
  showMessage({ content = '', color = '', timeout = undefined }) { // When not defining the timeout, it will rely on the deck-snackbar default timeout
    return store.dispatch('snackbar/showMessage', {
      message: content, color, timeout, id: uuidv4(),
    });
  },
});

export default defineNuxtPlugin({
  name: 'notifier',
  dependsOn: ['store'],
  setup(nuxtApp) {
    return {
      provide: {
        notifier: notifier(nuxtApp.$store),
      },
    };
  },
});
