import { generatePlugin } from '~/assets/javascript/modules/auth';
import type { NuxtApp } from '#app';

declare module 'vue' {
  interface ComponentCustomProperties {
    $auth: NuxtApp['$auth']
  }
}

export default defineNuxtPlugin({
  name: 'auth',
  dependsOn: ['store'],
  setup(nuxtApp) {
    return generatePlugin(nuxtApp);
  },
});
