import type { Directive } from 'vue';

// define ts functions with types
function testClass(el: HTMLElement, { value }: { value: string }) {
  el.classList.add(`test-class-${value}`);
}

function testId(el: HTMLElement, { value }: { value: string }) {
  el.dataset.testid = `${value}`;
}

export const TestClassDirective: Directive = process.env.NODE_ENV === 'test' ? testClass : {};
export const TestIdDirective: Directive = process.env.NODE_ENV === 'test' ? testId : {};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('test-class', TestClassDirective);
  nuxtApp.vueApp.directive('test-id', TestIdDirective);
});
