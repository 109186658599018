import { calcAPCA } from 'apca-w3';

export const TRANSLUCENT_WHITE = '#FFFFFFD9'; // TODO: Use tokens when available
export const TRANSLUCENT_BLACK = '#000000A3'; // TODO: Use tokens when available

export const getAccessibleColor = (backgroundColorHex, darkMode) => {
  const whiteContrastDifference = parseFloat(Math.abs(calcAPCA(TRANSLUCENT_WHITE, backgroundColorHex)).toFixed(1));
  const blackContrastDifference = parseFloat(Math.abs(calcAPCA(TRANSLUCENT_BLACK, backgroundColorHex)).toFixed(1));
  const result = whiteContrastDifference >= blackContrastDifference;

  if (darkMode) {
    return result ? TRANSLUCENT_BLACK : TRANSLUCENT_WHITE;
  }

  return result ? TRANSLUCENT_WHITE : TRANSLUCENT_BLACK;
};
