import type { MutationTree } from 'vuex';

export const state = () => ({
  records: [],
  invalidCells: [],
});

type State = ReturnType<typeof state>;

export const mutations = <MutationTree<State>>{
  initTable(state) {
    state.invalidCells = [];
  },
  appendInvalidCell(state, cell) {
    state.invalidCells.push(cell);
  },
  removeInvalidCell(state, cell) {
    state.invalidCells.splice(state.invalidCells.indexOf(cell), 1);
  },
};

export const namespaced = true;
