import { isArray, isEqual, isObject } from 'lodash';

export const safeInsertValueInObjectPath = (obj, path, value) => {
  const pathArray = path.split('.');
  const lastIndex = pathArray.length - 1;

  let currentObj = obj;

  pathArray.forEach((key, index) => {
    if (index === lastIndex) {
      currentObj[key] = value;
    } else {
      currentObj[key] ||= {};
      currentObj = currentObj[key];
    }
  });

  // make sure that the Observer is triggered by this change
  obj[pathArray[0]] = { ...obj[pathArray[0]] };

  return obj;
};

export const getObjectValuePath = (obj, value, evaluator = isEqual) => {
  const path = [];

  const findValue = currentObj => Object.entries(currentObj).some(([key, currentValue]) => {
    if (evaluator(currentValue, value)) {
      path.push(key);
      return true;
    }

    if (isObject(currentValue)) {
      path.push(key);

      if (findValue(currentValue)) {
        return true;
      }

      path.pop();
    } else if (isArray(currentValue)) {
      return currentValue.some((item, index) => {
        path.push(index);

        if (findValue(item)) {
          return true;
        }

        path.pop();
        return false;
      });
    }

    return false;
  });

  findValue(obj);

  return path.length > 0 ? path.join('.') : undefined;
};
