<i18n lang="yaml">
pt:
  clearAllInGroup: "Desmarcar todas deste grupo"
  selectAllInGroup: "Marcar todas deste grupo"
en:
  clearAllInGroup: "Uncheck all in this group"
  selectAllInGroup: "Check all in this group"
</i18n>

<!-- TODO: group-label and select-all components are pretty similar, we should abstract them in a smarter way -->
<template>
  <div class="deck-select-list-item-group-label">
    <deck-tooltip
      v-if="!hideCheckbox"
      :text="item.groupSelection.isNone ? t('selectAllInGroup') : t('clearAllInGroup')"
      :disabled="disabled"
      position="left"
    >
      <template #activator="{ props }">
        <deck-checkbox
          :model-value="item.groupSelection.isAll"
          :indeterminate="item.groupSelection.isSome"
          :disabled="disabled"
          class="deck-select-list-item-group-label__checkbox"
          v-bind="props"
          @click.prevent.stop.capture="$emit('selectAll')"
        />
      </template>
    </deck-tooltip>
    <deck-icon
      v-if="item.icon"
      :name="item.icon"
      size="small"
      fixed-width
    />
    <deck-label :text="item.label" />
  </div>
</template>

<script>
import DeckTooltip from '~/deck/tooltip';
import DeckCheckbox from '~/deck/checkbox';
import DeckLabel from '~/deck/label';
import DeckIcon from '~/deck/icon';

export default {
  name: 'DeckSelectListItemGroupLabel',
  components: {
    DeckTooltip,
    DeckCheckbox,
    DeckIcon,
    DeckLabel,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectAll'],
  setup() {
    return {
      t: useI18n().t,
    };
  },
};
</script>
<style lang="scss">
.deck-select-list-item-group-label {
  display: flex;
  align-items: center;
  gap: var(--z-s2);
}

.deck-select-list-item-group-label__checkbox {
  opacity: 0.8;
}
</style>
