<i18n lang="yaml">
pt:
  title: 'O time {workspaceName} está feliz em te ver aqui! 🙌 '
  signIn:
    title: 'Entre na Área de Trabalho'
  signUp:
    title: 'Faça seu cadastro e confira nossas carreiras'
en:
  title: '{workspaceName} welcomes you! 🙌'
  signIn:
    title: 'Log into workspace'
  signUp:
    title: 'Sign up and check our careers'
</i18n>

<template>
  <AuthLogin
    :layout-attrs="{
      title: t('title', { workspaceName }),
    }"
    default-tab="signUp"
    :modules="['signIn', 'signUp', 'magicLink']"
    :module-attrs="{
      signIn: {
        title: t('signIn.title'),
      },
      signUp: {
        title: t('signUp.title'),
      },
    }"
  />
</template>

<script lang="ts">
import { mapGetters } from '~/assets/javascript/modules/vuex';
import AuthLogin from '~/components/auth/login';

export default defineComponent({
  name: 'SignUp',
  components: { AuthLogin },
  setup() {
    const { t } = useI18n();

    definePageMeta({ layout: 'public' });

    return {
      t,
      ...mapGetters('workspace', ['workspaceName']),
    };
  },
});
</script>
