<i18n lang="yaml">
pt:
  emailValidationInProgress: 'Verificando e-mail'
  emailValidationSuccess: 'E-mail validado com sucesso'
  enterWorkspace: 'Entrar'
  magicLinkLogin: 'Entrando com link mágico'
en:
  emailValidationInProgress: 'Validating email'
  emailValidationSuccess: 'Email validated successfully'
  enterWorkspace: 'Enter'
  magicLinkLogin: 'Login using magic link'
</i18n>
<template>
  <AuthLogin
    :layout-attrs="{
      title: title,
      hideSignUpDisclaimer: true,
      hideCaptchaDisclaimer: true,
      loading: loading,
      showSwitchLocaleButton: false,
    }"
    :modules="modules"
    :module-attrs="{
      emailValidation: {
        loading: loading,
        validatedEmailBtnAttrs: {
          to: loadingPage,
          text: t('enterWorkspace'),
        }
      },
    }"
  />
</template>

<script lang="ts">
import AuthLogin from '~/components/auth/login';

export default defineComponent({
  name: 'MagicLink',
  components: { AuthLogin },
  setup() {
    definePageMeta({
      middleware: ['auth-redirect'],
    });

    return {
      t: useI18n().t,
    };
  },
  auth: false,
  data() {
    return {
      state: 'loading',
    };
  },
  computed: {
    fromEmailValidation() {
      return this.$route.query.email_validation === 'true';
    },
    loading() {
      return this.state === 'loading';
    },
    title() {
      if (this.fromEmailValidation) {
        if (this.loading) return this.t('emailValidationInProgress');

        return this.t('emailValidationSuccess');
      }

      return this.t('magicLinkLogin');
    },
    modules() {
      if (this.fromEmailValidation) return ['emailValidation'];

      return [];
    },
    tenantSlug() {
      return this.$auth.user?.workspace?.slug;
    },
    loadingPage() {
      const redirect = {
        name: 't-tenantSlug-loading',
        params: {
          tenantSlug: this.tenantSlug,
        },
      };

      if (this.$route.query.app) {
        redirect.query = {
          app: this.$route.query.app,
        };
      }

      return this.localePath(redirect);
    },
  },
  async mounted() {
    try {
      await this.$auth.magicLinkModule.login({
        token: this.$route.query.token,
        withCallback: !this.fromEmailValidation,
      });

      this.state = 'success';
    } catch (error) {
      this.state = 'error';

      this.$errorRescue(this, error, 'invalidLink', {
        silenceRollbar: error?.response?._data?.errors?.credentials === 'invalid',
      });

      delete this.$route.query.token;

      this.$router.push(this.localePath({
        name: 'login',
        query: this.$route.query,
      }));
    }
  },
});
</script>
