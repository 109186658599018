<template>
  <NuxtLoadingIndicator
    color="rgb(var(--v-theme-primary))"
    error-color="rgb(var(--v-theme-error))"
  />

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
useZazos().initializeTheme();
</script>
