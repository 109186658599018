import { executions } from './executions';

export const workflows = ($http: HttpApi) => ({
  create: (data: HttpParams['body'] = {}) => $http.$post('/workflows', { body: data }),
  get: (workflowId: string, params: HttpParams['params'] = {}) => $http.$get(`/workflows/${workflowId}`, { params }),
  list: (params: HttpParams['params'] = {}) => $http.$get('/workflows', { params }),
  update: (workflowId: string, params: HttpParams['body']) => $http.$patch(`/workflows/${workflowId}`, { body: params }),
  archive: (workflowId: string) => $http.$post(`/workflows/${workflowId}/archive`),
  unarchive: (workflowId: string) => $http.$delete(`/workflows/${workflowId}/archive`),

  // nested resources
  executions: executions($http),
});
