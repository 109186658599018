<i18n lang="yaml">
pt:
  cardOptions: 'Opções do card'
  publicPrivacyNotice: 'Aberto: Os dados dessa página são associados ao seu usuário e podem ser acessados pelos administradores do sistema.'
  confidentialPrivacyNotice: 'Confidencial: O sistema registra seus dados, mas nenhum administrador têm acesso nominal às suas respostas.'
  anonymousPrivacyNotice: 'Anônimo: Suas respostas são registradas de modo criptografado e sem nenhuma informação pessoal associada.'
en:
  cardOptions: 'Card options'
  publicPrivacyNotice: 'Public: The information in this page is associated to you and can be accessed by the system administrators.'
  confidentialPrivacyNotice: 'Confidential: The system will register your information, but it will not be available to any other user.'
  anonymousPrivacyNotice: 'Anonymous: Your information is encrypted before it is registered, with no personal information associated.'
</i18n>

<template>
  <v-row
    class="deck-exodia-header align-baseline"
    no-gutters
  >
    <v-col
      v-if="avatarIcon || avatarImageUrl"
      class="flex-grow-0 align-self-center"
    >
      <div
        v-test-id="'deck-exodia-header-avatar-image'"
        :class="{
          'deck-exodia-header__avatar': true,
          [`deck-exodia-header__avatar--${size}`]: true,
          'deck-exodia-header__avatar--contain': avatarImageContain,
        }"
        :style="`background-image: url(${avatarImageUrl});`"
      >
        <v-icon
          v-if="avatarIcon"
          class="text--primary"
        >
          fa-regular {{ avatarIcon }}
        </v-icon>
      </div>
    </v-col>

    <v-col class="flex-1 min-w-0">
      <deck-trimmer
        v-if="title"
        :text="title"
        :class="{ [`deck-exodia-header__title--${size}`]: true }"
        :line-clamp="titleLineClamp"
        class="deck-exodia-header__title mb-0"
        tag="p"
        role="heading"
        aria-level="2"
      />
      <deck-trimmer
        v-if="subtitle"
        :class="`deck-exodia-header__subtitle--${size}`"
        :text="subtitle"
        :line-clamp="subtitleLineClamp"
        class="deck-exodia-header__subtitle mb-0"
        tag="p"
        role="heading"
        aria-level="3"
      />
    </v-col>

    <slot name="top-content" />

    <v-col
      v-if="Boolean(privacy)"
      class="flex-grow-0"
    >
      <deck-chip
        :icon="privacyIcon"
        :text="t(`${privacy}PrivacyNotice`)"
      />
    </v-col>

    <v-col
      v-if="$slots['menu-items']"
      class="flex-grow-0"
    >
      <v-menu
        offset="8"
        min-width="200"
        location="bottom"
      >
        <template #activator="{ props }">
          <v-btn
            :size="size === 'small' ? 'small' : 'default'"
            icon
            :aria-label="t('cardOptions')"
            class="ml-2"
            elevation="0"
            v-bind="props"
            @click.prevent
          >
            <v-icon>fa-solid fa-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <slot name="menu-items" />
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import DeckChip from '~/deck/chip';
import DeckTrimmer from '~/deck/trimmer';

export default {
  name: 'DeckExodiaHeader',
  components: {
    DeckChip,
    DeckTrimmer,
  },
  props: {
    title: { type: String, default: null },
    subtitle: { type: String, default: null },
    avatarIcon: { type: String, default: null },
    avatarImageUrl: { type: String, default: null },
    avatarImageContain: { type: Boolean, default: false },
    size: { type: String, required: true },
    titleLineClamp: { type: [Number, String], default: 1 },
    subtitleLineClamp: { type: [Number, String], default: 2 },
    privacy: { type: String, default: null },
  },
  setup() {
    return {
      t: useI18n().t,
    };
  },
  computed: {
    privacyIcon() {
      switch (this.privacy) {
        case 'public':
          return 'fa-regular fa-user-unlock';
        case 'confidential':
          return 'fa-regular fa-user-lock';
        case 'anonymous':
          return 'fa-regular fa-user-shield';
        default:
          return null;
      }
    },
  },
};
</script>

<style lang="scss">
.deck-exodia-header {
  flex-grow: 0;
  flex-wrap: nowrap;
  gap: var(--z-s1);
  margin-top: 0;
}

.deck-exodia-header__title {
  position: relative;
  font-size: 24px !important;
  line-height: 30px;
  font-weight: 500;
}

.deck-exodia-header__title--small{
  font-size: 16px !important;
  line-height: 20px;
}

.deck-exodia-header__subtitle {
  font-size: 16px !important;
  line-height: 20px;
  color: var(--z-color-label) !important;
  margin-top: var(--z-s1);
}

.deck-exodia-header__subtitle--small{
  font-size: 12px !important;
  line-height: 16px;
}

.deck-exodia-header__avatar {
  height: 56px;
  width: 56px;
  margin-right: var(--z-s2) !important;
  border-radius: var(--z-border-radius-base);
  background-size: cover;

  .v-icon {
    font-size: 36px;
    height: 100%;
    width: 100%;
  }
}

.deck-exodia-header__avatar--small {
  height: 40px;
  width: 40px;

  .v-icon {
    font-size: 28px;
    height: 100%;
    width: 100%;
  }
}

.deck-exodia-header__avatar--contain {
  background-size: contain;
}
</style>
