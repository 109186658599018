export const UNSENT_VIEW_ATTRIBUTES = Object.freeze([
  'fields',
  'fields_ids',
  'fields_required',
  'fields_uneditable',
  'has_changes',
  'layoutOptions',
  'recordIds',
  'sheet',
  'subviews',
]);
