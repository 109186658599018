<template>
  <div
    class="deck-checkbox"
    :class="classes"
  >
    <!--
      Triggered when the checkbox value is modified. Sends its value as the
      payload. Bound to v-model.
      @event input
      @type {boolean}
    -->
    <v-checkbox-btn
      v-click-outside="onClickOutside"
      :model-value="modelValue"
      :indeterminate="indeterminate"
      :disabled="disabled"
      :hide-details="hideDetails"
      density="compact"
      :label="label"
      :aria-label="ariaLabel"
      color="primary"
      v-bind="$attrs"
      @update:model-value="onUpdateModelValue"
      @click="onClick"
    >
      <template
        v-if="$slots.default"
        #label
      >
        <!--
          @slot Default slot that may serve as a replacement for the label.
          When using this slot avoid using elements with custom line-heights and
          just let them inherit it from the parent, as this element references a
          static line-height var to center the label with the checkbox box.
        -->
        <slot>
          <deck-label
            :text="label"
            size="small"
          />
        </slot>
      </template>
    </v-checkbox-btn>
  </div>
</template>

<script>
/**
 * Wrapper component for the Vuetify's `v-checkbox`.
 * See https://v2.vuetifyjs.com/en/components/checkboxes
 */
export default {
  name: 'DeckCheckbox',
  components: {
    DeckLabel: defineAsyncComponent(() => import('~/deck/label')),
  },
  props: {
    /**
     * The value of the checkbox.
     * Note: Bound to the `input-value` prop of `v-checkbox`, which is the one
     * bound to v-model. More Vue-centric in our implementation.
     * @type {boolean}
     * @default undefined
     */
    modelValue: {
      type: Boolean,
      default: undefined,
    },

    /**
     * Hide hint and validation errors.
     * @type {boolean | string}
     * @default true
     */
    hideDetails: {
      type: [Boolean, String],
      default: true,
    },

    /**
     * Disable the checkbox.
     * @type {boolean}
     * @default false
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Sets an indeterminate state for the checkbox
     * @type {boolean}
     * @default false
     */
    indeterminate: {
      type: Boolean,
      default: false,
    },

    /**
     * The label to display alongside the checkbox.
     * @type {string}
     * @default undefined
     */
    label: {
      type: String,
      default: undefined,
    },

    /**
     * The aria-label html attribute.
     * @type {string}
     * @default undefined
     */
    ariaLabel: {
      type: String,
      default: undefined,
    },
  },
  emits: ['update:modelValue', 'focus', 'blur'],
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    classes() {
      return {
        'deck-checkbox--checked': this.modelValue,
        'deck-checkbox--unchecked': !this.modelValue,
        'deck-checkbox--indeterminate': this.indeterminate,
        'deck-checkbox--disabled': this.disabled,
      };
    },
  },
  methods: {
    onUpdateModelValue(value) {
      this.$emit('update:modelValue', value);
    },
    /**
     * Focuses the checkbox.
     */
    onClick() {
      if (!this.focused) {
        this.focused = true;
        /**
         * Triggered when the checkbox is focused.
         * @event focus
         */
        this.$emit('focus');
      }
    },
    /**
     * Blurs the checkbox.
     * This was necessary because vuetify's v-checkbox on v2 version doesn't emit blur events.
     */
    onClickOutside() {
      if (this.focused) {
        this.focused = false;
        /**
         * Triggered when the checkbox is blurred.
         * @event blur
         */
        this.$emit('blur');
      }
    },
  },
};
</script>
<style lang="scss">
.deck-checkbox {
  .v-input--density-compact {
    --v-input-control-height: 16px;
  }

  .v-selection-control--density-compact {
    --v-selection-control-size: 16px;
  }

  .v-selection-control {
    align-items: center;
    gap: 8px;

    .v-selection-control__input {
      &:before {
        width: 28px;
        height: 28px;
        border-radius: 8px;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        transition: 100ms ease !important;
        background-color: rgb(var(--v-theme-primary)) !important;
      }

      .v-icon {
        font-size: 16px;
        transition: 100ms ease;
        pointer-events: none;
        z-index: 2;

        &::before {
          font-size: 1em;
        }
      }

     .v-label {
       line-height: var(--deck-checkbox-label-line-height);

        // Centers the first line of the label with the checkbox box
        transform: translateY(calc(var(--deck-checkbox-box-height) / 2 - 0.5em * var(--deck-checkbox-label-line-height)))
      }
    }
  }
}

.deck-checkbox--disabled .v-input {
  pointer-events: none;
  opacity: 0.5;
}
</style>
