import { v4 as uuidv4 } from 'uuid';

export default class MarkdownInfoComponentOptionBuilder {
  static build(infoComponent) {
    return new MarkdownInfoComponentOptionBuilder(infoComponent).build();
  }

  constructor(infoComponent) {
    this.markdownInfoComponent = infoComponent;
  }

  build() {
    const markdownInfoComponentId = this.markdownInfoComponent.id || uuidv4();

    const markdownInfoComponentOption = {
      ...this.markdownInfoComponent,
      id: markdownInfoComponentId,
      title: this.markdownInfoComponent.label,
      titleTypography: 'text-body-2',
      icon: 'fa-brands fa-markdown',
    };

    return markdownInfoComponentOption;
  }
}
