import { useNuxtApp } from '#app';
import type { MutationTree, ActionTree } from 'vuex';
import { enrichViewData } from '~/assets/javascript/utils';

export const state = () => ({
  views: [],
  miniSidebar: window.localStorage?.getItem('viewsMenu.miniSidebar') || false,
  search: '',
});

type State = ReturnType<typeof state>;

export const mutations = <MutationTree<State>>{
  updateViews(state, { views }) {
    state.views = views;
  },
  setMiniSidebar(state, data) {
    state.miniSidebar = data;
  },
  updateString(state, payload) {
    state[payload.field] = payload.data;
  },
  resetFilterToolbarSearch(state) {
    state.search = '';
  },
};

export const actions = <ActionTree<State, any>>{
  async loadViews({ commit, rootGetters }, { tenantSlug = null, isPublic = false }) {
    const { $api } = useNuxtApp();
    const path = isPublic ? `/public/workspaces/${tenantSlug}/views` : '/views';
    const views = await $api.$get(path);

    const categories = rootGetters['workspace/categoryHierarchy'];

    const enrichedViews = enrichViewData(views, categories);

    commit('updateViews', { views: enrichedViews });
  },
};

export const namespaced = true;
