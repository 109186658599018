import { calculateFieldIdMapping } from '~/assets/javascript/utils';
import { VIEW_TYPES } from '~/assets/javascript/constants';

export default class RecordListOptions {
  constructor(view, locale, pageSectionKey = 'main_section') {
    this.view = view;
    this.locale = locale;
    this.layoutOptions = VIEW_TYPES[view.page_type]?.builderOptions || {};
    this.fieldIdMapping = calculateFieldIdMapping(this.view.fields || [], this.locale);
    this.pageSectionKey = pageSectionKey;
  }

  // Search
  get hasGlobalSearch() {
    return Boolean(this.layoutOptions.hasGlobalSearch);
  }

  get hasFilterBy() {
    return Boolean(this.layoutOptions.canFilterDataByColumn && this.filterByFieldId);
  }

  get filterByFieldId() {
    return this.view.page_state_components?.index?.[this.pageSectionKey]?.allow_filter_by;
  }

  get filterByFieldName() {
    return this.fieldIdMapping[this.filterByFieldId]?.name;
  }

  // Group by
  get hasGroup() {
    return Boolean(this.layoutOptions.canGroupDataByColumn && this.groupFieldId);
  }

  get groupFieldId() {
    return this.view.page_state_components?.index?.[this.pageSectionKey]?.column_group_by?.field_id;
  }

  get currentGroupByField() {
    if (!this.hasGroup) return null;

    return this.fieldIdMapping[this.groupFieldId];
  }
}
