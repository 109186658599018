import { defineNuxtPlugin } from '#app/nuxt'
import { LazyDeckAccordion, LazyDeckAlert, LazyDeckAvatar, LazyDeckButtonGroup, LazyDeckButton, LazyDeckCheckbox, LazyDeckChip, LazyDeckExodiaFooter, LazyDeckExodiaHeaderImageProfileImageFallback, LazyDeckExodiaHeaderImage, LazyDeckExodiaHeader, LazyDeckExodiaToolbar, LazyDeckExodia, LazyDeckExpander, LazyDeckHinter, LazyDeckIcon, LazyDeckInputsInputLabel, LazyDeckInputsDateInput, LazyDeckInputsNumberInput, LazyDeckInputsSelectInputItem, LazyDeckInputsSelectInputNewItem, LazyDeckInputsSelectInput, LazyDeckInputsSelectOptionsInput, LazyDeckInputsTextWizardInput, LazyDeckInputsYamlFileInput, LazyDeckKbd, LazyDeckLabel, LazyDeckProgress, LazyDeckRadio, LazyDeckSelectListItemGroupLabel, LazyDeckSelectListItemSelectAll, LazyDeckSelectListItemSelectable, LazyDeckSelectListItem, LazyDeckSelectSelection, LazyDeckSelectVListItem, LazyDeckSelect, LazyDeckSnackbar, LazyDeckSwitch, LazyDeckTabs, LazyDeckTabsTab, LazyDeckTextFieldAppendDefault, LazyDeckTextField, LazyDeckTooltip, LazyDeckTrimmer, LazyDeckUtilsColorContrast, LazyDeckUtilsColor, LazyDeckUtils } from '#components'
const lazyGlobalComponents = [
  ["DeckAccordion", LazyDeckAccordion],
["DeckAlert", LazyDeckAlert],
["DeckAvatar", LazyDeckAvatar],
["DeckButtonGroup", LazyDeckButtonGroup],
["DeckButton", LazyDeckButton],
["DeckCheckbox", LazyDeckCheckbox],
["DeckChip", LazyDeckChip],
["DeckExodiaFooter", LazyDeckExodiaFooter],
["DeckExodiaHeaderImageProfileImageFallback", LazyDeckExodiaHeaderImageProfileImageFallback],
["DeckExodiaHeaderImage", LazyDeckExodiaHeaderImage],
["DeckExodiaHeader", LazyDeckExodiaHeader],
["DeckExodiaToolbar", LazyDeckExodiaToolbar],
["DeckExodia", LazyDeckExodia],
["DeckExpander", LazyDeckExpander],
["DeckHinter", LazyDeckHinter],
["DeckIcon", LazyDeckIcon],
["DeckInputsInputLabel", LazyDeckInputsInputLabel],
["DeckInputsDateInput", LazyDeckInputsDateInput],
["DeckInputsNumberInput", LazyDeckInputsNumberInput],
["DeckInputsSelectInputItem", LazyDeckInputsSelectInputItem],
["DeckInputsSelectInputNewItem", LazyDeckInputsSelectInputNewItem],
["DeckInputsSelectInput", LazyDeckInputsSelectInput],
["DeckInputsSelectOptionsInput", LazyDeckInputsSelectOptionsInput],
["DeckInputsTextWizardInput", LazyDeckInputsTextWizardInput],
["DeckInputsYamlFileInput", LazyDeckInputsYamlFileInput],
["DeckKbd", LazyDeckKbd],
["DeckLabel", LazyDeckLabel],
["DeckProgress", LazyDeckProgress],
["DeckRadio", LazyDeckRadio],
["DeckSelectListItemGroupLabel", LazyDeckSelectListItemGroupLabel],
["DeckSelectListItemSelectAll", LazyDeckSelectListItemSelectAll],
["DeckSelectListItemSelectable", LazyDeckSelectListItemSelectable],
["DeckSelectListItem", LazyDeckSelectListItem],
["DeckSelectSelection", LazyDeckSelectSelection],
["DeckSelectVListItem", LazyDeckSelectVListItem],
["DeckSelect", LazyDeckSelect],
["DeckSnackbar", LazyDeckSnackbar],
["DeckSwitch", LazyDeckSwitch],
["DeckTabs", LazyDeckTabs],
["DeckTabsTab", LazyDeckTabsTab],
["DeckTextFieldAppendDefault", LazyDeckTextFieldAppendDefault],
["DeckTextField", LazyDeckTextField],
["DeckTooltip", LazyDeckTooltip],
["DeckTrimmer", LazyDeckTrimmer],
["DeckUtilsColorContrast", LazyDeckUtilsColorContrast],
["DeckUtilsColor", LazyDeckUtilsColor],
["DeckUtils", LazyDeckUtils],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
