import { filters } from './filters';
import { filterSchema } from './filterSchema';

export const views = ($http: HttpApi) => ({
  get: (viewId: string, params: HttpParams['params'] = {}) => $http.$get(`/builder/views/${viewId}`, { params }),
  create: (data: HttpParams['body'] = {}) => $http.$post('/builder/views', { body: data }),
  update: (viewId: string, data: HttpParams['body'] = {}) => $http.$patch(`/builder/views/${viewId}`, { body: data }),
  publish: (viewId: string) => $http.$post(`/builder/views/${viewId}/publish`),
  archive: (viewId: string) => $http.$post(`/builder/views/${viewId}/archive`),
  unarchive: (viewId: string) => $http.$delete(`/builder/views/${viewId}/archive`),
  filters: filters($http),
  filterSchema: filterSchema($http),
});
