import VUETIFY_COLORS from 'vuetify/util/colors';

function buildColor(hex, fontColor) {
  return { background: hex, color: fontColor };
}

export const COLORS = Object.freeze({
  blue: {
    accent: buildColor('#bbdefb', 'black'),
    light: buildColor('#82b1ff', 'black'),
    base: buildColor('#2196f3', 'white'),
    dark: buildColor('#0d47a1', 'white'),
  },
  cyan: {
    accent: buildColor('#b2ebf2', 'black'),
    light: buildColor('#84ffff', 'black'),
    base: buildColor('#00bcd4', 'white'),
    dark: buildColor('#006064', 'white'),
  },
  teal: {
    accent: buildColor('#b2dfdb', 'black'),
    light: buildColor('#a7ffeb', 'black'),
    base: buildColor('#009688', 'white'),
    dark: buildColor('#004d40', 'white'),
  },
  green: {
    accent: buildColor('#c8e6c9', 'black'),
    light: buildColor('#b9f6ca', 'black'),
    base: buildColor('#4caf50', 'white'),
    dark: buildColor('#1b5e20', 'white'),
  },
  yellow: {
    accent: buildColor('#fff9c4', 'black'),
    light: buildColor('#ffff8d', 'black'),
    base: buildColor('#ffdc40', 'white'),
    dark: buildColor('#f57f17', 'white'),
  },
  orange: {
    accent: buildColor('#ffe0b2', 'black'),
    light: buildColor('#ffd180', 'black'),
    base: buildColor('#ff9800', 'white'),
    dark: buildColor('#e65100', 'white'),
  },
  red: {
    accent: buildColor('#ffcdd2', 'black'),
    light: buildColor('#ff8a80', 'black'),
    base: buildColor('#f44336', 'white'),
    dark: buildColor('#b71c1c', 'white'),
  },
  pink: {
    accent: buildColor('#f8bbd0', 'black'),
    light: buildColor('#ff80ab', 'black'),
    base: buildColor('#e91e63', 'white'),
    dark: buildColor('#880e4f', 'white'),
  },
  deepPurple: {
    accent: buildColor('#d1c4e9', 'black'),
    light: buildColor('#b388ff', 'black'),
    base: buildColor('#673ab7', 'white'),
    dark: buildColor('#311b92', 'white'),
  },
  grey: {
    accent: buildColor('#f5f5f5', 'black'),
    light: buildColor('#bdbdbd', 'black'),
    base: buildColor('#9e9e9e', 'white'),
    dark: buildColor('#212121', 'white'),
  },
});

export const WHITE_FONT_VARIANT_NAMES = Object.freeze(['base', 'dark']);
export const PRIMARY_COLORS = Object.freeze(Object.keys(COLORS));
export const COLOR_TONES = Object.freeze(Object.keys(COLORS[Object.keys(COLORS)[0]]));
export const HEX_COLORS = PRIMARY_COLORS.map(color => COLORS[color].base.background);

export const WORKSPACE_SIGNUP_COLORS = Object.freeze({
  primary: '#FD8C41',
  accent: VUETIFY_COLORS.grey.darken2,
  secondary: '#022140',
  info: VUETIFY_COLORS.blue.base,
  warning: VUETIFY_COLORS.amber.base,
  error: VUETIFY_COLORS.deepOrange.accent3,
  success: VUETIFY_COLORS.lightGreen.base,
});
