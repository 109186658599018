import { UNSENT_VIEW_ATTRIBUTES, VIEW_TYPES } from '~/assets/javascript/constants';
import { cloneDeep, isEmpty, omit } from 'lodash';
import { fetchFieldsIdsFromPageStateComponent } from '~/assets/javascript/utils/page-state-components';
import { enrichViewData, extractViewRecords } from '~/assets/javascript/utils';
import { safeInsertValueInObjectPath } from '~/assets/javascript/utils/object';

export const state = () => ({
  view: {},
  views: [],
  editingView: {},
  permissions: [],
  selectedComponentKey: 'general',
  locale: 'en',
  isSaving: false,
});

const NON_NULLABLE_STATE_KEYS = [
  'cover_image',
  'profile_image',
  'subtitle',
  'title',
];

const refreshFieldIds = (editingView) => {
  editingView.fields_ids = fetchFieldsIdsFromPageStateComponent(editingView.page_state_components);
};

const cleanUpPageStateComponents = (editingView) => {
  if (!editingView.page_state_components) { return; }

  Object.keys(editingView.page_state_components).forEach((key) => {
    Object.values(editingView.page_state_components[key]).forEach((pageStateComponent) => {
      if (!pageStateComponent) { return; }

      NON_NULLABLE_STATE_KEYS.forEach((nullableKey) => {
        if (pageStateComponent[nullableKey] === null) {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete pageStateComponent[nullableKey];
        }
      });
    });
  });
};

const loadSubviews = (view, subviews = []) => {
  (view.subviews || []).forEach((subview) => {
    subviews.push(subview);
    loadSubviews(subview, subviews);
  });

  return subviews;
};

export const getters = {
  everyonePermissions: ({ permissions }) => permissions.filter(({ everyone }) => everyone),
  sharedWithEveryone: (_, { everyonePermissions }) => Boolean(everyonePermissions.length),
  userGroupPermissions: ({ permissions }) => permissions.filter(({ grantee_type: granteeType }) => granteeType === 'UserGroup'),
  userPermissions: ({ permissions }) => permissions.filter(({ grantee_type: granteeType }) => granteeType === 'User'),
  editingViewState: ({ editingView, selectedComponentKey }) => {
    if (isEmpty(editingView)) return { component: {} };

    if (selectedComponentKey === 'general') {
      return {
        ...editingView,
        component: { key: 'general' },
      };
    }

    const selectedComponent = VIEW_TYPES[editingView.page_type].components.find(({ key }) => key === selectedComponentKey);

    if (selectedComponent) {
      return {
        ...editingView.page_state_components[selectedComponent.key],
        component: selectedComponent,
      };
    }

    return editingView;
  },
  liveEdit: (_, { layoutOptions }) => layoutOptions.liveEdit,
  viewFields: ({ editingView }) => editingView.fields,
  hasChanges: ({ view }) => view.has_changes,
  hasGroup(_state, { layoutOptions, groupFieldId }) {
    return !!(layoutOptions.canGroupDataByColumn && groupFieldId);
  },
  layoutOptions: ({ editingView }) => editingView.layoutOptions || {},
  groupFieldId: ({ editingView }) => editingView.page_state_components?.index?.main_section?.column_group_by?.field_id,
  isPublic({ editingView }) {
    return Boolean(editingView.view_public);
  },
  findEditingSubview: (_, { subviews }) => (viewId, fieldId) => subviews.find(
    subview => subview.metadata.parent_view_id === viewId
      && subview.metadata.from_field_id === fieldId,
  ),
  subviews({ editingView }) {
    return loadSubviews(editingView);
  },
  unarchivedViews({ views }) {
    return views.filter(view => !view.archived_at);
  },
};

export const mutations = {
  setIsSaving(state, value) {
    state.isSaving = value;
  },
  setLocale(state, value) {
    state.locale = value;
  },
  setView(state, payload) {
    const layoutOptions = VIEW_TYPES[payload.page_type].builderOptions;

    state.view = payload;
    state.editingView = payload;
    state.editingView.layoutOptions = layoutOptions;
  },
  updateViewData(state, payload) {
    const viewIsAlreadyStored = state.views.some(view => view.id === payload.id);

    if (viewIsAlreadyStored) {
      state.views = state.views.map((view) => {
        if (view.id === payload.id) {
          return payload;
        }

        return view;
      });
    } else {
      state.views = [...state.views, payload];
    }
  },
  setViews(state, payload) {
    state.views = payload;
  },
  storeNewView(state, payload) {
    state.views = [...state.views, payload];
  },
  setEditingView(state, payload) {
    state.editingView = payload;
    state.editingView.layoutOptions = VIEW_TYPES[payload.page_type].builderOptions;
    state.editingView.has_changes = true;
  },
  setEditingViewHasChanges(state) {
    state.editingView.has_changes = true;
  },
  setPermissions(state, payload) {
    state.permissions = payload;
  },
  resetEditingView(state) {
    state.editingView = state.view;
    state.editingView.layoutOptions = VIEW_TYPES[state.view.page_type].builderOptions;
  },
  setSelectedComponentKey(state, key) {
    state.selectedComponentKey = key;
  },
  setEditingViewState(state, payload) {
    if (payload.component.key === 'general') {
      state.editingView = {
        ...state.editingView,
        ...omit(payload, ['component']),
      };

      return;
    }

    state.editingView = {
      ...state.editingView,
      page_state_components: {
        ...state.editingView.page_state_components,
        [payload.component.key]: {
          ...state.editingView.page_state_components[payload.component.key],
          ...omit(payload, ['component']),
        },
      },
    };

    refreshFieldIds(state.editingView);
    cleanUpPageStateComponents(state.editingView);
  },
  cleanEditingView(state) {
    state.view = {};
    state.editingView = {};
    state.permissions = [];
    state.selectedComponentKey = 'general';
  },
  setEditingViewInfoComponentColumnGroupBy(state, component) {
    safeInsertValueInObjectPath(
      state,
      'editingView.page_state_components.index.main_section.column_group_by',
      component,
    );
  },
  setEditingViewInfoComponentRowGroupBy(state, component) {
    safeInsertValueInObjectPath(
      state,
      'editingView.page_state_components.index.main_section.row_group_by',
      component,
    );
  },
};

export const actions = {
  async loadBuilderView({ commit, dispatch }, { viewId } = { viewId: undefined }) {
    const { $asyncDataManager, $errorRescue } = useNuxtApp();
    const loadMethod = () => dispatch('loadViewAndSandboxView', viewId);

    const onLoad = async ({ view, sandboxView }) => {
      commit('records/reset', null, { root: true });
      await dispatch('applyViewData', { view, sandboxView });
    };

    const onLoadError = (error) => {
      $errorRescue(this, error, 'builderView/loadBuilderView');
    };

    return $asyncDataManager.load({
      loadMethod,
      onLoad,
      onLoadError,
      loadId: `view__${viewId}`,
    });
  },
  applyViewData({ commit, dispatch, state }, { view, sandboxView }) {
    const { $i18n } = useNuxtApp();
    const { records, view: publishedViewData } = extractViewRecords(view);

    commit('updateViewData', publishedViewData);

    if (publishedViewData.page_data_default) {
      if (state.view.id !== publishedViewData.id) commit('records/resetViewFiltersAndRequiredFields', null, { root: true });

      commit('setView', publishedViewData);
      commit('records/loadRecordsByView', { ...publishedViewData, records }, { root: true });
      dispatch('view/fakeLoadBuilderView', publishedViewData, { root: true });

      return;
    }

    const { records: sandboxRecords, view: sandboxViewData } = extractViewRecords(sandboxView);

    // reset filters only if the new view is different from the current one
    // when opening a record from an index of records the view will be loaded again
    // and the filters should not be reset, because it is loaded asynchronously and can be overrided
    if (state.view.id !== sandboxView.id) commit('records/resetViewFiltersAndRequiredFields', null, { root: true });

    commit('setLocale', $i18n.locale);
    commit('setView', sandboxViewData);
    dispatch('view/fakeLoadBuilderView', publishedViewData, { root: true });
    commit('records/loadRecordsByView', { records: sandboxRecords, ...sandboxViewData }, { root: true });
  },
  async loadViews({ commit, rootGetters }) {
    const { $api } = useNuxtApp();
    const data = await $api.$get('/builder/views');

    const categories = rootGetters['workspace/categoryHierarchy'];
    const enrichedViews = enrichViewData(data, categories);

    return commit('setViews', enrichedViews);
  },
  async loadViewPermissions({ commit, state }) {
    const { $api } = useNuxtApp();

    const params = {
      params: {
        object_type: 'View',
        object_id: state.editingView.id,
      },
    };

    const data = await $api.$get('/permissions', params);

    return commit('setPermissions', data);
  },
  async loadViewAndSandboxView(_, viewId) {
    const { $api } = useNuxtApp();
    const view = await $api.$get(`/builder/views/${viewId}`);

    // sometimes the default sheet view can be used here and it has no sandbox view
    if (view.page_data_default) return { view };

    const sandboxView = await $api.$get(`/builder/views/${view.sandbox_view_id}`);

    return { view, sandboxView };
  },
  async saveEditingView({ commit, dispatch, state, rootState }, { errorMessage = null } = {}) {
    const { $api, $asyncDataManager, $errorRescue } = useNuxtApp();
    const currentViewId = state.editingView.id;
    const currentPublishedViewId = rootState.view.view.id;

    const onSave = () => {
      // it can be different when the user changes the page before the save is finished
      if (currentViewId !== state.editingView.id) return;

      commit('setEditingViewHasChanges');
      commit('setView', state.editingView);
    };

    const loadMethod = () => {
      // it can be different when the user changes the page before the save is finished and the load is called
      if (currentPublishedViewId !== rootState.view.view.id) return Promise.resolve({});

      return dispatch('loadViewAndSandboxView', currentPublishedViewId);
    };

    const onLoad = async ({ view, sandboxView }) => {
      // it can be different when the user changes the page before the load is finished
      if (currentViewId !== state.editingView.id) return;

      if (window) window.dispatchEvent(new Event('global:beforeViewRefresh'));
      await dispatch('applyViewData', { view, sandboxView });
      if (window) window.dispatchEvent(new Event('global:afterViewRefresh'));
    };

    // TODO: improve this to update only the difference between the original view and the editing view
    // It cannot be done yet because the endpoint always expect the entire view object to be sent
    const payload = omit(state.editingView, UNSENT_VIEW_ATTRIBUTES);

    const saveMethod = () => $api.$patch(`/builder/views/${currentViewId}`, { body: payload });

    const beforeViewChangeCopy = cloneDeep(state.view);

    const onSaveError = (error) => {
      $errorRescue(this, error, errorMessage || 'builderView/saveEditingView');

      // it can be different when the user changes the page before the save is finished
      if (currentViewId !== state.editingView.id) return;

      commit('setView', beforeViewChangeCopy);
    };

    commit('setIsSaving', true);

    return $asyncDataManager.save({
      saveMethod,
      loadMethod,
      onSave,
      onLoad,
      onSaveError,
      loadId: `view__${currentViewId}`,
      saveId: `view__${currentViewId}`,
    }).then(() => {
      commit('setIsSaving', false);
    });
  },
  async addPermissions({ commit, state, dispatch }, permissions) {
    const data = await dispatch('addPermissionsRequest', permissions);
    commit('setPermissions', [...state.permissions, ...data.flat()]);
    commit('setEditingViewHasChanges');
  },
  async addPermissionsRequest(_, permissions) {
    const { $api } = useNuxtApp();
    return Promise.all(permissions.map(permission => $api.$post('/permissions', { body: permission })));
  },
  async removePermissions({ commit, state, dispatch, rootState }, permissionIds) {
    const { $apiClient } = useNuxtApp();
    await Promise.all(permissionIds.map(id => $apiClient.permissions.delete(id)));
    dispatch('loadBuilderView', { viewId: rootState.view.view.id });
    commit('setPermissions', state.permissions.filter(({ id }) => !permissionIds.includes(id)));
    commit('setEditingViewHasChanges');
  },
  async publish({ state, commit, dispatch, rootState }) {
    const view = await dispatch('publishViewRequest', state.editingView.id);

    commit('setView', view);
    await dispatch('loadBuilderView', { viewId: rootState.view.view.id });
  },
  async publishViewRequest(_, viewId) {
    const { $api } = useNuxtApp();
    return $api.$post(`/builder/views/${viewId}/publish`);
  },
  async discard({ state, commit, dispatch, rootState }) {
    const { $api } = useNuxtApp();
    const view = await $api.$post(`/builder/views/${state.editingView.id}/discard_changes`);

    commit('setView', view);

    await Promise.all([
      dispatch('loadBuilderView', { viewId: rootState.view.view.id }),
      dispatch('loadViewPermissions'),
    ]);
  },
  async deleteView({ state, commit, rootState }, viewId) {
    const { $api } = useNuxtApp();
    await $api.$delete(`/builder/views/${viewId}`);

    const updatedViews = state.views.filter(view => view.id !== viewId);
    commit('setViews', updatedViews);

    if (rootState.view.view.id === viewId) {
      commit('cleanEditingView');
    }
  },
};

export const namespaced = true;
