<i18n lang="yaml">
pt:
  askEmailStep:
    title: "Esqueci minha senha"
    subtitle: "Informe seu email para receber o link de redefinição de senha"
  resetPasswordStep:
    title: 'Redefinir senha'
    subtitle: 'Informe sua nova senha'
en:
  askEmailStep:
    title: "Forgot your password?"
    subtitle: "You will receive an email with the link to access your workspaces"
  resetPasswordStep:
    title: 'Redefine password'
    subtitle: 'Enter your new password'
</i18n>
<template>
  <AuthLogin
    :layout-attrs="{
      title: t(`${step}.title`),
      subtitle: t(`${step}.subtitle`),
      hideCaptchaDisclaimer: true,
      hideSignUpDisclaimer: true
    }"
    :modules="[
      'resetPassword',
    ]"
  />
</template>

<script lang="ts">
import AuthLogin from '~/components/auth/login';

export default defineComponent({
  name: 'TenantResetPassword',
  components: { AuthLogin },
  setup() {
    definePageMeta({
      layout: 'public',
    });

    return {
      t: useI18n().t,
    };
  },
  computed: {
    step() {
      return this.$route.query.token ? 'resetPasswordStep' : 'askEmailStep';
    },
  },
});
</script>
