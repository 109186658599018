<template>
  <label
    class="deck-input-label"
    v-bind="$attrs"
  >
    <slot />
  </label>
</template>

<script>
export default {
  name: 'DeckInputLabel',
};
</script>

<style>
.deck-input-label {
  display: inline-flex;
  font-weight: 500;
  font-size: var(--deck-label-font-size);
  line-height: var(--deck-label-line-height);
  color: var(--z-color-label) !important;
  letter-spacing: var(--deck-label-letter-spacing);
}
</style>
