/* eslint-disable no-await-in-loop */
export const waitUntil = async (fn, time = 1000, wait = null) => {
  const startTime = new Date().getTime();

  while (true) {
    const result = await fn();

    if (result) { return result; }

    if (wait && new Date().getTime() - startTime > wait) {
      throw new Error('Max wait reached');
    } else {
      await new Promise((resolve) => { setTimeout(resolve, time); });
    }
  }
};

export default defineNuxtPlugin({
  name: 'wait-until',
  setup() {
    return {
      provide: { waitUntil },
    };
  },
});
