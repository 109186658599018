export const entityDependencies = ($http: HttpApi) => ({
  listByTargetIdAndType: async (targetId: string, targetType: string) => $http.$get(
    '/builder/entity_dependencies',
    { params: { target_id: targetId, target_type: targetType } },
  ),
  listBySourceIdAndType: async (sourceId: string, sourceType: string) => $http.$get(
    '/builder/entity_dependencies',
    { params: { source_id: sourceId, source_type: sourceType } },
  ),
});
