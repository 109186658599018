<template>
  <v-chip
    class="deck-select-input-item"
    :class="classes"
    :color="customColor ? '' : 'primary'"
    :style="value.style"
    :link="isALink"
    :href="isALink ? href : undefined"
    :target="isALink ? '_blank' : undefined"
    v-bind="$attrs"
    label
    @click="isALink ? $event.stopPropagation() : undefined"
  >
    <v-avatar
      v-if="value.image"
      small
      class="flex-shrink-0 mr-1"
    >
      <v-img :src="value.image" />
    </v-avatar>
    <deck-icon
      v-else-if="icon"
      size="small"
      class="flex-shrink-0 pr-1"
      :name="icon"
    />

    <deck-trimmer
      :class="{ 'text-decoration-underline': isALink }"
      :title="text"
      :text="text"
      :line-clamp="avoidTruncation ? 0 : 1"
      tag="span"
    />

    <deck-icon
      v-if="canRemove"
      class="flex-shrink-0 ml-1"
      :aria-label="$t('global.remove')"
      :title="$t('global.remove')"
      size="x-small"
      :color="customColor ? '' : 'grey-darken-1'"
      :style="value.style"
      name="fa-close"
      @click.stop="$emit('remove')"
    />
  </v-chip>
</template>

<script>
import { objectShouldHave } from 'vue-prop-validation-helper';
import { isAUrl, parseUrlFromString } from '~/assets/javascript/utils';
import DeckTrimmer from '~/deck/trimmer';

const LINK_ICON = 'fa-solid fa-arrow-up-right-from-square';

export default {
  name: 'DeckSelectInputItem',
  components: {
    DeckTrimmer,
  },
  props: {
    canRemove: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/no-restricted-props
    value: {
      type: Object,
      required: true,
      validate: objectShouldHave(['text', 'value']),
    },
    avoidTruncation: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['remove'],
  computed: {
    customColor() {
      return this.value.style?.color;
    },
    href() {
      if (!this.isALink) return null;

      return parseUrlFromString(this.text);
    },
    text() {
      return this.value?.text || '';
    },
    isALink() {
      return isAUrl(this.text);
    },
    icon() {
      if (this.value.icon) return this.value.icon;
      if (this.isALink) return LINK_ICON;

      return null;
    },
    classes() {
      return {
        'deck-select-input-item--avoid-truncation': this.avoidTruncation,
        'deck-select-input-item--cursor-pointer': this.isALink || this.$attrs.onClick,
      };
    },
  },
};
</script>

<style lang="scss">
.deck-select-input-item {
  flex: 1 1 auto;
  max-width: fit-content;
  height: 24px !important;
  min-height: 24px;
  white-space: unset !important;
  padding: 0 8px !important;

  & > .v-chip__content {
    display: inline-flex;
    align-items: center;
    overflow: hidden;
  }
}

.deck-select-input-item--avoid-truncation {
  height: auto !important;
}

.deck-select-input-item--cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}
</style>
