export default defineNuxtPlugin({
  name: 'rollbar',
  dependsOn: ['store'],
  setup({ $store, $rollbar }) {
    if (process.env.NODE_ENV === 'production') {
      $rollbar.configure({
        transform: (payload) => {
          payload.workspace_id = $store?.state?.workspace?.workspace?.id;
          payload.workspace_slug = $store?.state?.workspace?.workspace?.slug;
        },
      });
    }
  },
});
