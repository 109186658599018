<template>
  <v-switch
    :id="computedId"
    :disabled="disabled"
    :model-value="modelValue"
    :false-value="falseValue"
    :true-value="trueValue"
    :aria-label="ariaLabel"
    :class="classes"
    class="deck-switch"
    hide-details
    inset
    density="compact"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template #label>
      <slot>
        <deck-label
          v-if="label"
          :size="kind === 'form' ? 'dense' : 'small'"
          :text="label"
          class="ml-2"
        />
      </slot>
    </template>
  </v-switch>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

/**
 * A wrapper around Vuetify's `v-switch` component with common props
 * and provides a better developer experience.
 * See https://v2.vuetifyjs.com/en/api/v-switch/
 */
export default defineComponent({
  name: 'DeckSwitch',
  components: {
    DeckLabel: defineAsyncComponent(() => import('~/deck/label')),
  },
  props: {
    /**
     * The id of the switch input.
     * @type {string}
     */
    id: {
      type: String,
      default: undefined,
    },
    /**
     * The input modelValue of the switch.
     * @type {any}
     * @required
     */
    modelValue: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: true,
    },

    /**
     * The kind of the checkbox.
     * @type {'form' | 'settings' | string}
     * @default 'form'
     */
    kind: {
      type: String,
      default: 'form',
    },

    /**
     * Determines whether the switch is disabled.
     * @type {boolean}
     * @default false
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * The value to use when the switch is in the "off" state.
     * @type {any}
     * @default false
     */
    falseValue: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: false,
    },

    /**
     * The value to use when the switch is in the "on" state.
     * @type {any}
     * @default true
     */
    trueValue: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: true,
    },

    /**
     * The label to display alongside the switch.
     * @type {string}
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * The aria-label html attribute.
     * @type {string}
     */
    ariaLabel: {
      type: String,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      uid: uuidv4(),
    };
  },
  computed: {
    computedId() {
      return this.id || `deck-select-${this.uid}`;
    },
    classes() {
      return {
        'deck-switch--on': this.modelValue === this.trueValue,
        'deck-switch--off': this.modelValue === this.falseValue,
        'deck-switch--disabled': this.disabled,
      };
    },
  },
});
</script>
<style lang="scss">
.deck-switch {
  --deck-switch-border-color: var(--z-input-border-color);

  .v-selection-control__wrapper {
    border-radius: var(--z-border-radius-pill);
    outline: 1px solid var(--deck-switch-border-color);
    outline-offset: -1px;
  }

  .v-selection-control--density-compact {
    --v-selection-control-size: 20px;
  }

  .v-switch__track {
    opacity: 0.32;
    height: var(--v-selection-control-size);
    min-width: 36px;
  }

  .v-switch__thumb {
    width: 12px;
    height: 12px;
    outline: 1px solid var(--deck-switch-border-color);
  }

  // hide input hover style from vuetify
  .v-selection-control__input::before {
    display: none !important;
  }

  .v-label {
    padding-left: 0;
  }

  &:not(.deck-switch--disabled):is(:hover, &:focus-visible) {
    --deck-switch-border-color: var(--z-input-border-color-highlight);
  }
}

.deck-switch--on {
  .v-selection-control__input {
    transform: translateX(8px) !important;
  }

  .v-switch__track, .v-switch__thumb {
    background-color: rgb(var(--v-theme-primary)) !important;
  }
}

.deck-switch--off {
  .v-selection-control__input {
    transform: translateX(-8px) !important;
  }

  .v-switch__thumb {
    transform: none;
  }
}

.deck-switch--disabled {
  pointer-events: all !important; // Allow wrapper clicks
  cursor: default;

  .v-input__control {
    pointer-events: none; // But not the switch itself
  }
}
</style>
