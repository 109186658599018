import { isEqual } from 'lodash';
import { isUuidV4 } from '~/assets/javascript/utils/string';
import {
  SubviewHasNoInsertPermissionError,
  SubviewHasNoUpdatePermissionError,
} from './errors';

const isNewLink = ({ fromRecordId, fromFieldId, foreignRecordId, rootGetters }) => {
  const fromRecord = rootGetters['records/rawRecordById'](fromRecordId);

  // If the record does not exist in rawRecordById,
  // it means that the record was not edited previously,
  // so it cannot be a new link
  if (!fromRecord) return false;

  return (fromRecord[fromFieldId] || []).every(({ foreign_record_id: id }) => id !== foreignRecordId);
};

const transformValueByFieldType = ({
  fromRecordId,
  fieldId,
  value,
  type,
  subview,
  rootGetters,
  getRecordByIdGetter,
}) => {
  switch (type) {
    case 'Date':
    case 'DateTime':
      return value?.value;
    case 'Attachment':
      return (value || []).map(({ attachment_id: attachmentId }) => attachmentId);
    case 'CoverImage':
      return (value || []).map(({ cover_image_id: coverImageId }) => coverImageId);
    case 'Link':
      if (subview) {
        const draftRecordValue = (value || []).map(({ foreign_record_id: foreignRecordId }) => {
          const record = rootGetters[getRecordByIdGetter](foreignRecordId) || {};

          // eslint-disable-next-line no-use-before-define
          const recordData = transformRecordData(
            record,
            subview.fields,
            subview.id,
            rootGetters,
            getRecordByIdGetter,
          );

          if (record.isNew) {
            recordData.operation_type = 'insert';
          } else if (rootGetters['records/draftRecordFieldsChangedById'](foreignRecordId)) {
            recordData.operation_type = subview.permit_record_update ? 'update' : 'keep';
          } else if (isNewLink({ fromRecordId, foreignRecordId, rootGetters, fromFieldId: fieldId })) {
            recordData.operation_type = 'link';
          } else {
            recordData.operation_type = 'keep';
          }

          return recordData;
        });

        if (!subview.permit_record_insert) {
          const hasAnyNewRecord = draftRecordValue.some(recordValue => recordValue.operation_type === 'insert');

          if (hasAnyNewRecord) throw new SubviewHasNoInsertPermissionError();
        }

        if (!subview.permit_record_update) {
          const hasAnyUpdatedRecord = draftRecordValue.some(recordValue => recordValue.operation_type === 'update');

          if (hasAnyUpdatedRecord) throw new SubviewHasNoUpdatePermissionError();
        }

        const unlinkedRecords = (rootGetters['records/rawRecordById'](fromRecordId)?.[fieldId] || [])
          .filter(({ foreign_record_id: foreignRecordId }) => draftRecordValue.every(({ id }) => id !== foreignRecordId))
          .map(({ foreign_record_id: foreignRecordId }) => ({ id: foreignRecordId, operation_type: 'unlink' }));

        return [
          ...draftRecordValue,
          ...unlinkedRecords,
        ];
      }

      return (value || []).map(({ foreign_record_id: foreignRecordId }) => foreignRecordId);
    case 'Select':
    case 'MultipleSelect':
      return (value || []).map(({ select_option_id: selectOptionId }) => selectOptionId);
    case 'Number':
      if (!value?.value && value?.value !== 0) return null;
      return value;
    default:
      return value;
  }
};

export function transformRecordData(recordData, fields, viewId, rootGetters, getRecordByIdGetter) {
  const data = { id: recordData.id };

  fields.forEach((field) => {
    const { id: fieldId, type, calculated } = field;

    // Calculated fields are not editable
    if (calculated) return;
    // Lookup and Formula fields are not editable
    if (['Lookup', 'Formula'].includes(type)) return;
    // User field is not editable through this interface, except for new records
    if (type === 'User' && !recordData.isNew) return;

    const value = recordData[fieldId];

    const subview = type === 'Link' ? rootGetters['view/findSubview'](viewId, fieldId) : null;

    data[fieldId] = transformValueByFieldType({
      fromRecordId: recordData.id,
      fieldId,
      value,
      type,
      subview,
      rootGetters,
      getRecordByIdGetter,
    });
  });

  return data;
}

export const transformDraftRecordData = (recordData, fields, viewId, rootGetters) => transformRecordData(recordData, fields, viewId, rootGetters, 'records/draftRecordById');

export const transformRawRecordData = (recordData, fields, viewId, rootGetters) => transformRecordData(recordData, fields, viewId, rootGetters, 'records/rawRecordById');

export const viewHasRequiredFields = view => view.fields_required.length > 0 || view.subviews?.some(viewHasRequiredFields) || false;

export const buildValuesPatchPayload = (rawRecord, draftRecord, view, submitMode = null) => {
  const result = {};

  const { fields_required: requiredFields, subviews, page_type: pageType } = view;

  const calculatedSubmitMode = submitMode || (pageType === 'Form' ? 'submit' : 'autosave');

  const isForeignRecord = view.metadata?.parent_view_id;

  Object.entries(draftRecord).forEach(([key, value]) => {
    // If the key is not an uuid, it's something like 'id', 'operation_type', etc.
    // Those keys are needed to the server if it's a foreign record
    if (!isUuidV4(key)) {
      if (isForeignRecord) {
        result[key] = value;
      }

      return;
    }

    const subview = subviews?.find(({ metadata: { from_field_id: linkFieldId } }) => linkFieldId === key);

    // I recently tried not to use the comparison between raw and draft records and use
    // draftRecordChangedFieldsMapping instead, but it didn't work as expected.
    // Mostly because of minicard reasons: if you remove a minicard link for example,
    // it won't be in the draftRecordChangedFieldsMapping. So this state isn't 100% reliable
    // when it comes to foreign records, it's a bit tricky to handle
    // It was better to keep using the comparison between raw and draft records.
    const valueChanged = !isEqual(value, rawRecord?.[key]);

    // When there are subviews, we need to build the whole payload for each of the foreign records
    if (subview) {
      // For subviews we need to check if the subview has required fields somewhere in the
      // stack (If a subview of the subview has a required field, and so on...)
      // If so, we need to send every foreign record that is part of the stack path to the server
      if (valueChanged || (calculatedSubmitMode === 'submit' && viewHasRequiredFields(subview))) {
        // When the subview has no insert or update permission the record data is not sent to the server, only the foreign_record_id's
        const valueHasForeignRecordUuids = value.some(isUuidV4);

        if (valueHasForeignRecordUuids) {
          result[key] = value;
        } else {
          result[key] = value.map((foreignRecord) => {
            const foreignRawRecord = rawRecord?.[key]?.find(({ id }) => id === foreignRecord.id);

            return buildValuesPatchPayload(foreignRawRecord, foreignRecord, subview, calculatedSubmitMode);
          });
        }
      }

      return;
    }

    const isRequiredField = requiredFields.includes(key);

    if ((isRequiredField && calculatedSubmitMode === 'submit') || valueChanged) {
      result[key] = value;
    }
  });

  return result;
};
