import { createStore } from 'vuex';
import * as modules from '~/store';

export default defineNuxtPlugin({
  name: 'store',
  setup(nuxtApp) {
    const store = createStore({ modules });

    nuxtApp.vueApp.use(store);

    return {
      provide: { store },
    };
  },
});
