<i18n lang="yaml">
pt:
  failedToUploadImage: "Falha ao enviar imagem"
  loading: "Carregando..."
  mentionButtonsTexts:
    addChain: 'Adicionar propriedade'
    addChildField: 'Adicionar campo da base de dados conectada'
    addField: 'Adicionar campo desta base'
    addFunction: 'Adicionar função'
    addStep: 'Adicionar resultado de ação anterior'
  tooltipTexts:
    insertLink: '"Insira o link"'
    visitUrl: '"Visitar a URL"'
    remove: '"Remover"'
    edit: '"Editar"'
    save: '"Salvar"'
  writeHerePlaceholder: 'Escreva aqui...'
en:
  failedToUploadImage: "Failed to upload image"
  loading: "Loading..."
  mentionButtonsTexts:
    addChain: 'Add property'
    addChildField: 'Add connected database field'
    addField: 'Add field from this database'
    addFunction: 'Add function'
    addStep: 'Add previous action result'
  tooltipTexts:
    insertLink: '"Insert link"'
    visitUrl: '"Visit URL"'
    remove: '"Remove"'
    edit: '"Edit"'
    save: '"Save"'
  writeHerePlaceholder: 'Write here...'
</i18n>
<template>
  <div
    v-if="!forceRerender"
    class="deck-text-wizard-input"
    :class="classes"
  >
    <portal to="wizard-counter">
      <v-counter
        v-if="maxCounter"
        active
        :max="maxCounter"
        :value="counterValue"
      />
    </portal>

    <deck-label
      v-if="label"
      :input-ref="labelId"
      :text="label"
      :size="dense ? 'dense' : 'small'"
    />

    <v-progress-linear
      v-if="loading"
      absolute
      height="4"
      indeterminate
      rounded
    />

    <v-input
      :id="labelId"
      ref="input"
      class="deck-text-wizard-input__editor-input"
      hide-details="auto"
      :hint="hint"
      :persistent-hint="persistentHint"
      :rules="rules"
      :model-value="quillText"
      :error-messages="errorMessages"
      :style="editorStyle"
    >
      <QuillEditor
        ref="editor"
        class="deck-text-wizard-input__editor"
        data-testid="quill-editor"
        :toolbar="quillToolbarConfig"
        :content="quillText"
        content-type="html"
        :modules="customQuillModules"
        :placeholder="quillPlaceholder"
        :read-only="!editable"
        :options="{ modules: { history: { userOnly: true } } }"
        @update:content="parseQuillText('input', $event)"
        @focus="$emit('focus')"
        @blur="parseQuillText('blur', $event)"
      />

      <!--
        TODO: We should be able to inject these buttons directly onto quill
        toolbar through its API for proper layouting.
        In order to position these as they currently are, we had to use a very
        hacky way of doing it with display: contents, flex order, and sibling
        selectors
      -->
      <div
        v-if="mentionEnabled && editable"
        class="deck-text-wizard-input__bottom-toolbar"
      >
        <template v-if="!customMentionsMenu">
          <deck-button
            v-for="char in mentionButtons"
            :key="char"
            :text="mentionsButtonDefinitions[char].text"
            :icon="mentionsButtonDefinitions[char].icon"
            kind="ghost"
            size="small"
            color="controls"
            is-ready
            @click="openMentionMenu(char)"
          />
        </template>

        <template v-else>
          <slot name="mentions-menu" />
        </template>
      </div>

      <template #message="{ message }">
        <div class="deck-text-wizard-input__input-details">
          <div class="flex-grow-1">
            {{ message }}
          </div>
          <portal-target name="wizard-counter" />
        </div>
      </template>
    </v-input>
    <div
      v-if="maxCounter && !hasError && !hint"
      class="deck-text-wizard-input__input-details mt-2 px-2"
    >
      <v-spacer />
      <portal-target name="wizard-counter" />
    </div>
  </div>
</template>
<script>
import '@zazos-team/vue-quill/dist/vue-quill.snow.css';
import { QuillEditor } from '@zazos-team/vue-quill';
import Mention from 'quill-mention';
import QuillMarkdown from 'quilljs-markdown';
import QuillResizeImage from 'quill-resize-image';
import ImageUploader from 'quill-image-uploader';
import 'quill-paste-smart';
import {
  formatFormulaToQuillValidHTML,
  formatMarkdownToQuillValidHTML,
  MENTION_CHARS_MAP,
  MENTION_ICONS_MAP,
  renderMentionsList,
  replaceMentionNotationsToTag,
  replaceMentionTagsToNotation,
  FileUploader,
} from '~/assets/javascript/utils';

export default {
  name: 'DeckTextWizardInput',
  components: {
    QuillEditor,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: null,
    },
    comfortReadMode: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    limitedWidth: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/no-unused-properties
    highlightWhenFocused: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Array,
      default: () => [],
    },
    mentions: {
      type: Object,
      default: () => ({}),
      validator: (value) => {
        const keysAreValid = Object.keys(value).every(key => Object.keys(MENTION_CHARS_MAP).includes(key));
        const itemsIsAnArray = Object.values(value).every(obj => Array.isArray(obj.items));

        return keysAreValid && itemsIsAnArray;
      },
    },
    customMentionsMenu: {
      type: Boolean,
      default: false,
    },
    contextSchema: {
      type: Object,
      default: () => ({}),
    },
    rules: {
      type: Array,
      default: () => [],
    },
    maxCounter: {
      type: Number,
      default: null,
    },
    outsideErrorMessages: {
      type: Array,
      default: () => [],
    },
    hint: { type: String, default: null },
    persistentHint: { type: Boolean, default: false },
    label: { type: String, default: null },
    monospaced: { type: Boolean, default: false },
  },
  emits: ['update:modelValue', 'blur', 'focus', 'error'],
  setup() {
    const input = ref(null);

    return {
      t: useI18n().t,
      theme: useTheme(),
      input,
    };
  },
  data() {
    return {
      cachedMentions: {},
      forceRerender: false,
      internalValue: this.modelValue,
      disableValues: {
        // modules to disable
        toolbar: 'toolbar',
        markdown: 'markdown',
        mention: 'mention',
        // toolbar controls to disable
        bold: 'bold',
        italic: 'italic',
        strike: 'strike',
        header: 'header',
        blockquote: 'blockquote',
        codeBlock: 'code-block',
        list: 'list',
        indent: 'indent',
        link: 'link',
        image: 'image',
        video: 'video',
      },
      tooltipTextVars: {
        '--insert-link-text': this.t('tooltipTexts.insertLink'),
        '--visit-url-text': this.t('tooltipTexts.visitUrl'),
        '--edit-text': this.t('tooltipTexts.edit'),
        '--remove-text': this.t('tooltipTexts.remove'),
        '--save-text': this.t('tooltipTexts.save'),
      },
      uploadingImage: false,
      errorMessage: null,
      // eslint-disable-next-line vue/no-unused-properties
      quillMarkdown: null,
    };
  },
  computed: {
    customQuillModules() {
      return [
        { name: 'mention', module: Mention, options: this.quillMentionConfig }, // doc here: https://github.com/quill-mention/quill-mention
        { name: 'markdown', module: QuillMarkdown }, // doc here: https://github.com/cloverhearts/quilljs-markdown
        { name: 'resize', module: QuillResizeImage, options: this.quillResizeImageConfig }, // doc here: https://www.npmjs.com/package/quill-resize-module
        { name: 'image', module: ImageUploader, options: this.quillImageUploaderConfig },
      ].filter(({ name }) => !this.disable.includes(name));
    },
    hasError() {
      if (!this.input) return false;

      return this.input.isValid === false;
    },
    classes() {
      return {
        'deck-text-wizard-input--read-only': !this.editable,
        'deck-text-wizard-input--error': this.hasError,
        'deck-text-wizard-input--monospaced': this.monospaced,
        'deck-text-wizard-input--comfort': this.comfortReadMode,
        'deck-text-wizard-input--dense': this.dense,
        'deck-text-wizard-input--limited-width': this.limitedWidth,
        'deck-text-wizard-input--space-between-elements': this.markdownEnabled,
        'deck-text-wizard-input--no-toolbar': !this.hasVisibleToolbar,
      };
    },
    mentionsButtonDefinitions() {
      return {
        [MENTION_CHARS_MAP.field]: { text: this.t('mentionButtonsTexts.addField'), icon: MENTION_ICONS_MAP.field },
        [MENTION_CHARS_MAP.chain]: { text: this.t('mentionButtonsTexts.addChain'), icon: MENTION_ICONS_MAP.chain },
        [MENTION_CHARS_MAP.child_field]: { text: this.t('mentionButtonsTexts.addChildField'), icon: MENTION_ICONS_MAP.child_field },
        [MENTION_CHARS_MAP.step]: { text: this.t('mentionButtonsTexts.addStep'), icon: MENTION_ICONS_MAP.step },
        [MENTION_CHARS_MAP.formula_function]: { text: this.t('mentionButtonsTexts.addFunction'), icon: MENTION_ICONS_MAP.formula_function },
      };
    },
    loading() {
      return this.uploadingImage;
    },
    quillText() {
      if (this.markdownEnabled) {
        return formatMarkdownToQuillValidHTML(this.internalValue, this.$sanitize, [
          text => replaceMentionNotationsToTag(text, this.mentions, this.contextSchema, Object.values(this.cachedMentions)),
        ]);
      }

      return formatFormulaToQuillValidHTML(this.internalValue, this.$sanitize, [
        text => replaceMentionNotationsToTag(text, this.mentions, this.contextSchema, Object.values(this.cachedMentions)),
      ]);
    },
    editorStyle() {
      if (!this.height) return {};

      return {
        '--deck-text-wizard-input-editor-height': this.height,
        ...this.tooltipTextVars,
      };
    },
    labelId() {
      return `text-wizard-label-${this.$.uid}`;
    },
    counterValue() {
      return this.$markdownToText(this.internalValue).length;
    },
    errorMessages() {
      return this.errorMessage ? [this.errorMessage] : this.outsideErrorMessages;
    },
    quillPlaceholder() {
      if (!this.placeholder && this.editable) {
        return this.t('writeHerePlaceholder');
      }

      if (this.internalValue) {
        return undefined;
      }

      return this.placeholder;
    },
    quillToolbarConfig() {
      if (!this.editable || !this.toolbarEnabled) return [];

      const { bold, italic, strike, header, blockquote, codeBlock, list, indent, link, image, video } = this.disableValues;

      // doc here: https://quilljs.com/docs/modules/toolbar/
      const defaultToolbar = [
        [bold, italic, strike],
        [{ [header]: '1' }, { [header]: '2' }, blockquote, codeBlock],
        [{ [list]: 'ordered' }, { [list]: 'bullet' }, { [indent]: '-1' }, { [indent]: '+1' }],
        [link, image, video],
      ];

      return defaultToolbar
        .map(controlGroup => controlGroup.filter(control => this.controlIsEnabled(control)))
        .filter(controlGroup => controlGroup.length > 0);
    },
    quillResizeImageConfig() {
      if (!this.markdownEnabled || !(this.editable && this.imageEnabled)) return {};

      // doc here: https://github.com/cloverhearts/quilljs-markdown
      return {
        // doc here: https://github.com/kensnyder/quill-image-resize-module
        modules: ['Resize', 'DisplaySize'],
      };
    },
    quillMentionConfig() {
      if (!this.mentionEnabled) return {};

      // doc here: https://github.com/quill-mention/quill-mention
      return {
        allowedChars: /^[A-zÀ-ü\s]*$/,
        mentionContainerClass: `ql-mention-list-container v-theme--${this.theme.global.name.value}`,
        mentionDenotationChars: Object.values(MENTION_CHARS_MAP),
        positioningStrategy: 'fixed',
        spaceAfterInsert: false,
        dataAttributes: [
          'id',
          'value',
          'denotationChar',
          'link',
          'target',
          'disabled',
          'level',
        ],
        onSelect: (item, insertItem) => {
          this.cacheMentionItem(item);
          insertItem(item);
        },
        renderLoading: () => this.t('loading'),
        source: async (searchTerm, renderList, mentionChar) => {
          let beforeFrozenValue = this.internalValue || '';

          if (beforeFrozenValue.charAt(beforeFrozenValue.length - 1) === mentionChar) {
            beforeFrozenValue = beforeFrozenValue.slice(0, -1);
          }

          let frozenMentions;
          let afterFrozenValue;

          try {
            await nextTick(); // awaits this.modelValue to be updated with the new mentionChar
            await nextTick(); // need to wait 3 cycles
            await nextTick(); // need to wait 3 cycles

            frozenMentions = _cloneDeep(this.mentions);
            afterFrozenValue = this.internalValue || '';

            // We need to split afterFrozenValue to get the text before the cursor
            // comparing the beforeFrozenValue with afterFrozenValue we can get the text after the cursor
            const cursorPosition = Array.from(afterFrozenValue).findIndex((char, index) => beforeFrozenValue[index] !== char);
            const beforeCursorText = Array.from(afterFrozenValue).slice(0, cursorPosition + 1).join('') || '';

            await renderMentionsList(searchTerm, renderList, mentionChar, frozenMentions, beforeCursorText);
          } catch (error) {
            this.$rollbar.error(error, { ...error.errorData, searchTerm, mentionChar, mentions: frozenMentions, value: afterFrozenValue });
          }
        },
      };
    },
    quillImageUploaderConfig() {
      if (!this.imageEnabled) return {};

      return {
        upload: this.handleUploadImage,
      };
    },
    hasVisibleToolbar() {
      return !['toolbar', 'markdown', 'mention'].every(item => this.disable.includes(item));
    },
    mentionButtons() {
      const strategies = Object.keys(this.mentions);

      if (strategies.includes('field') || strategies.includes('step')) {
        ['chain', 'child_field'].forEach((strategy) => {
          if (!strategies.includes(strategy)) {
            strategies.push(strategy);
          }
        });
      }

      return Object.entries(MENTION_CHARS_MAP).filter(([strategy, _]) => strategies.includes(strategy)).map(([_, char]) => char);
    },
    markdownEnabled() {
      return !this.disable.includes(this.disableValues.markdown);
    },
    mentionEnabled() {
      return !this.disable.includes(this.disableValues.mention);
    },
    toolbarEnabled() {
      return !this.disable.includes(this.disableValues.toolbar);
    },
    imageEnabled() {
      return !this.disable.includes(this.disableValues.image);
    },
    quill() {
      return this.$refs.editor?.getQuill();
    },
  },
  watch: {
    modelValue(newValue) {
      if (newValue === this.internalValue) return;

      this.internalValue = newValue;
    },
    'theme.global.name.value': function onThemeChange() {
      this.forceRerender = true;

      this.$nextTick(() => {
        this.forceRerender = false;
      });
    },
    async editable() {
      // there is a problem that is not enabling the toolbar/editor when the editable prop is changed
      // it forces the rerender of the component to fix it
      this.forceRerender = true;
      await nextTick();
      this.forceRerender = false;
    },
  },
  mounted() {
    if (this.quill) {
      this.quill.root.setAttribute('aria-labelledby', this.labelId);
    }
  },
  beforeUnmount() {
    this.quill?.getModule('mention')?.hideMentionList();
  },
  methods: {
    cacheMentionItem(item) {
      this.cachedMentions[item.id] = { ...item };
    },
    openMentionMenu(char) {
      this.quill.getModule('mention').openMenu(char);
    },
    // eslint-disable-next-line vue/no-unused-properties
    insertOption(option) {
      // onSomethingChange from quill-mention must be called in order to
      // correctly track cursor position after a line break. This method calls
      // quill.getSelection() and updates the cursor position according to the selected range in quill.
      // Without this fix, mentions would be inserted one position behind the cursor's actual position
      // after a newline.

      // https://github.com/quill-mention/quill-mention/blob/a5812687dbdfbaa10fcb526990476ccfc466f05b/src/mention.ts#L939-L944

      this.quill.getModule('mention').onSomethingChange();

      this.quill.getModule('mention').insertItem(
        {
          ...option,
          denotationChar: '@',
        },
        true,
      );
    },
    controlIsEnabled(control) { // control can be a string or an object, and it customizes the toolbar buttons
      if (_isObject(control)) { // when control is like { header: '1' }
        return !Object.values(control).some(item => this.disable.includes(item));
      }

      return !this.disable.includes(control);
    },
    parseQuillText(eventType, $event) {
      const quillContent = eventType === 'blur' ? $event.value.firstElementChild.innerHTML : $event;

      const htmlParser = this.markdownEnabled
        ? this.$htmlToMarkdown
        : this.htmlToText;

      const mentionsParsedQuill = replaceMentionTagsToNotation(quillContent, htmlParser, this.mentions);

      if (this.internalValue === mentionsParsedQuill) return;

      this.internalValue = mentionsParsedQuill;
      if (eventType === 'input') this.$emit('update:modelValue', mentionsParsedQuill);
      if (eventType === 'blur') this.$emit('blur', mentionsParsedQuill);
    },
    htmlToText(text) {
      return this
        .$htmlToText(text.replaceAll('</p>', '</p>\n'))
        .replaceAll('    ', '\t') // replace the special 4 spaces with a tab
        .replaceAll('&gt;', '>')
        .replaceAll('&lt;', '<')
        .replaceAll('&amp;', '&');
    },
    async handleUploadImage(file) {
      if (!this.imageEnabled) return undefined;

      this.uploadingImage = true;
      this.errorMessage = null;

      try {
        const fileUploader = new FileUploader(this.$api, this.$externalApi);

        const fileData = await fileUploader.uploadImageFile(file);

        if (!fileData) throw new Error('Failed to upload image');

        this.uploadingImage = false;
        return fileData.download_url.default;
      } catch (error) {
        this.$errorRescue(this, error, 'handleUploadImage');
        this.errorMessage = this.t('failedToUploadImage');
      } finally {
        this.uploadingImage = false;
      }

      return undefined;
    },
  },
};
</script>
<style lang="scss">
.deck-text-wizard-input {
  --deck-text-wizard-input-background-color: var(--z-input-background-color);
  --deck-text-wizard-input-editor-highlight-background-color: var(--z-input-background-color);
  --deck-text-wizard-input-editor-highlight-outline-color: var(--z-color-gray);

  --deck-text-wizard-input-padding-inline: var(--z-input-padding-inline);

  --deck-text-wizard-input-border-width: var(--z-input-border-width);
  --deck-text-wizard-input-border-color: var(--z-input-border-color);
  --deck-text-wizard-input-border-color-highlight: var(--z-input-border-color-highlight);

  --deck-text-wizard-input-border-radius: var(--z-input-border-radius);

  --deck-text-wizard-input-min-font-size: var(--z-font-size-small);
  --deck-text-wizard-input-max-font-size: var(--z-font-size-medium);
  --deck-text-wizard-input-line-height: var(--z-line-height-comfort);
  --deck-text-wizard-input-letter-spacing: 0.01em;

  --deck-text-wizard-input-space-between-elements: var(--z-s5);

  --deck-text-wizard-input-height: var(--z-input-height-small);
  --deck-text-wizard-input-toolbars-height: 24px;
  --deck-text-wizard-input-toolbars-items-transition: 200ms ease-out;

  --deck-text-wizard-input-centering-margin: calc((var(--deck-text-wizard-input-height) - (1em * var(--deck-text-wizard-input-line-height))) / 2);

  width: 100%;

  &:not(.deck-text-wizard-input--read-only) {
    .v-input__control {
      .ql-editor {
        position: relative;

        > :first-child {
          padding-top: var(--deck-text-wizard-input-centering-margin);
        }

        > :last-child {
          padding-bottom: var(--deck-text-wizard-input-centering-margin);
          margin-bottom: 0 !important;
        }

        // Overlay layer to allow focusing the editor when clicking on the toolbar items
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          height: stretch;
          width: stretch;
          cursor: text;
          z-index: 0;
        }

        // Ensures the overlay layer is below the editor content
        &::before, > * {
          z-index: 1;
        }

        > * {
          position: relative;
        }
      }

      .ql-container {
        display: unset !important;
        height: calc(var(--deck-text-wizard-input-editor-height) - var(--deck-text-wizard-input-toolbars-height));
      }

      // To make the toolbar items appear when focusing an editable editory
      .ql-formats,
      .deck-text-wizard-input__bottom-toolbar {
        transition: 125ms ease-out;
        transform: translateY(75%);
        opacity: 0;
        pointer-events: none;
      }

      &:hover, &:focus-within {
        border-color: var(--deck-text-wizard-input-border-color-highlight);
      }

      &:focus-within {
        .ql-formats,
        .deck-text-wizard-input__bottom-toolbar {
          transform: translateY(0);
          opacity: 1;
          pointer-events: all;
        }

        .ql-editor {
          //position: relative;
          background-color: var(--deck-text-wizard-input-editor-highlight-background-color);
          outline-color: var(--deck-text-wizard-input-editor-highlight-outline-color);
        }
      }
    }
  }

  .v-input__control {
    display: flex;
    flex-wrap: wrap;
    row-gap: calc(var(--z-s1) / 2);
    align-items: center;
    border-style: solid;
    border-width: var(--deck-text-wizard-input-border-width);
    border-color: var(--deck-text-wizard-input-border-color);
    border-radius: var(--deck-text-wizard-input-border-radius);
    background-color: var(--deck-text-wizard-input-background-color);
    overflow: hidden;
    transition: 200ms ease;

    .ql-container {
      font-family: var(--z-font-family-base) !important;
      order: -1;
      width: 100%;
      border: none;
    }
  }
}

.deck-text-wizard-input__content {
  display: flex;
  flex-direction: column;
}

.deck-text-wizard-input__input-details {
  display: flex;
}

.deck-text-wizard-input__bottom-toolbar {
  display: flex;
  align-items: center;
  order: -1;
  margin-right: calc(var(--deck-text-wizard-input-padding-inline) - var(--z-s1));
  margin-left: calc(var(--deck-text-wizard-input-padding-inline) - var(--z-s2));
  height: var(--deck-text-wizard-input-toolbars-height);
}

.deck-text-wizard-input__content {
  flex: 1;
  height: 100%;
}

.deck-text-wizard-input__editor {
  display: contents;

  .ql-editor {
    border-radius: var(--deck-text-wizard-input-border-radius);
    border: none;
    font-size: clamp(var(--deck-text-wizard-input-min-font-size), 2vw + 4px, var(--deck-text-wizard-input-max-font-size));
    height: 100%;
    min-height: var(--deck-text-wizard-input-height);
    outline: 1px solid transparent; // Appears only when focusing
    padding-block: 0;
    padding-inline: var(--deck-text-wizard-input-padding-inline);
    transition: 200ms ease;
    width: 100%;
    word-break: break-word;

    p, li {
      line-height: var(--deck-text-wizard-input-line-height);
      letter-spacing: var(--deck-text-wizard-input-letter-spacing);
    }

    ol,
    ul {
      padding-left: 0 !important;
    }

    li:not(.ql-direction-rtl) {
      &::before {
        margin-left: -1.8em !important;
        margin-right: 0.8em !important;
      }
    }

    img {
      max-width: 100%;
    }

    &.ql-blank::before {
      left: unset;
      right: unset;
      font-style: unset;
      line-height: var(--deck-text-wizard-input-line-height);
      margin-top: var(--deck-text-wizard-input-centering-margin);
      color: var(--z-input-placeholder-color);
    }

    .ql-video {
      width: 100%;
      aspect-ratio: 16/9;
    }
  }
}

.ql-tooltip {
  font-family: var(--z-font-family-base) !important;
  z-index: 1;

  // ----------------------------------------------------------------- //
  // Begin Localization of quill's tooltips                            //
  // ----------------------------------------------------------------- //

  // Tem uma issue aberta pq o quilljs não aceita config de locale https://github.com/quilljs/quill/issues/2922.
  // MAS dá pra fazer override via CSS (SIM! HORRÍVEL!), hence this gambiarra
  &::before {
    content: var(--vist-url-text) !important;
  }

  &[data-mode=link]::before {
    content: var(--insert-link-text) !important;
  }

  &.ql-editing .ql-action::after {
    content: var(--save-text) !important;
  }

  .ql-action::after {
    content: var(--edit-text) !important;
  }

  .ql-remove::before {
    content: var(--remove-text) !important;
  }
  // ----------------------------------------------------------------- //
  // End Localization of quill's tooltips                              //
  // ----------------------------------------------------------------- //
}

.ql-toolbar {
  display: contents;
  width: 100%;
  padding-block: 0;
  border-style: none;

  .ql-formats {
    margin: 0 !important;
    padding-left: calc(var(--deck-text-wizard-input-padding-inline) - var(--z-s2)) !important;
    height: var(--deck-text-wizard-input-toolbars-height);

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 28px;

      &.ql-active {
        background-color: transparent !important;
      }

      svg {
        height: 16px !important;
        width: 16px !important;
      }
    }

    & + .ql-formats {
      padding-left: 0 !important;
      margin-left: var(--z-s3) !important;
    }
  }

  &::after {
    display: none;
  }
}

// ----------------------------------------------------------------- //
// Modifiers                                                          //
// ----------------------------------------------------------------- //

.deck-text-wizard-input--no-toolbar {
  --deck-text-wizard-input-editor-highlight-background-color: transparent;
  --deck-text-wizard-input-editor-highlight-outline-color: transparent;
}

.deck-text-wizard-input--read-only {
  --deck-text-wizard-input-padding-inline: var(--z-input-padding-inline-read-only);
  --deck-text-wizard-input-background-color: var(--z-input-background-color-read-only);
  --deck-text-wizard-input-border-color: var(--z-input-border-color-read-only);
  --deck-text-wizard-input-border-color-highlight: var(--z-input-border-color-read-only);

  .ql-blank {
    height: unset;
    min-height: unset;
  }
}

.deck-text-wizard-input--comfort {
  --deck-text-wizard-input-height: var(--z-input-height-medium);
  --deck-text-wizard-input-max-font-size: var(--z-font-size-large) !important;
}

.deck-text-wizard-input--dense {
  --deck-text-wizard-input-line-height: var(--z-line-height-base) !important;
  --deck-text-wizard-input-max-font-size: var(--z-font-size-small) !important;
  --deck-text-wizard-input-letter-spacing: 0 !important;
}

.deck-text-wizard-input--error {
  --deck-text-wizard-input-border-color: rgb(var(--v-theme-error)) !important;
}

.deck-text-wizard-input--limited-width .ql-container {
  max-width: 740px;
  margin-inline: auto;
}

.deck-text-wizard-input--space-between-elements {
  .ql-editor {
    h1, h2, h3, h4, h5, h6, blockquote, ul, ol, img, iframe, pre, p {
      margin-bottom: var(--deck-text-wizard-input-space-between-elements);
    }

    > :last-child {
      margin-bottom: 0 !important;
    }
  }

  &:not(.deck-text-wizard-input--read-only) {
    .ql-editor {
      min-height: calc(var(--deck-text-wizard-input-height) + var(--deck-text-wizard-input-space-between-elements)); // Makes the editor have a larger min-height to look more like a textarea
    }
  }
}

.deck-text-wizard-input--monospaced .ql-editor {
  font-family: var(--z-font-family-mono);
  word-break: break-all;
}

// ----------------------------------------------------------------- //
// Global styles to make the mention list look like the rest of the editor  //
// ----------------------------------------------------------------- //
span.mention {
  background-color: #e8ebf6;
  padding: 2px 0;
  border-radius: 4px;

  &[data-denotation-char="$"] {
    background-color: transparent;
    color: #3f51b5;
    font-weight: 400;

    & > span {
      & > span.ql-mention-denotation-char {
        display: none;
      }
    }
  }

  & > span {
    margin: 0 2px;
  }
}

.ql-mention-list-container {
  background-color: var(--z-main-background-color);
  padding: 8px 0;
  z-index: 9999;
  box-shadow: rgb(0 0 0 / 9%) 0 3px 12px !important;
  border-radius: 8px;
  overflow-y: auto;
}

li.ql-mention-list-item {
  font-size: 16px;
  line-height: 1.2;
  padding: 8px 24px;
  color: var(--z-text-color);

  &.selected {
    background-color: var(--z-main-background-accent-color);
    color: #3f51b5 !important;
  }
}

ul.ql-mention-list {
  padding-left: 0;
  list-style-type: none;
}
</style>
