import type { GetterTree, MutationTree } from 'vuex';
import { calculateFieldIdMapping } from '~/assets/javascript/utils';

export const state = () => ({
  fields: [],
});

type State = ReturnType<typeof state>;

export const mutations = <MutationTree<State>>{
  setFields(state, fields) {
    state.fields = fields;
  },
};

export const getters = <GetterTree<State, any>>{
  fieldIdMapping({ fields }, _getters, rootState) {
    return calculateFieldIdMapping(fields, rootState['views/locale']);
  },
};

export const namespaced = true;
