
export class Module {
  constructor(nuxt) {
    this.$localePath = nuxt.$localePath;
    this.$router = nuxt.$router;
  }

  onRedirect(to, from) {
    const fromRoute = this.$router.resolve(from);
    window.localStorage?.setItem('redirectTo', from);
    let redirectDestination = to;

    if (redirectDestination === '/login') {
      const { tenantSlug } = fromRoute.params;
      redirectDestination = tenantSlug ? `/t/${tenantSlug}/login` : 'login';
    }

    return this.$localePath({
      path: redirectDestination,
      query: { redirectTo: from },
    });
  }
}

export const OnRedirectSetup = (nuxt) => {
  const module = new Module(nuxt);

  nuxt.$auth.onRedirect((...args) => module.onRedirect(...args));
};
