<i18n lang="yaml">
pt:
  pageTitle: 'Loja de Apps para RH - {appName}'
  description: 'O que esse app faz?'
  features: 'Funcionalidades'
  data: 'Estruturas de Dados'
  open: 'Abrir'
  uninstallAppWarningMessage: 'Esta aplicação será desinstalada e todas as páginas e automações criados por ela serão removidos. Os seus dados não serão afetados. As seguintes dependências serão removidas:'
en:
  pageTitle: 'HR App Store - {appName}'
  description: 'What does this app do?'
  features: 'Features'
  data: 'Data Structure'
  open: 'Open'
  uninstallAppWarningMessage: 'This app will be uninstalled and all pages and automations created by it will be removed. Your data will not be affected. The following dependencies will be removed:'
</i18n>

<template>
  <LogoLoading v-if="loading" />

  <AppStore
    v-else-if="app"
    is-app-content
    :title="appTitle"
    :category-icon="app.icon"
    :back-button-category="lastPageCategory"
  >
    <template #controls>
      <v-btn
        v-if="!hasStudioAccess"
        size="small"
        elevation="0"
        color="#fd8c41"
        :loading="loading"
        class="text-white"
        @click="goToSignUp"
      >
        {{ $t('global.install') }}
      </v-btn>

      <template v-else-if="appInstalled">
        <v-btn
          size="small"
          variant="outlined"
          elevation="0"
          color="red"
          :loading="loading"
          :disabled="app.archived"
          class="text-white"
          @click="openDialogUninstallApp"
        >
          {{ $t('global.uninstall') }}
        </v-btn>
        <v-btn
          size="small"
          elevation="0"
          color="#fd8c41"
          :loading="loading"
          :disabled="app.archived"
          class="text-white"
          @click="goToInstalledApp"
        >
          {{ t('open') }}
        </v-btn>
      </template>

      <v-btn
        v-else
        size="small"
        elevation="0"
        color="#fd8c41"
        :loading="loading"
        :disabled="app.archived"
        class="text-white"
        @click="openAppInstallWizardDialog"
      >
        {{ $t('global.install') }}
      </v-btn>
    </template>

    <div
      v-if="app.video"
      class="app-page__video-wrapper"
    >
      <iframe
        :src="app.video"
        class="app-page__video"
      />
    </div>

    <h3 class="text-h5">
      {{ t('description') }}
    </h3>

    <Markdown
      :model-value="app.description"
      class="mt-5"
    />

    <h3 class="text-h5 mt-10">
      {{ t('features') }}
    </h3>
    <AppResourcesList :app="app" />
  </AppStore>
</template>

<script lang="ts">
import AppStore from '~/components/store/app-store';
import { mapGetters, mapActions, mapMutations } from '~/assets/javascript/modules/vuex';
import LogoLoading from '~/components/LogoLoading';
import Markdown from '~/components/data-elements/markdown-data-element';
import AppResourcesList from '~/components/app-resources-list';

export default {
  name: 'AppPage',
  components: {
    AppResourcesList,
    AppStore,
    LogoLoading,
    Markdown,
  },
  beforeRouteEnter(_to, from, next) {
    // If the user is coming from the categories page, we use the last category query for the back button
    next((vm) => {
      vm.lastPageCategory = from.query?.category || 'all';
    });
  },
  layout: 'store',
  setup() {
    definePageMeta({
      layout: 'store',
    });

    const { t } = useI18n();
    const route = useRoute();

    const vuex = {
      // eslint-disable-next-line vue/no-unused-properties
      ...mapGetters('apps', ['getAppByName', 'getAppInstallationByName']),
      ...mapGetters('workspace', ['tenantSlug']),
      ...mapActions('apps', ['loadApps', 'loadAppInstallations', 'uninstallApp', 'loadPublicApps']),
      ...mapMutations('dialog', ['openDialog']),
    };

    const appName = computed(() => route.params.appName);
    // @ts-expect-error Some error
    const app = computed(() => vuex.getAppByName.value(appName.value));
    const appTitle = computed(() => app.value?.translated_name);

    buildHead({
      titleTemplate: '%s ⚡️Zazos',
      title: computed(() => t('pageTitle', { appName: appTitle.value })),
      description: computed(() => ({
        value: app.value?.description,
        truncate: true,
      })),
    });

    return {
      t,
      ...vuex,
      appName,
      app,
      appTitle,
    };
  },
  data() {
    return {
      loading: false,
      lastPageCategory: '',
    };
  },
  computed: {
    hasStudioAccess() {
      return this.$auth.loggedIn && this.$auth.user.role === 'admin';
    },
    appInstallation() {
      return this.getAppInstallationByName(this.appName);
    },
    appInstalled() {
      return !!this.appInstallation;
    },
    appViews() {
      return this.app?.views?.filter(({ visible }) => visible).map(view => ({ name: view.name, type: this.$t('global.view') })) || [];
    },
    appWorkflows() {
      return this.app?.workflows?.map(workflow => ({ name: workflow.name, type: this.$t('global.workflow') })) || [];
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;

      if (this.hasStudioAccess) {
        await Promise.all([this.loadApps(), this.loadAppInstallations()]);
      } else {
        await this.loadPublicApps();
      }

      this.loading = false;
    },
    goToSignUp() {
      this.$router.push(this.localePath({
        name: 'sign-up',
        query: { utm_app: this.appName },
      }));
    },
    openAppInstallWizardDialog() {
      this.openDialog({
        component: 'DialogAppInstallWizard',
        maxWidth: 1024,
        fullHeight: true,
        attrs: {
          appName: this.app.name,
        },
      });
    },
    goToInstalledApp() {
      const viewToRedirectTo = this.appInstallation.schema.views.find(view => view.view_type === 'Content')
        || this.appInstallation.schema.views[0];

      this.$router.push(this.localePath({
        name: 't-tenantSlug-studio-pages-id',
        params: {
          tenantSlug: this.tenantSlug,
          id: viewToRedirectTo.id,
        },
      }));
    },
    openDialogUninstallApp() {
      this.openDialog({
        component: 'DialogConfirmAction',
        attrs: {
          warning: true,
          text: this.t('uninstallAppWarningMessage'),
          itemDependencies: [
            ...this.appViews.map(view => `(${view.type}) ${view.name}`),
            ...this.appWorkflows.map(workflow => `(${workflow.type}) ${workflow.name}`),
          ],
        },
        on: {
          onConfirm: async () => {
            try {
              this.loading = true;
              await this.uninstallApp(this.app.name);
            } catch (error) {
              this.$errorRescue(this, error, 'uninstalledApp');
            } finally {
              this.loading = false;
            }
          },
        },
      });
    },
  },
};
</script>

<style lang="scss">
@use '~/assets/styles/tools/fluid-grid';

.app-page__video-wrapper {
  --aspect-ratio: 10 / 16; // height ratio / width ratio
  --max-height: 60vh; // arbitrary value, limit the height of the video to always show some content below

  position: relative;
  padding-bottom: min(var(--max-height), var(--aspect-ratio) * 100%);
  margin-bottom: 32px;
}

.app-page__video {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 12px;
  background-color: #FD8C4126;
}
</style>
