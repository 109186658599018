import { load as loadReCaptcha } from 'recaptcha-v3';

export default defineNuxtPlugin({
  name: 'recaptcha',
  setup() {
    const { public: { recaptchaSiteKey } } = useRuntimeConfig();

    const plugin = {
      init: () => loadReCaptcha(recaptchaSiteKey, {
        useRecaptchaNet: true,
        autoHideBadge: true,
      }),
      fetchToken: async (fn: (token: string) => Promise<void>) => {
        if (['development', 'storybook'].includes(process.env.NODE_ENV)) {
          return fn('FakeToken');
        }

        const instance = await plugin.init();
        const token = await instance.execute('login');

        return fn(token);
      },
    };

    return {
      provide: {
        recaptcha: plugin,
      },
    };
  },
});
