
import ChartInfoComponentOptionBuilder from './chart';
import FieldInfoComponentOptionBuilder from './field';
import GroupInfoComponentOptionBuilder from './group';
import MarkdownInfoComponentOptionBuilder from './markdown';

const infoComponentOptionBuilders = {
  chart: ChartInfoComponentOptionBuilder,
  field: FieldInfoComponentOptionBuilder,
  group: GroupInfoComponentOptionBuilder,
  markdown: MarkdownInfoComponentOptionBuilder,
};

export default class InfoComponentOptionBuilder {
  static build(infoComponent, mainContent) {
    return new InfoComponentOptionBuilder(infoComponent, mainContent).build();
  }

  constructor(infoComponent, mainContent = []) {
    this.builder = new infoComponentOptionBuilders[infoComponent.type](infoComponent, mainContent);
  }

  build() {
    return this.builder.build();
  }
}

