/* eslint-disable camelcase */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["buildValueFromPaste"] }] */

import { isEmpty } from 'lodash';
import { CHECKLIST_ESCAPE_CHARS_MAPPING } from '~/assets/javascript/constants';
import { stringToArray } from '~/assets/javascript/utils';
import BaseCell from './BaseCell';

// Extracts the checkbox item text and whether it is checked or not
// Example:
//   [x] A -> checked: true, text: 'A'
//   [] B -> checked: false, text: 'B'
//   [X] C -> checked: true, text: 'C'
//   [x] -> checked: true, text: ''
//   [] -> checked: false, text: ''
const EXTRACT_CHECKLIST_ITEM_PATTERN = /^\s*\[(x|X| |)\]\s*(.*)/;

const unescapeChecklistItemText = (text) => {
  if (typeof text === 'string') {
    Object.entries(CHECKLIST_ESCAPE_CHARS_MAPPING).forEach(([char, escapedChar]) => {
      text = text.replaceAll(escapedChar, char);
    });
  }

  return text;
};

export function stringToChecklist(rawText) {
  const items = stringToArray(rawText).map((itemText) => {
    const match = itemText.match(EXTRACT_CHECKLIST_ITEM_PATTERN);

    const checked = match?.[1]?.toLowerCase() === 'x';
    const text = match?.[2]?.trim(); // The text of the checkbox item

    return { checked, text: unescapeChecklistItemText(text) };
  });

  const checked_items = items.filter(item => item.checked).length;
  const total_items = items.length;

  return {
    checked_items,
    total_items,
    display_name: `${checked_items}/${total_items}`,
    items,
  };
}

export default class Checklist extends BaseCell {
  constructor(...args) {
    super(...args);
    this.allowPaste = true;
    this.emptyValue = isEmpty;
    this.allowClear = true;
  }

  buildValueFromPaste(rawValue) {
    if (rawValue === '') return null;

    return stringToChecklist(rawValue);
  }
}
