import type { Directive } from 'vue';

function disableClickOutsideDataTable(el: HTMLElement) {
  el.classList.add('disable-click-outside-data-table');
}

export const DisableClickOutsideDataTableDirective: Directive = disableClickOutsideDataTable;

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('disable-click-outside-data-table', DisableClickOutsideDataTableDirective);
});
